
const REFRESH_TOKEN = 'REFRESH_TOKEN';

export default {
    LOGIN: 'LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGIN_AFTER_REGISTER: 'LOGIN_AFTER_REGISTER',
    LOGOUT: 'LOGOUT',
    LOGOUT_EXPIRE: 'LOGOUT_EXPIRE',
    TURN_OFF_EXPIRE_ALERT: 'TURN_OFF_EXPIRE_ALERT',
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
}

export const updateToken = () => ({ type: REFRESH_TOKEN })