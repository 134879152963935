import cache from "js-cache"
import client from '../helpers/HttpClient'
import AuthHelper from "../helpers/AuthHelper"

const endpoit = '/settings/'
export default {
    updateSetting(value, id) {
        const path = `${endpoit}${id}/`;
        let data ={
            "value": value
        }
        return client.patch(path,data)
    },
    updateSettingColumns(value, key) {
        const path = `/settings-key/${key}/`;
        let data = {
            "value": value
        }
        return client.patch(path, data);
    },
    async getSetting() {
        const path = endpoit;
        try {
            const userId = AuthHelper.getUserId()
            const cacheKey = userId + path
            let data = cache.get(cacheKey)
            if (data) {
                return data
            }
            let response = await client.get(path)
            data = response.data
            cache.set(cacheKey, data)
            return data
        } catch (error) {
            throw (error)
        }
    }
}
