import client from '../helpers/HttpClient'

const endpoit = '/settings/'
export default {
    updateLang(langVal, id, key) {
        const path = `${endpoit}${id}/`;
        let data ={
            "key": key,
            "value": langVal
        }
        return client.patch(path,data).then(val => {
          localStorage.setItem("lang", JSON.stringify({...data, id: val.id}));
          return val;
        })
    },
    async listLang() {
        const path = endpoit;
        try {
            let response = await client.get(path)
            return response.data
        } catch (error) {
            throw (error)
        }
    },
    async getLang() {
        const path = endpoit;
        try {
            let response = await client.get(path)
            return response.data.find(item => item.key === "Language");
        } catch (error) {
            throw (error)
        }
    }
}
