import React from "react";
import { getDateTime } from '../../helpers/UtilsHelper';
import TabelHelper from '../../helpers/TabelHelper';
import strings from "../../localization";
import "./ButtonIcon.scss"

export function LastUpdateFormatter(props) {
    const isParentRow = TabelHelper.isParentRow(props.cell);
    const row = props.cell.getRow();
    const data = row.getData();
    const updateBy = data.updatedBy || data.createdBy || "";
    // const firstName = updateBy.firstName ? updateBy.firstName : "";
    // const lastName = updateBy.lastName ? updateBy.lastName : "";
    // const company = updateBy.company ? `(${updateBy.company})` : "";
    const timeFormat = props.timeFormat === "12h" ? "h:mm A" : "HH:mm";
    return (
      <div className="last-update">
        {
          updateBy &&isParentRow && <>
            <div>{getDateTime(data.updatedAt, timeFormat) || ""}</div>
            <div>{getDateTime(data.updatedAt, "MMMM Do YYYY") || ""}</div>
            <div className="last-update__link">{strings.view_history}</div>
          </>
        }
      </div>
    )
}
