import client from '../helpers/HttpClient';
import { saveFileFromResponse } from '../helpers/FileHelper';

const endpoint = '/tasksmanager/tasks/';

const generateUploadTaskFileUrl = async (taskId, fileName) => {
    const path = `${endpoint}${taskId}/attachments/generate-urls/`
    return client.post(path, {fileName})
}

const generateUploadTaskFileUrlV2 = async (taskId, fileName) => {
    const path = `${endpoint}${taskId}/task-attachments/generate-urls/`
    return client.post(path, {name: fileName})
}

const saveUploadedFile = async (taskId, fileInfo) => {
    const path = `${endpoint}${taskId}/attachments/`
    return client.post(path, fileInfo)
}

const saveUploadedFileV2 = async (taskId, fileInfo) => {
    const path = `${endpoint}${taskId}/task-attachments/`
    return client.post(path, fileInfo)
}

export default {
    async getSideBarFile(payload) {
        let { offset, id } = payload;
        const limit = 10;
        const path = `${endpoint}${id}/attachments/?limit=${limit}&offset=${offset}`;
        try {
            let response = await client.get(path);
            return response.data;
        } catch (error) {
            throw (error);
        }
    },
    async getSideBarFileV2(payload) {
        let { offset, id } = payload;
        const limit = 10;
        const path = `${endpoint}${id}/task-attachments/?limit=${limit}&offset=${offset}`;
        try {
            let response = await client.get(path);
            return response.data;
        } catch (error) {
            throw (error);
        }
    },
    async deleteSidebarFile(list) {
        const path = `/tasksmanager/attachments/deletemultiple/`;
        try {
            return await client.post(path, list);
        } catch (error) {
            throw (error);
        }
    },
    async downloadSidebarFile(list) {
        const path = `/tasksmanager/attachments/download/`;
        const config = {
            responseType: 'blob',
        }
        try {
            return await client.post(path, list, config).then((response) => {
                saveFileFromResponse(response);
            });
        } catch (error) {
            throw (error);
        }
    },
    async uploadSidebarFile(id, data, cell) {
        const path = `${endpoint}${id}/attachments/`;
        var formData = new FormData();
        formData.append("listFileInfo", JSON.stringify(data.listFileInfo));
        data.files.forEach(item => {
            formData.append("files", item);
        })
        let response = await client.post(path, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return response.data;
    },

    async uploadSidebarSingleFile(taskId, data) {
        const {file, fileInfo} = data;
        const {url, fileName} = (await generateUploadTaskFileUrl(taskId, file.name)).data;
        
        // upload by signed url
        const uploadResponse = await fetch(url, {
            method: "PUT",
            body: file
        })
        
        if (!uploadResponse || uploadResponse.status !== 200) {
            throw new Error("upload fail")
        }

        // save file with info
        const response = await saveUploadedFile(taskId, {...fileInfo, fileName});
        return response.data;
    },
    async uploadSidebarSingleFileV2(taskId, data) {
        const {file, fileInfo} = data;
        if (!fileInfo.comment) {
            fileInfo.comment = ""
        }
        const {url, fileName} = (await generateUploadTaskFileUrlV2(taskId, file.name)).data;
        
        // upload by signed url
        const uploadResponse = await fetch(url, {
            method: "PUT",
            body: file
        })
        
        if (!uploadResponse || uploadResponse.status !== 200) {
            throw new Error("upload fail")
        }

        // save file with info
        const response = await saveUploadedFileV2(taskId, {...fileInfo, name: fileName});
        return response.data;
    },
    async updateSidebarFile({ id, data }) {
        const path = `/tasksmanager/attachments/${id}/`;
        let response = await client.put(path, data);
        return response.data;
    }
}
