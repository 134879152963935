import { all, fork } from "redux-saga/effects";
import { watchQAAsync } from "./qa.saga"
import { watchProjectAsync } from "./project.saga";
import { watchProjectGroupAsync } from "./projectGroup.saga";
import { watchLangAsync } from "./lang.saga";
import { watchSettingAsync } from './setting.saga';
import { watchSidebarAsync } from "./sidebar.saga"
import { watchBillingAsync } from "./billing.saga"
import { watchAuthAsync } from "./auth.saga"
import { watchHistoryAsync } from "./historySaga"
import { watchProjectUserAsync } from "./projectUserSaga"
import { watchAdminDashboardAsync } from "./adminDashboardSaga"

export default function* sagas() {
  yield all([
    fork(watchQAAsync),
    fork(watchProjectAsync),
    fork(watchProjectGroupAsync),
    fork(watchAuthAsync),
    fork(watchLangAsync),
    fork(watchSettingAsync),
    fork(watchSidebarAsync),
    fork(watchBillingAsync),
    fork(watchHistoryAsync),
    fork(watchProjectUserAsync),
    fork(watchAdminDashboardAsync),
  ]);
}
