import React from "react";
import TabelHelper from '../../helpers/TabelHelper';
import strings from "../../localization";
import "./ButtonIcon.scss"

export function ButtonIconFile(props) {
  let data = props.cell.getRow().getData();
  let { cell, isClosed } = props;

  function onClick(cell) {
    if (data.id) {
      props.onClickFile(cell, isClosed)
    }
  }

  if (TabelHelper.isParentRow(cell) && data && data.id) {
    let fileCount = (data && data.countAttachment) || 0;
    return (<div className="button-icon-file" onClick={() => onClick(cell)}>
      { fileCount > 0 &&
        <div className="button-icon-file__count">{fileCount}{` ${strings.files}`}</div>
      }
      <div className="button-icon-file__upload">{strings.upload}</div>
    </div>);
  } else {
    return <span />;
  }
}
