import client from '../helpers/HttpClient'
const endpoint = '/auth/'
export default {
    async auth(username, password) {
        const path = endpoint;
        let response = await client.post(path, { email: username, password })
        return response.data;
    },
    async authFirebase(firebaseToken) {
        const path = endpoint + "firebase-auth/";
        let response = await client.post(path, { token: firebaseToken })
        return response.data;
    },
    async refreshToken(token) {
        const path = endpoint + "refresh-token/";
        let response = await client.post(path, { token })
        return response.data;
    }
}
