import moment from 'moment';
import _ from 'lodash';

export const getDate = (data, format) => data && moment(data).format(format || "YYYY-MM-DD");
export const getDateTime = (data, format) => data && moment(data).format(format || "YYYY-MM-DD HH:mm:ss");
export const getFirstDayNextMonth = format => moment().endOf("month").format(format || "YYYY-MM-DD");

export const uppercaseFirstLetter = data => data.charAt(0).toUpperCase() + data.slice(1);

export const getExpandList = (list, id) => {
  let tempArray = [];
  const emptyData = {
    id: null,
    parentTask: id,
    issue: '',
    answer: '',
    lastupdate: null
  };
  if (list && list.length) {
    list.forEach((item, index) => {
      item.index = index + 1;
      tempArray.push(item);
      if (item.id === id) {
        if (item.subtasks && item.subtasks.length > 0) {
          item.subtasks.forEach((child, childIndex) => {
            child.parentTask = id;
            child.index = `${index + 1}.${childIndex + 1}`
            tempArray.push(child)
          });
        }
        let indexEndRow = item.subtasks ? item.subtasks.length + 1 : 1;
        emptyData.index = `${index + 1}.${indexEndRow}`;
        tempArray.push(emptyData)
      }
    })
  }
  return tempArray;
}

export const getCloseExpandList = (list, id) => {
  if (list && list.length) {
    let row = list.find(item => item.id === id);
    if (row) {
      return list.filter(item => !(item.parentTask && item.parentTask === id))
    }
  }
  return list;
}

export const createEmptyRow = () => {
  return {
    id: null,
    issue: null,
    answer: null,
    taskNum: null,
    parentTask: null,
    priority: null,
    taskStatus: null,
    project: null
  }
}

const _getAnswerBackupData = (rowData) => {
  const answerBackupData = Array(2).fill(null);
  const { subtasks } = rowData;
  const countTask = subtasks && subtasks.length;
  //Add data for parent
  const { answer, updatedAnswerBy, updatedAnswerAt } = rowData;
  answerBackupData[0] = { answer, updatedAnswerBy, updatedAnswerAt }

  //Add data for latest child
  if (countTask > 0) {
    const latestChild = subtasks[countTask - 1];
    answerBackupData[1] = {
      answer: latestChild.answer,
      updatedAnswerBy: latestChild.updatedAnswerBy,
      updatedAnswerAt: latestChild.updatedAnswerAt
    };
  }
  return answerBackupData;
}

export const buildTableData = (tasks, projectId, isClosed) => {
  let emptyRow = createEmptyRow();
  // emptyRow.id = -1
  emptyRow.project = projectId;
  if (!tasks) {
    return []
  }

  tasks.forEach(task => {
    task.answerBackup = _getAnswerBackupData(task);
    const latestChild = task.answerBackup[1];
    if (latestChild) {
      const { answer, updatedAnswerBy, updatedAnswerAt } = latestChild;
      task.answer = answer;
      task.updatedAnswerBy = updatedAnswerBy;
      task.updatedAnswerAt = updatedAnswerAt;
    }

    if (task.subtasks) {
      let emptyChildRow = { ...emptyRow, parentTask: task.id };
      if (!isClosed) {
        task.subtasks.push(emptyChildRow);
      }
      task.subtasks.forEach(item => {
        item.parentTaskNum = task.taskNum;
      })
    }
  });

  if (!isClosed) {
    tasks.push(emptyRow)
  }
  return tasks;
}

export const buildTaskData = (tasks) => {
  tasks.forEach(task => {
    if (task.subtasks && task.subtasks.length > 0) {
      task.subtasks = task.subtasks.filter(item => item);
    }
  });
  return tasks;
}

export const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

export const keysToCamel = function (o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toCamel(k)] = keysToCamel(o[k]);
      });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

export const isRowExpanded = (row) => {
  if (!row) {
    return false;
  }

  const config = row._row.modules && row._row.modules.dataTree;
  return config && config.open;
}

export const urlQueryFromObject = (filters) => {
  if (!_.isObject || _.isNull(filters)) {
    return "";
  }
  for (let key in filters) {
    const value = String(filters[key]);
    if (_.isEmpty(value) || _.isNull(value)) {
      delete filters[key]
    }
  }
  filters = { limit: 100000, offset: 0, ...filters };
  if (!_.isUndefined(filters.desc) && !filters.desc) {
    filters.ordering = `-${filters.ordering}`;
  }
  const searchParams = new URLSearchParams(filters);
  searchParams.delete('desc')
  return "?" + searchParams.toString();
}

export const fixNumber = (number, amount = 1) => {
  return Number(number).toFixed(amount)
}

export const removeElementById = (elmId) => {
  if (document.getElementById(elmId)) {
    document.getElementById(elmId).outerHTML = "";
  }
};

export const downloadUrl = (url) => {
  debugger
  const el = document.createElement('a')
  el.style.display = 'none'
  document.body.append(el)
  el.href = url
  el.setAttribute('download', true)
  el.click()
  window.URL.revokeObjectURL(el.href);
  document.body.removeChild(el);
}