import { SIDEBAR_ACTIONS, QA_ACTIONS } from '../actions'
const initState = () => ({
    loading: false,
    success: false,
    dataSideBar: [],
    uploadItem: [],
    count: 0,
    offset: 0,
    id: null,
    taskNum: null,
    showSidebar: false,
    cell: null,
    isClosed: true,
})
export default function sidebarReducer(state = initState(), action) {
    const { type, payload } = action
    switch (type) {
        case SIDEBAR_ACTIONS.GET_SIDEBAR_REQUEST:
            return Object.assign({}, state, {
                loading: true
            });
        case SIDEBAR_ACTIONS.GET_SIDEBAR_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                dataSideBar: payload.results,
                uploadItem: [],
                offset: 10,
                count: payload.count
            });
        case SIDEBAR_ACTIONS.GET_SIDEBAR_FAIL:
            return Object.assign({}, state, {
                loading: false,
            });

        case SIDEBAR_ACTIONS.LOADMORE_SIDEBAR_REQUEST:
            return Object.assign({}, state, {
                loading: true
            });
        case SIDEBAR_ACTIONS.LOADMORE_SIDEBAR_SUCCESS:
            const { results, count } = payload;
            let removeDuplicate = [];
            results.forEach(loadItem => {
              let valid = true;
              state.uploadItem.forEach(uploadItem => {
                if(uploadItem.id === loadItem.id) {
                  valid = false;
                }
              })
              if(valid) {
                removeDuplicate.push(loadItem);
              }
            })
            return Object.assign({}, state, {
                loading: false,
                dataSideBar: [...state.dataSideBar, ...removeDuplicate],
                offset: state.offset + 10,
                count
            });
        case SIDEBAR_ACTIONS.LOADMORE_SIDEBAR_FAIL:
            return Object.assign({}, state, {
                loading: false,
            });

        case SIDEBAR_ACTIONS.OPEN_SIDEBAR:
            return Object.assign({}, state, {
                dataSideBar: [],
                id: null,
                showSidebar: payload
            });
        case SIDEBAR_ACTIONS.DELETE_SIDEBAR_REQUEST:
            return Object.assign({}, state, {
                loading: true,
            });

        case SIDEBAR_ACTIONS.DELETE_SIDEBAR_SUCCESS:
            payload.length && payload.forEach(id => {
              state.dataSideBar = state.dataSideBar.filter(item => item.id !== id);
            })
            return Object.assign({}, state, {
                loading: false,
            });

        case SIDEBAR_ACTIONS.DELETE_SIDEBAR_FAIL:
            return Object.assign({}, state, {
                loading: false,
            });

        case SIDEBAR_ACTIONS.UPLOAD_SIDEBAR_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                success: false
            });

        case SIDEBAR_ACTIONS.UPLOAD_SIDEBAR_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                success: true
            });

        case SIDEBAR_ACTIONS.UPLOAD_SIDEBAR_FAIL:
          return Object.assign({}, state, {
              loading: false,
          });

        case SIDEBAR_ACTIONS.PUSH_SIDEBAR_REQUEST:
          return Object.assign({}, state, {
              loading: true,
              success: false
          });

        case SIDEBAR_ACTIONS.PUSH_SIDEBAR_REQUEST_FAIL:
            return Object.assign({}, state, {
                loading: false,
                success: false
            });
        case SIDEBAR_ACTIONS.PUSH_SIDEBAR_REQUEST_SUCCESS:
            return Object.assign({}, state, {
                dataSideBar: [...state.dataSideBar, payload],
                uploadItem: [...state.uploadItem, payload],
                loading: false,
                success: true
            });

        case SIDEBAR_ACTIONS.TOGGLE_SIDE_BAR:
            const { isClosed } = payload;
            if(payload.forceClose){
                return {
                    ...state,
                    showSidebar: false,
                    isClosed,
                }
            }

            const data = payload.cell.getRow().getData();
            const oldId = state.id
            const newId = data.id;
            const taskNum = data.taskNum;
            if (oldId === newId) {
                return {
                    ...state,
                    showSidebar: !state.showSidebar,
                    id: newId,
                    cell: payload.cell,
                    taskNum,
                    isClosed,
                }
            }
            else {
                return {
                    ...state,
                    showSidebar: true,
                    id: newId,
                    cell: payload.cell,
                    taskNum,
                    isClosed,
                }
            }
        case SIDEBAR_ACTIONS.UPDATE_CELL:
          return {
            ...state,
            cell: payload
          }

        case QA_ACTIONS.QA_RESET_STATE:
            return initState()

        default:
            return state
    }
}
