import React from "react";
import constants from "../../constants";

export default class SelectFormater extends React.Component {
  componentDidMount() {
    this.markRow();
  }

  markRow = () => {
    const baseElement = this.props.baseElement;
    setTimeout(() => {
      let closestRow = baseElement.closest('.tabulator-row');
      const data = this.props.cell.getRow().getData();
      this.markClosedRow(data, closestRow);
    }, 0)
  }

  markClosedRow(data, closestRow) {
    if (data.taskStatus === constants.TASK_STATUS.CLOSE) {
      if (closestRow)
        closestRow.classList.add("closed-row");
    } else {
      if (closestRow)
        closestRow.classList.remove("closed-row");
    }

    if (data.taskStatus === constants.TASK_STATUS.COMPLETE) {
      if (closestRow)
        closestRow.classList.add("completed-row");
    } else {
      if (closestRow)
        closestRow.classList.remove("completed-row");
    }
  }

  componentDidUpdate() {

  }

  getData = cell => {
    const row = cell.getRow();
    const data = row.getData();
    return data;
  }

  render() {
    let value = "";
    const { cell, statusOptions } = this.props;
    const data = this.getData(cell);
    const isParentRow = data.parentTask === null;
    if (isParentRow) {
      value = cell.getValue();
      const selected = statusOptions.find(item => item.value === value)
      value = (selected && selected.label) || value
    }
    const statusTextColor = cell.getValue() === constants.TASK_STATUS.COMPLETE ? "completed-status-text" : null;
    return <div className={statusTextColor}>
      {data && data.id && value}
    </div>;
  }
}
