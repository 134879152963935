import { call, put, takeLatest } from "redux-saga/effects";
import { ADMIN_DASHBOARD_ACTIONS } from "../actions";
import adminApi from '../../api/adminApi';
import { toast } from 'react-toastify';

function* getList({ payload }) {
    const { filters } = payload;
    try {
        const data = yield call(_getList.bind(this, filters))
        yield put({ type: ADMIN_DASHBOARD_ACTIONS.GET_ADMIN_LIST_SUCCESS, payload: data })
    } catch (error) {
        yield put({ type: ADMIN_DASHBOARD_ACTIONS.GET_ADMIN_LIST_FAIL })
    }
}

function _getList(filters) {
    return adminApi.list(filters).then(response => {
        return response
    })
}

function* updateCurrentPlan({ payload }) {
    const { adminId } = payload
    try {
        const response = yield call(_update.bind(this, payload))
        const data = { adminId, response }
        yield put({ type: ADMIN_DASHBOARD_ACTIONS.UPDATE_CURRENT_PLAN_SUCCESS, payload: data })
        toast.success("Update current plan success !");
        payload.toggleModalCurrentPlan && payload.toggleModalCurrentPlan();
    } catch (error) {
        yield put({ type: ADMIN_DASHBOARD_ACTIONS.UPDATE_CURRENT_PLAN_FAIL })
        toast.error("Update current plan fail !");
    }
}

function _update(payload) {
    return adminApi.updateCurrentPlan(payload).then(response => {
        return response
    })
}


export function* watchAdminDashboardAsync() {
    yield takeLatest(ADMIN_DASHBOARD_ACTIONS.GET_ADMIN_LIST, getList);
    yield takeLatest(ADMIN_DASHBOARD_ACTIONS.UPDATE_CURRENT_PLAN, updateCurrentPlan);


}
