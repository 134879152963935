import React, { Component } from 'react';
import firebase from 'firebase/app';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { Provider } from 'react-redux';
import { store } from "./redux"
import langHelper from './helpers/LangHelper'
import config from "./config"

firebase.initializeApp(config.firebaseConfig);

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./components/layouts/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./pages/Login/Login'));
const Register = React.lazy(() => import('./pages/Register/Register'));
const RegisterRequest = React.lazy(() => import('./pages/Register/RegisterRequest'));
const ForgotPassword = React.lazy(() => import('./pages/Login/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./pages/Login/ResetPassword'));

class App extends Component {

  render() {
    return <Provider store={store}>
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/register-request" name="Register Request Page" render={props => <RegisterRequest {...props} />} />
            <Route exact path="/forgot-password" name="Register Page" render={props => <ForgotPassword {...props} />} />
            <Route exact path="/reset-password" name="Reset password Page" render={props => <ResetPassword {...props} />} />
            <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </Provider>
  }
  componentDidMount() {
    langHelper.initLanguage();
  }
}

export default App;
