import { call, put, takeLatest, select } from "redux-saga/effects";
import _ from 'lodash';
import { SIDEBAR_ACTIONS } from '../actions'
import sidebarApi from '../../api/sidebarApi';
import { toast } from 'react-toastify';
import strings from '../../localization';

function* requestSidebar(body) {
    try {
        const data = yield call(getRequestSidebar.bind(this, body.payload))
        yield put({ type: SIDEBAR_ACTIONS.GET_SIDEBAR_SUCCESS, payload: data })
    } catch (error) {
        yield put({ type: SIDEBAR_ACTIONS.GET_SIDEBAR_FAIL })
    }
}

function* requestSidebarLoadMore(body) {
    try {
        const data = yield call(getRequestSidebar.bind(this, body.payload))
        yield put({ type: SIDEBAR_ACTIONS.LOADMORE_SIDEBAR_SUCCESS, payload: data })
    } catch (error) {
        yield put({ type: SIDEBAR_ACTIONS.LOADMORE_SIDEBAR_FAIL })
    }
}

function* requestDeleteSidebarFile(data) {
    const {payload} = data;
    try {
        const isDeleteSuccess = yield call(deleteSidebarFile.bind(this, payload));
        if (isDeleteSuccess) {
          const cell = yield select(state => state.sidebar.cell);
          updateFileNumber(cell, payload.list.length, 'delete');
          yield put({ type: SIDEBAR_ACTIONS.DELETE_SIDEBAR_SUCCESS, payload: payload.list })
        }
        payload.callback();
    } catch (error) {
        yield put({ type: SIDEBAR_ACTIONS.DELETE_SIDEBAR_FAIL })
        payload.callback();
    }
}

function getRequestSidebar(payload) {
    return sidebarApi.getSideBarFileV2(payload).then(response => {
        return response
    })
}

function deleteSidebarFile(data) {
    const { list } = data;
    return sidebarApi.deleteSidebarFile({list_id: list}).then(response => {
        toast.success(strings.delete_file_success);
        return true
    }).catch(() => {
        toast.error(strings.delete_file_fail);
        return false
    })
}

function* requestUploadSidebar(body) {
    try {
        const data = yield call(uploadRequestSidebar.bind(this, body.id, body.file));
        yield put({ type: SIDEBAR_ACTIONS.UPLOAD_SIDEBAR_SUCCESS, payload: data })
    } catch (error) {
        yield put({ type: SIDEBAR_ACTIONS.UPLOAD_SIDEBAR_FAIL })
    }
}

function uploadRequestSidebar(id, file) {
    return sidebarApi.uploadSidebarFile(id, file).then(response => {
        toast.success(strings.upload_success)
        return response
    }).error(error=>{
        toast.error(strings.upload_fail)
    })
}

function* requesPushSidebar(body) {
    try {
        const { payload } = body;
        const cell = yield select(state => state.sidebar.cell);
        if(_.isArray(payload)){ updateFileNumber(cell, payload.length, 'upload') }
        else { updateFileNumber(cell, 1, 'upload')}
        yield put({ type: SIDEBAR_ACTIONS.PUSH_SIDEBAR_REQUEST_SUCCESS, payload: payload })
    } catch(error) {
        yield put({ type: SIDEBAR_ACTIONS.PUSH_SIDEBAR_REQUEST_FAIL })
    }
}

function updateFileNumber(cell, number, type) {
  const updateRow = cell && cell.getRow();
  const rowData = updateRow && updateRow.getData();
  if (type === 'upload') {
    updateRow && updateRow.update({ countAttachment: rowData.countAttachment + number });
  } else {
    updateRow && updateRow.update({ countAttachment: rowData.countAttachment - number });
  }
}

export function* watchSidebarAsync() {
    yield takeLatest(SIDEBAR_ACTIONS.GET_SIDEBAR_REQUEST, requestSidebar);
    yield takeLatest(SIDEBAR_ACTIONS.LOADMORE_SIDEBAR_REQUEST, requestSidebarLoadMore);
    yield takeLatest(SIDEBAR_ACTIONS.UPLOAD_SIDEBAR_REQUEST, requestUploadSidebar);
    yield takeLatest(SIDEBAR_ACTIONS.DELETE_SIDEBAR_REQUEST, requestDeleteSidebarFile);
    yield takeLatest(SIDEBAR_ACTIONS.PUSH_SIDEBAR_REQUEST, requesPushSidebar);
}
