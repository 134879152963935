export default {
    "#": "#",
    home: "ホーム",
    attachments: "添付ファイル",
    history: "履歴",
    setting: "設定",
    user_setting: "ユーザー設定",
    company_QA: "会社 A's QA シート",
    tag_not_available: "参照タグが見つかりません",
    invite_user_success: "ユーザー招待を完了しました",
    invite_email_fail_message: "招待に失敗しました",
    email_exist_error: "このメールアドレスは既に登録されています",
    change_password_success: "パスワードの変更に成功しました",
    change_password_fail: "パスワードの変更に失敗しました",
    current_password_incorrect: "パスワードが一致しません",
    update_profile_success: "プロファイルが正常に更新されました",
    update_profile_error: "プロファイルの更新に失敗しました",
    current_password: "現在のパスワード",
    new_issue: "新しい質問",
    update_issue: "質問の更新",
    all_changes: "すべての変更",
    notifications: "通知",
    inviter: "招待者",
    confirm_invitation: "招待の確認",
    confirm_invitation_user: "招待の確認ユーザー",
    invitation_date: "招待日",
    reject: "拒否",
    confirm_invite_success: "招待に成功しました",
    status: "ステータス",
    priority: "優先度",
    issue: "質問",
    issues: "質問",
    assignee: "依頼者",
    answer: "回答",
    upload: "アップロード",
    comment: "コメント",
    title: "タイトル",
    time: "時間",
    add_file: "ファイルを追加する",
    all_files: "すべてのファイル",
    question: "質問",
    last_update: "最終更新",
    files: "ファイル",
    reply: "応答",
    search_bar: "検索バー",
    undo: "元に戻す",
    save: "保存する",
    share: "共有する",
    language: "言語",
    add_new: "新規作成",
    select_project: "プロジェクトを選択",
    project_code: "プロジェクトコード",
    project_name: "プロジェクト名",
    author: "作成者",
    created_date: "作成日",
    updated_date: "更新日",
    text_signup: "SmartQAに今すぐ参加して、QAシートの連絡を効率化しましょう",
    text_signin: "ログインページに移動します！",
    join: "参加",
    previous: "前",
    next: "次",
    search: "検索",
    project_search_placeholder: "プロジェクト名またはコードで検索",
    history_search_placeholder: "履歴コンテンツで検索",
    size: "サイズ",
    auth_invalid: "認証が正しく実行されませんでした。",
    project_selection: "プロジェクト選択",
    logout: "ログアウト",
    login: "ログイン",
    ok: "はい",
    username: "ユーザー名",
    fullname: "フルネーム",
    password: "パスワード",
    confirm_password: "パスワードを認証する",
    required_login: "ユーザー名とパスワードを入力してください。",
    wrong_login_info: "ユーザー名またはパスワードが間違っています。",
    create: "作成",
    create_project: "プロジェクトを作成",
    cancel: "キャンセル",
    project_name_placeholder: "プロジェクト名を入力してください",
    create_project_sucess: "プロジェクトを作成しました",
    create_project_fail: "プロジェクト作成に失敗しました",
    empty_data: "データがありません",
    signup: "サインアップ",
    signin: "サインイン",
    register_now: "今すぐ登録",
    delete: "削除する",
    update: "更新する",
    upload_success: "ファイルアップロードの成功 !",
    upload_fail: "ファイルのアップロードが失敗する !",
    update_success: "更新に成功しました！",
    update_fail: "更新に失敗しました！",
    learn_more: "もっと詳しく知る",
    take_the_tour: "もっと詳しく知る",
    delete_issue_success: "質問を削除しました",
    search_qa: "QAを検索",
    hide_closed: "非表示",
    confirm_delete_text: "このアイテムを削除しますか?",
    confirm_resend_text: "また招待状を送ります?",
    confirm_delete_folder: "このアイテムを削除しますか?",
    project_hercules: "Hercules プロジェクト",
    navigation: "ナビゲーション",
    account: "アカウント",
    reset: 'リセット',
    share_project_success: "プロジェクトを共有しました",
    share_project: "プロジェクトを共有する",
    copy_project: "コピーする",
    date_time: "日時",
    task: "タスク",
    action: "アクション",
    create_task: "タスクを作成",
    update_task: "更新タスク",
    delete_task: "タスクを削除",
    create_sub_task: "サブタスクを作成",
    update_sub_task: "サブタスクを更新",
    delete_sub_task: "サブタスクを削除",
    create_attachment: "タスクに添付ファイルを追加する",
    update_attachment: "タスク内の添付ファイル[_file_name_]を更新します",
    delete_attachment: "タスクから添付ファイルを削除",
    delete_file_success: "ファイルの削除に成功しました",
    delete_file_fail: "ファイルの削除に失敗しました",
    project_history: "プロジェクト履歴",
    confirmation: "確認",
    thumbnail: "サムネイル",
    file_name: "ファイル名",
    date: "日付",
    user: "ユーザー",
    users: "ユーザー",
    download: "ダウンロード",
    remove: "削除",
    load_more: "読み込む",
    and: "そして",
    order: "依頼",
    after: "後",
    before: "前",
    add_user: "ユーザーを追加する",
    accounts: "アカウント",
    level: "レベル",
    options: "オプション",
    manager: "管理者",
    email: "メールアドレス",
    can_not_add_user: "ユーザーを追加できません",
    high: "高",
    normal: "中",
    low: "低",
    open: "開く",
    in_progress: "進行中",
    close: "閉じる",
    invite: "招待する",
    invite_user: "ユーザーを招待",
    list_invalid: "メールアドレスが無効です",
    confirm_invite_email: "これらのメールアドレスに招待状を送りますか？",
    send: "送信",
    list_email: "メーリングリスト",
    back: "戻る",
    confirm: "確認",
    confirm_upgrade: "アップグレードを確認",
    input_list_email: "メールアドレスを入力してください",
    register: "登録",
    first_name: "名",
    romanized_first_name: "ローマ字名",
    last_name: "姓",
    romanized_last_name: "ローマ字の姓",
    field_required: "必須入力項目です",
    password_min_length: "パスワードは6文字以上で設定してください",
    password_dont_match: "パスワードが一致しません",
    register_success_title: "登録に成功しました！",
    register_success_content: "ログインしてsmartQAに参加できます！",
    request_register_success_title: "リクエストに成功しました！",
    request_register_success_content: "登録リンクを送信しました。メールを確認して登録を完了してください！",
    register_link_expired: "登録リンクの有効期限が切れています",
    company: "会社",
    profile: "プロフィール",
    show_hide: "表示/非表示",
    change_password: "パスワード変更",
    updated_by: "更新者",
    yes: "はい",
    no: "いいえ",
    override: "上書き",
    warning: "警告",
    conflict_warning_message: "このセルの内容は変更されました",
    select: "選択する",
    reference_tag: "参照タグ",
    sub_title_download: "ダウンロード",
    sub_title_delete: "削除する",
    error: "エラー",
    disconnect_content: "接続を確認してください！",
    pending_invitations: "保留中の招待",
    closed: "クローズ",
    complete: "完了",
    delete_project_warning: "このプロジェクトを削除すると、データベースからすべての情報が削除されます。元に戻すことはできません",
    delete_project_guild: "削除を実行するには、\" DELETE \" と入力してください",
    close_project_warning: "閉じられたプロジェクトは読み取り専用モードに設定されます。 ユーザーはプロジェクトで何かを追加または編集できなくなります。",
    close_project_guild: "閉じてもよい場合には、\" CLOSE \" と入力します",
    leave_project: "このプロジェクトから退出する",
    close_project: "このプロジェクトを終了する",
    open_project: "このプロジェクトを開く",
    delete_project: "このプロジェクトを削除",
    delete_project_title: "プロジェクトの削除",
    close_project_title: "プロジェクトを終了する",
    open_project_title: "プロジェクトを開く",
    leave: "退出する",
    view: "見る",
    export: "書き出す",
    leave_project_title: "プロジェクトから退出する",
    leave_project_warning_user: "このプロジェクトを終了しますか？",
    leave_project_warning_admin: "あなたはこのプロジェクトの管理者ですが、本当に退出しますか？",
    leave_project_warning_admin_assign: "退出する場合は、このプロジェクトにマネージャーを割り当ててください !",
    delete_project_error_message: "このプロジェクトを削除できません",
    close_project_success_message: "プロジェクトを終了ました",
    close_project_error_message: "プロジェクトを終了する際にエラーが発生しました",
    close_project_confirm_error_message: "プロジェクトを閉じる場合は\" CLOSE \" と入力してください",
    open_project_warning: "このプロジェクトを再度開きますか？",
    open_project_guild: "開く場合は、\" OPEN \" と入力してください",
    open_project_success_message: "プロジェクトを正常に開きました",
    open_project_error_message: "エラーが発生しました",
    open_project_confirm_error_message: "開く場合は、\" OPEN \" と入力してください",
    general_files: "一般ファイル",
    description: "説明",
    add_folder: "フォルダーを追加",
    add_folder_success: "フォルダーを追加しました",
    delete_success: "削除しました",
    delete_fail: "削除に失敗しました",
    new_folder: "新しいフォルダ",
    forgot_password: "パスワードを忘れた方はこちら",
    invalid_email: "無効なメールアドレス",
    request_reset_password_successful: "パスワードをリセットするにはメールを確認してください",
    enter_email: "メールアドレスを入力してください",
    project_settings: "プロジェクト設定",
    customize_columns: "列をカスタマイズする",
    reset_password: "パスワードのリセット",
    reset_password_success: "パスワードをリセットしました",
    reset_password_fail: "パスワードのリセットに失敗しました",
    reset_password_success_notice: "新しいパスワードは正常に変更されました！",
    reset_password_expired: "パスワードのリセットリンクの有効期限が切れています",
    billing: "支払情報",
    invoices: "請求書",
    current_plan: "最新計画",
    plan_view_plans: "計画ビュー計画",
    basic_plan: "Basic Plan",
    change_plan: "プランの変更",
    next_billing_date: "次請求日",
    next_plan: "次の計画",
    billing_information: "請求情報",
    name: "名前",
    company_name: "会社名",
    company_address: "会社の住所",
    city_country: "市、国",
    zip: "郵便番号",
    personal_address: "住所",
    payment_methods: "支払い方法",
    credit_card_or_bank_tranfer: "クレジットカードまたは銀行振込",
    change_my_plan: "プランの変更",
    upgrade: "アップグレード",
    account_deletion: "アカウントの削除",
    account_deletion_warning: "これにより、アカウントと関連データが削除されます。元に戻すことはできません。",
    delete_my_account: "アカウントの削除",
    confirm_delete_account: "アカウントを削除してもよろしいですか？",
    create_success: "作成に成功しました",
    create_fail: "作成に失敗しました",
    add_card: "カードを追加",
    credit_card: "クレジットカード",
    bank_transfer: "銀行振込",
    select_payment_method: "支払い方法の選択",
    list_credit_card: "クレジットカードのリスト",
    upgrade_account_message: "この機能を使用するには、アカウントをアップグレードする必要があります！",
    confirm_payment: "支払いの確認",
    select_method_upgrade: "アカウントをアップグレードする前に支払い方法を選択する必要があります！",
    pay_with_card: "以下のクレジットカードで取引を行います:",
    update_payment_method: "支払い方法の更新",
    pay: "支払う",
    add_new_credit_card: "新しいクレジットカードを追加",
    free: "無料",
    basic: "基本",
    pro: "プロ",
    pro_translation: "プロ+翻訳",
    limit_invite_email_error: "電子メールの数が上限を超えています。より多くの人を招待するには、アカウントをアップグレードする必要があります。",
    select_type_payment: "支払い方法の選択",
    from: "変更前",
    plan: "計画",
    monthly: "月",
    yearly: "年",
    per_month: "月額",
    per_year: "年額",
    selected: "選択済み",
    jpy: "¥",
    usd: "$",
    you_save: "保存します",
    downgrade: "ダウングレード",
    number_of_object: "オブジェクトの数",
    price: "価格",
    max_attachment_storage: "添付ファイル容量上限",
    customizations_themes: "カスタマイズデザイン",
    max_collaborators: "登録可能者数",
    download_only: "ダウンロードのみ",
    unlimited: "無制限",
    '0MB': "0MB",
    '250MB': "250MB",
    '5GB': "5GB",
    '380yen': '380円',
    '780yen': '780円',
    change: "変更",
    'free_des': "'誰とでも自由にコラボレーション",
    '3_team_members': "計3名",
    '3_projects': "合計3つのプロジェクト",
    'file_download_only': "ファイルのダウンロードのみ",
    'basic_des': "始めましょう",
    '5_team_members': "プロジェクトごとに合計5人のメンバー",
    '30_team_members': "プロジェクトごとに最大30名のメンバー",
    '10_projects': "合計10プロジェクト",
    '250_mb_store': "ファイルのアップロード用に250 MBのストレージ",
    'buy_basic': "基本を購入",
    'pro_des': "プロユーザー向けのプロ機能",
    'unlimited_team_members': "プロジェクトごとに無制限のメンバー",
    'unlimited_projects': "無制限の合計プロジェクト",
    '10_gb_storage': "ファイルアップロード用の10GBストレージ",
    'export_qa': "QAプロジェクトをCSVにエクスポートできます",
    'buy_pro': "プロ購入",
    'buy_pro_plus': "プロ+翻訳を購入",
    'back_to_free': "無料に戻る",
    'back_to_basic': "基本に戻る",
    'get_started': '始めましょう',
    'pro_tran_des': "QAシートに自動翻訳",
    'all_pro_features': "全てのプロプランの機能",
    'auto_translation_list_language': "自動翻訳（英語、日本語、中国語、韓国語）",
    'add_member_for_your_auto_translation_project': "自動翻訳プロジェクトのすべてのメンバーは、プロ＋翻訳レベルではない場合でも、翻訳機能を使用できます",
    replies: "返信",
    view_history: "履歴の表示",
    actions: "アクション",
    type_here_to_add_reply: "ここに入力して返信します",
    type_here_to_add_issue: "ここに入力して課題を追加します",
    all_status: "すべてのステータス",
    display_issue: "表示された課題",
    current_tier_membership_time: "現在のティアメンバーシップ時間",
    total_projects: "全てのプロジェクト",
    signup_date: "登録日",
    of_project: "プロジェクト数",
    previous_plan: "前計画",
    previous_plan_start_date: "前計画開始日",
    previous_plan_total_months: "前計画の合計月数",
    current_plan_start_date: "最新の計画開始日",
    current_plan_total_months: "最新計画の合計月数",
    billing_type: "請求方法",
    activity_level: "活動レベル",
    min_months: "最小月",
    max_months: "最大月",
    min_project: "最小プロジェクト。。。",
    max_project: "最大プロジェクト。。。",
    start_date: " - 開始日 - ",
    end_date: " - 終了日 - ",
    type: "タイプ。。。",
    year: "年",
    month: "月",
    change_current_plan_text_modal: "このアカウントのプランを変更する",
    showing: "表示中",
    to: "~",
    of: "/",
    entities: "項目",
    select_file_upload: "アップロードするファイルを選択",
    drag_and_drop: "ここへファイルをドロップ",
    add_a_title: "タイトルを追加",
    add_a_comment: "コメントを追加",
    projects: "プロジェクト",
    project: "プロジェクト",
    add_project_placeholder: "ここに入力してプロジェクトを追加します",
    search_project: "プロジェクトを検索",
    super_admin: "特別管理者",
    login_smartQA: "smartQAにログインします。",
    sign_up_smartQA: "smartQAに登録します。",
    sign_up_des_1: "SmartQAに今すぐ参加して、QA シートの連絡を効率化させましょう",
    sign_up_des_2: "詳細はこちら",
    sign_up_des_3: "ツアーに参加",
    export_this_project: "このプロジェクトをエクスポート",
    browse_files: "ブラウズファイル",
    status_filter: "ステータスフィルター",
    translation: "翻訳",
    address: "住所",
    card_information: "カード情報",
    country_or_region: "国または地域",
    tags: "タグ",
    custom1: "カスタム1",
    custom2: "カスタム2",
    custom3: "カスタム3",
    copy: "テキストがクリップボードにコピーされました",
    resend_success: "招待はもう一回送られました",
    resend_fail: "招待は送られませんでした",
    date_joined: "登録日付",
    pin: "このプロジェクトを固定する",
    unpin: "このプロジェクトの固定を解除する",
    department: "部門",
    overview: "概要",
    plans: "計画",
    add_payment_method: "お支払い方法を追加",
    card_number: "カード番号",
    expiry: "有効期限",
    name_on_card: "カード名義人",
    contact_us: "お問い合わせ",
    bank_transfer_placeholder: "銀行振込に関するお問い合わせは、直接メールでご連絡ください ",
    current_plan_detail: "現在のプランは ",
    downgrade_plan_detail_1: "あなたへのダウングレード",
    downgrade_plan_detail_2: "計画が開始されます",
    select_card: "カードを選択",
    department_division: "部門",
    collaborators: "協力者",
    time_format: "時間フォーマット",
    update_settings: "設定の更新",
    save_settings_success: "設定は成功に更新しました",
    save_settings_error: "設定を更新することは失敗しました",
    project_limit_message: "プランのプロジェクトの上限に達しました。 アカウントをアップグレードするか、参加を終了してください。",
    project_limit_reached: "プランのプロジェクトの上限に達しました。 アカウントをアップグレードするか、参加を終了してください。",
    confirm_payment_description: "ここでは、支払いがいつ請求されるかなどの情報を追加できます。",
    paid: "支払済",
    pages: "ページ",
    invitation_reject: "招待が拒否されました",
    change_language: "言語を変えてください",
    auto_translate: "自動翻訳",
    enable_translations: "翻訳を有効にする",
    english: "英語",
    japanese: "日本語",
    korean: "韓国語",
    chinese: "中国人",
    you_have: "あなたが持っている",
    auto_translation_setting_title: "自動翻訳プロジェクトのクレジット。",
    enable_auto_translation: "自動翻訳を有効にする?",
    auto_translation_setting_subject: "これを元に戻すことはできません。翻訳クレジットを取り戻すには、プロジェクトを閉じるか削除する必要があります。",
    auto_translation_setting_subject_warning: "翻訳クレジットを取り戻すには、プロジェクトを閉じるか削除する必要があります。",
    import: "インポート",
    export_csv: "CSVをエクスポート",
    export_csv_file: "CSV +ファイルをエクスポート",
    original: "元の",
    auto_translation: "自動翻訳",
    enable: "有効にする",
    on: "オン",
    off: "オフ",
    import_data_into_this_project: "このプロジェクトにデータをインポートする",
    owner: "オーナー",
    choose_file_to_upload: "アップロードするファイルを選択",
    choose_another_file: "別のファイルを選択",
    issue_column: "発行コラム",
    answer_column: "回答欄",
    import_file_success: "ファイルのインポート成功",
    import_file_failure: "ファイルのインポートに失敗しました",
    tos_text_before: "",
    tos_text_middle: "と",
    tos_text_after: "に同意します",
    tos: "利用規約",
    privacy_policy: "個人情報保護方針",
    averageIssue: "1か月あたりの平均発行数",
    averageIssueTrans: "1か月あたりの平均発行A-T",
    averageCharacters: "1か月あたりの平均文字",
    averageCharactersTrans: "月あたりの平均文字A-T",
    group: "グループ",
    groups: "グループ",
    project_group: "プロジェクトグループ",
    new_project_group: "新しいプロジェクトグループ",
    project_group_name: "グループ名",
    no_group: "グループなし",
    change_group: "グループを変更",
    unsorted: "（未分類）",
    delete_project_group: "プロジェクトグループを削除",
    delete_project_group_confirm: "このグループを削除すると、プロジェクトが「未分類」グループに移動されます。 よろしいですか？",
    group_404: "このグループは存在しません",
    limited_space_message: "プランのファイルストレージ制限に達しました。ファイルを削除するか、アカウントをアップグレードしてください。",
    storage: "ストレージ",
    storage_used: "使用したストレージ"
}
