import _ from "lodash"
import { toast } from "react-toastify";
import { createEmptyRow } from "../../helpers/UtilsHelper";
import TabelHelper from '../../helpers/TabelHelper';
import taskApi from "../../api/taskApi";
import { priorityOptions, statusOptions } from "./TableOptions";
import constants from "../../constants";
import { removeEditBuffer } from "../../components/Tabulator/ultils";
import { store } from "../../redux";
import { APP_ACTIONS } from "../../redux/actions";
import { getDateTime } from '../../helpers/UtilsHelper';

export const highlightRowClass = 'highlight-row'

async function deleteRow(cell) {
    store.dispatch({ type: APP_ACTIONS.START_LOADING });
    let row = cell.getRow();
    let data = row.getData();
    let scrollInfo = getScrollInfo();

    try {
        await taskApi.delete(data.id).finally(() => {
            store.dispatch({ type: APP_ACTIONS.END_LOADING });
        })
        row.treeCollapse()
        row.delete()
        if (!TabelHelper.isParentRow(cell)) {
            adjustParentAfterDeleteChild(cell, data);
        }

        scrollToPos(scrollInfo);
    } catch (error) {
        toast.error(error);
    }
}

function adjustParentAfterDeleteChild(cell, data) {
    let parentRow = cell.getRow().getTreeParent();
    let parentData = parentRow.getData();
    let parentSubtasks = parentData.subtasks;
    let deletedRowIndex = parentSubtasks.findIndex(item => item.id === data.id);
    if (deletedRowIndex >= 0) {
        parentSubtasks.splice(deletedRowIndex, 1);
    }
    parentRow.update({ subtasks: parentSubtasks });
    parentRow.treeExpand();
}

async function addRow(row, rowData, cell, disabledRowMove) {
    store.dispatch({ type: APP_ACTIONS.START_LOADING });
    const table = row.getTable();
    const scrollInfo = getScrollInfo();
    const mentionUsers = getRowMentionUser(rowData);
    if (!_.isEmpty(mentionUsers)) {
        rowData.mentionUsers = mentionUsers
    }

    rowData.order = 0;
    try {
        const updatedData = await taskApi.create(rowData).finally(() => {
            store.dispatch({ type: APP_ACTIONS.END_LOADING });
        });
        let emptyRow = createNewRow(updatedData);
        if (TabelHelper.isParentRow(cell)) {
            const newRow = await addParentRow(updatedData, emptyRow, row, table, scrollInfo);
            moveToRightPriority(newRow, disabledRowMove)
            // focusEditRow(row);
        } else {
            addChildRow(cell, updatedData, emptyRow, scrollInfo);
        }

        removeEditBuffer(rowData.project, null);
    } catch (error) {
        toast.error(error);
    }
}

function moveToTheEnd(row) {
    const rowsData = row.getTable().getData();
    const moveTo = rowsData[rowsData.length - 2].id;
    executeMove(row, moveTo, false);
}

function moveToRightPriority(row) {
    const rowData = row.getData();
    const rowsData = row.getTable().getData();
    let moveTo = 0;
    const { priority } = rowData;
    const rows = rowsData.filter(item => item.priority >= priority
        && item.taskStatus !== constants.TASK_STATUS.CLOSE
        && item.id !== rowData.id);
    if (rows.length > 0) {
        moveTo = rows[rows.length - 1].id;
        executeMove(row, moveTo, false);
    } else {
        moveTo = rowsData[0].id;
        executeMove(row, moveTo, true);
    }
    return moveTo;
}

function executeMove(row, moveTo, isAfter) {
    row.move(moveTo, isAfter);
    row.reformat();
    row.scrollTo();
    row.select();
    setTimeout(() => {
        row.deselect();
    }, 1000);
}

function focusEditRow(row) {
    let focusCell = row.getCells().find(cell => cell.getField() === "issue");
    focusCell.edit(true);
}

async function addParentRow(updatedData, emptyRow, row, table, scrollInfo) {
    updatedData.subtasks = [{ ...emptyRow, parentTask: updatedData.id }];
    await row.update(updatedData);
    row.reformat();
    await table.addRow(emptyRow);
    scrollToPos(scrollInfo);
    return row;
}

function _getAnswerBackupData(rowData) {
    const answerBackupData = Array(2).fill(null);
    const { subtasks } = rowData;
    const countTask = subtasks && subtasks.length;
    //Add data for parent
    const { answer, updatedAnswerBy, updatedAnswerAt } = rowData;
    answerBackupData[0] = { answer, updatedAnswerBy, updatedAnswerAt }

    //Add data for latest child
    if (countTask > 1) {
        const latestChild = subtasks[countTask - 2];
        answerBackupData[1] = {
            answer: latestChild.answer,
            updatedAnswerBy: latestChild.updatedAnswerBy,
            updatedAnswerAt: latestChild.updatedAnswerAt
        };
    }
    return answerBackupData;
}

async function addChildRow(cell, updatedData, emptyRow, scrollInfo) {
    let parentRow = cell.getRow().getTreeParent();
    let parentRowData = parentRow.getData();
    parentRowData.answerBackup = _getAnswerBackupData(parentRowData);
    emptyRow.parentTaskNum = parentRowData.taskNum;
    updatedData.parentTaskNum = parentRowData.taskNum;
    let subtasks = parentRowData.subtasks;
    subtasks.splice(subtasks.length - 1, 1, updatedData);
    subtasks.sort((a, b) => a.taskNum - b.taskNum);
    subtasks.push({ ...emptyRow, parentTask: parentRowData.id });
    await parentRow.update({ subtasks: subtasks })
    parentRow.treeExpand();
    scrollToPos(scrollInfo)
}

function createNewRow(updatedData) {
    let emptyRow = createEmptyRow();
    emptyRow.project = updatedData.project;
    return emptyRow;
}

function getScrollInfo() {
    let tableElement = document.querySelector(".tabulator-tableHolder");
    let oldScrollTop = tableElement.scrollTop;
    let oldScrollLeft = tableElement.scrollLeft;
    return { tableElement, oldScrollTop, oldScrollLeft }
}

function scrollToPos(scrollInfo) {
    const { tableElement, oldScrollTop, oldScrollLeft } = scrollInfo
    if (!tableElement) {
        return
    }
    try {
        let currentScrollTop = tableElement.scrollTop;
        if (currentScrollTop < oldScrollTop) {
            tableElement.scrollTo(oldScrollLeft, oldScrollTop);
        }
    } catch (error) { }
}

function getRowMentionUser(rowData) {
    const mentionUsersIssue = getMentionUser(rowData["issue"]);
    const mentionUsersAnswer = getMentionUser(rowData["answer"]);
    let mentionUsers = mentionUsersIssue.concat(mentionUsersAnswer);
    mentionUsers = new Set(mentionUsers);
    mentionUsers = Array.from(mentionUsers).sort();
    return mentionUsers.join();
}

function getMentionUser(cellData) {
    const mentionUserList = [];
    try {
        if (cellData) {
            const mentionRawObject = JSON.parse(cellData).entityMap;
            for (let key in mentionRawObject) {
                const value = mentionRawObject[key];
                if (value.type === "mention") {
                    const userLink = value.data.mention.link;
                    const userId = userLink.split('/user/')[1];
                    mentionUserList.push(userId);
                }
            }
        }
    } catch { }

    return mentionUserList;
}

function _handleRenderTypeDraft(value) {
    if (_.isEmpty(value)) {
        return "";
    }

    const draftObject = JSON.parse(value);
    let text = "";
    draftObject.blocks.map(item => {
        text += item.text;
        return false;
    });
    return text;
}

function _getAssigneeData(userList, value) {
    userList = userList.map(item => {
        const label = `${item.firstName || ''} ${item.lastName || ''}`
        return {
            label,
            value: item.id,
            company: item.company
        }
    });
    const cellData = value && userList.find(item => item.value === value);
    return cellData ? `${cellData.label || ''}-${cellData.company || ''}` : '';
}

function _getLastUpdate(data) {
    const updateBy = data.updatedBy || data.createdBy || "";
    const firstName = updateBy.firstName ? updateBy.firstName : "";
    const lastName = updateBy.lastName ? updateBy.lastName : "";
    const company = updateBy.company ? `(${updateBy.company})` : "";
    return `${getDateTime(data.updatedAt) || ""} ${firstName} ${lastName} ${company}`;
}

function _getObjectItem(item, userList) {
    if (item.id) {
        const { answer, assignee, issue, priority, taskStatus, taskNum, parentTaskNum } = item;
        let task = taskNum;
        if (parentTaskNum) {
            task = `${parentTaskNum}.${taskNum}`;
        }
        const _status = statusOptions().find(item => item.value && item.value === taskStatus);
        const _priority = priorityOptions().find(item => item.value && item.value === priority);
        const _issue = _handleRenderTypeDraft(issue);
        const _answer = _handleRenderTypeDraft(answer);
        const _assignee = _getAssigneeData(userList, assignee);
        const _lastUpdate = _getLastUpdate(item);
        return {
            task,
            status: _status ? _status.label : '',
            priority: _priority ? _priority.label : '',
            issue: _issue || '',
            answer: _answer || '',
            assignee: _assignee || '',
            lastUpdate: _lastUpdate || '',
        };
    }
    return false;
}

function getExportData(data, userList) {
    const exportArray = [];
    data.forEach(item => {
        const { subtasks } = item;
        const itemObject = _getObjectItem(item, userList);
        if (itemObject) {
            exportArray.push(itemObject);
        }
        if (subtasks && subtasks.length > 0) {
            subtasks.forEach(child => {
                const childObject = _getObjectItem(child, userList);
                if (childObject) {
                    exportArray.push(childObject);
                }
            })
        }
    })
    return exportArray;
}

function highlightRow(row) {
    row && row.getElement().classList.add(highlightRowClass)
}

function replaceTranslate(data) {
    return data.map(task => {
        if (task.issueTranslations && task.issueTranslations.length) {
            task.issue = task.issueTranslations[0].content
        }
        if (task.answerTranslations && task.answerTranslations.length) {
            task.answer = task.answerTranslations[0].content
        }
        if(task.subtasks) {
            task.subtasks = replaceTranslate(task.subtasks)
        }
        return task
    })
}

export {
    deleteRow,
    addRow,
    moveToTheEnd,
    moveToRightPriority,
    getScrollInfo,
    scrollToPos,
    focusEditRow,
    getMentionUser,
    getRowMentionUser,
    getExportData,
    highlightRow,
    replaceTranslate,
}
