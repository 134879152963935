import axios from 'axios'
import appConfig from '../config'
import auth from './AuthHelper'
import { store } from '../redux'
import { LOGIN_ACTIONS, APP_ACTIONS  } from '../redux/actions'

const HttpClient = axios.create({
    baseURL: appConfig.API_END_POINT,
    timeout: 600000,
});

HttpClient.interceptors.request.use(
    function (config) {
        addRequestHeader(config)
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

HttpClient.interceptors.response.use(function (response) {
    saveLastRequestTime(response);
    return response;
}, function (error) {
    handleError(error)
    return Promise.reject(error);
});

function saveLastRequestTime(response) {
    if (response.config.url.indexOf('refresh-token') >= 0) {
        window.lastRequest = new Date();
    }
}

function addRequestHeader(config) {
    addToken(config);
    addLanguage(config);
}

function addToken(config) {
    let token = auth.getToken();
    if (token) {
        config.headers["Authorization"] = `jwt ${token}`;
    }
}

function addLanguage(config) {
    let language = store.getState().lang.lang.value;
    language = adjustLang(language);
    if (language) {
        config.headers["Accept-Language"] = language;
    }
}

function adjustLang(lang) {
    switch (lang) {
        case 'ja':
            return 'ja'
        default:
            return lang
    }
}

function handleError(error) {
    if(!error.response){
        store.dispatch({ type: APP_ACTIONS.DISCONNECT_ON})
    }
    const status =  error.response && error.response.status
    if (status === 401) {
        forceLogout();
    }
}

function forceLogout() {
    auth.destroyToken();
    store.dispatch({ type: LOGIN_ACTIONS.LOGOUT_EXPIRE });
}

export default HttpClient;
