import { call, put, takeLatest } from "redux-saga/effects";
import { SETTING_ACTIONS } from '../actions';
import settingApi from '../../api/settingApi';

function* requestGetSetting() {
    try {
        const data = yield call(getRequestSetting.bind(this))
        yield put({ type: SETTING_ACTIONS.GET_SETTING_SUCCESS, payload: data })
    } catch (error) {
        yield put({ type: SETTING_ACTIONS.GET_SETTING_FAIL })
    }
}

function* requestSetSetting(inputData) {
    try {
        const data = yield call(setRequestSetting.bind(this, inputData.dataSetting, inputData.setting));
        yield put({ type: SETTING_ACTIONS.SET_SETTING_SUCCESS, payload: data.data })
    } catch (error) {
        yield put({ type: SETTING_ACTIONS.SET_SETTING_FAIL })
    }
}

function* requestSetSettingKey(inputData) {
  try {
      const data = yield call(setRequestSettingKey.bind(this, inputData.key, inputData.value));
      yield put({ type: SETTING_ACTIONS.SET_SETTING_KEY_SUCCESS, payload: data.data })
  } catch (error) {
      yield put({ type: SETTING_ACTIONS.SET_SETTING_KEY_FAIL })
  }
}



function getRequestSetting() {
    return settingApi.getSetting().then(response => {
        return response
    })
}


function setRequestSetting(id, value) {
    return settingApi.updateSetting(id, value).then(response => {
        return response
    })
}

function setRequestSettingKey(key, value) {
  return settingApi.updateSettingColumns(value, key).then(response => {
      return response
  })
}

export function* watchSettingAsync() {
    yield takeLatest(SETTING_ACTIONS.GET_SETTING, requestGetSetting);
    yield takeLatest(SETTING_ACTIONS.SET_SETTING, requestSetSetting);
    yield takeLatest(SETTING_ACTIONS.SET_SETTING_KEY, requestSetSettingKey);
}
