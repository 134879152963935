import {
    SETTING_ACTIONS
} from "../actions";
import constants from "../../constants";

const initState = {
    loading: false,
    data: [
        { key: "table_id", value: "20" },
        { key: "table_status", value: "110" },
        { key: "table_priority", value: "110" },
        { key: "table_issue", value: "350" },
        { key: "table_answer", value: "350" },
        { key: "table_lastupdate", value: "200" },
        { key: "table_files", value: "100" },
        { key: "table_reply", value: "100" },
        { key: "Language", value: constants.LANGUAGE_CODE.ENGLISH },
        { key: "projects_display_rows", value: "5"},
        { key: "time_format", value: "12h"}
    ],
    savingKey: false,
    success: false,
    error: false
};

export default function settingReducer(state = initState, action) {
    let { type, payload } = action;
    switch (type) {
        case SETTING_ACTIONS.GET_SETTING:
            return {
                ...state,
                loading: true,
            }
        case SETTING_ACTIONS.GET_SETTING_SUCCESS:
            let newData = payload;
            return {
                ...state,
                data: newData,
                loading: false,
            }
        case SETTING_ACTIONS.GET_SETTING_FAIL:
            return {
                ...state,
                loading: false,
            }
        case SETTING_ACTIONS.SET_SETTING:
            return {
                ...state,
                loading: true,
            }
        case SETTING_ACTIONS.SET_SETTING_SUCCESS: {
            let data = JSON.parse(JSON.stringify(state.data));
            let itemIndex = data.findIndex(item => item.key === payload.key);
            if (itemIndex >= 0) {
              data[itemIndex].value = payload.value;
            }

            return {
                ...state,
                data,
                loading: false,
            }
          }
        case SETTING_ACTIONS.SET_SETTING_KEY: {
          return {
            ...state,
            savingKey: true
          }
        }
        case SETTING_ACTIONS.SET_SETTING_KEY_SUCCESS: {
          let data = JSON.parse(JSON.stringify(state.data));
          let itemIndex = data.findIndex(item => item.key === payload.key);
          if (itemIndex >= 0) {
            data[itemIndex].value = payload.value;
          } else {
            data.push(payload);
          }

          return {
              ...state,
              data,
              loading: false,
              savingKey: false,
              success: true
          }
        }
        case SETTING_ACTIONS.SET_SETTING_FAIL:
            return {
                ...state,
                loading: false,
            }
        case SETTING_ACTIONS.SET_SETTING_KEY_FAIL:
              return {
                  ...state,
                  savingKey: false,
                  error: true
              }
        case SETTING_ACTIONS.SETTING_DISMISS: {
          return {
            ...state,
            success: false,
            error: false
          }
        }
        default:
            return state;
    }
}
