import { call, put, takeLatest, select } from "redux-saga/effects";
import firebase from 'firebase/app';
import 'firebase/auth';
import { LOGIN_ACTIONS } from '../actions'
import authApi from '../../api/authApi'
import authHelper from '../../helpers/AuthHelper'

function* requestAuth(dataLogin) {
    const { username, password } = dataLogin;
    try {
        const firebaseToken = yield call(firebaseAuth.bind(this, username, password))
        const data = yield call(postRequestAuth.bind(this, firebaseToken))
        authHelper.saveToken(data.token);
        yield put({ type: LOGIN_ACTIONS.LOGIN_SUCCESS, payload: data })
    } catch (error) {
        yield put({ type: LOGIN_ACTIONS.LOGIN_FAIL })
    }
}

function* requestAuthRegister({ data }) {
    try {
        authHelper.saveToken(data.token);
        yield put({ type: LOGIN_ACTIONS.LOGIN_SUCCESS, payload: data })
    } catch (error) {
        yield put({ type: LOGIN_ACTIONS.LOGIN_FAIL })
    }
}

async function postRequestAuth(firebaseToken) {
    return await authApi.authFirebase(firebaseToken)
}

async function firebaseAuth(username, password) {
    const data = await firebase.auth().signInWithEmailAndPassword(username, password);
    let token = await data.user.getIdToken()
    return token;
}

function* requestNewToken() {
    try {
        const token = yield select(state => state.auth.token)
        const data = yield call(postNewToken.bind(this, token))
        authHelper.saveToken(data.token);
        yield put({ type: LOGIN_ACTIONS.REFRESH_TOKEN_SUCCESS, payload: data })
    } catch (error) {
    }
}

function postNewToken(token) {
    return authApi.refreshToken(token).then(response => {
        return response
    })
}

export function* watchAuthAsync() {
    yield takeLatest(LOGIN_ACTIONS.LOGIN_AFTER_REGISTER, requestAuthRegister);
    yield takeLatest(LOGIN_ACTIONS.LOGIN, requestAuth);
    yield takeLatest(LOGIN_ACTIONS.REFRESH_TOKEN, requestNewToken);
}
