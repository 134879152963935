import "./Draft.scss"

import React from "react";
import _ from "lodash";

import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import { isIE11 } from '../../helpers/UtilsHelper'
import config from "../../config";
import { saveEditBuffer } from "./ultils"
import { removeAllBufferOnProject } from "../../components/Tabulator/ultils";

export default class DraftEditor extends React.Component {
  constructor(props) {
    super(props)
    this.editor = React.createRef();
    this.wrapper = React.createRef();
    this.mentionUser = createMentionPlugin({ mentionPrefix: '@', mentionTrigger: '@' });
    this.plugins = [this.mentionUser];

    let raw = EditorState.createEmpty();
    let cellValue = props.cell.getValue();
    if (!_.isEmpty(cellValue)) {
      try {
        const content = convertFromRaw(JSON.parse(cellValue));
        raw = EditorState.createWithContent(content);
      } catch (error) { }
    }

    this.initData(cellValue);
    this.state = {
      text: raw,
      userSuggestions: this.buildMentionList(this.props.userList),
    }
  }

  initData = (cellValue) => {
    const props = this.props;
    const data = props.cell.getRow().getData();
    this.oldRawText = cellValue;
    this.updatedFields = this.getLastUpdateField(props.type);
    this.updatedBy = data[this.updatedFields.updatedBy];
    this.updatedAt = data[this.updatedFields.updatedAt];
  }

  getLastUpdateField = (type) => {
    switch (type) {
      case "issue":
        return {
          updatedBy: "updatedIssueBy",
          updatedAt: "updatedIssueAt"
        };
      case "answer":
        return {
          updatedBy: "updatedAnswerBy",
          updatedAt: "updatedAnswerAt"
        };
      default:
        return {
          updatedBy: "updatedBy",
          updatedAt: "updatedAt"
        };
    }
  }

  buildMentionList = (userList) => {
    return userList.map(item => {
      const { firstName, lastName } = item;
      const fullName = `${firstName} ${lastName}`
      return {
        name: fullName,
        link: `#/user/${item.id}`,
        avatar: item.avatar || `https://ui-avatars.com/api/?name=${fullName}&size=40`,
      }
    })
  }

  getIssueString(item, issue) {
    let issueBlock = JSON.parse(item.issue);
    let text = issueBlock.blocks.reduce((a, b) => {
      let result = a + b.text;
      return result;
    }, "");
    issue = text || "";
    return issue;
  }

  onChange = (text) => {
    this.setState({ text })
  }

  onSearchUserChange = ({ value }) => {
    this.setState({
      userSuggestions: defaultSuggestionsFilter(value, this.buildMentionList(this.props.userList)),
    });
  };

  onAddMention = () => {
    // TODO: notify to mentioned user
  }

  focus = () => {
    this.editor.focus();
  }

  save = () => {
    let text = this.state.text.getCurrentContent();
    let plainText = text.getPlainText().trim();
    let raw = JSON.stringify(convertToRaw(text));
    let cellValue = this.props.cell.getValue();

    if (this.props.required) {
      if (plainText && plainText !== "") {
        this.props.onSuccess(raw)
      }
      else {
        this.props.cancel();
      }
    }
    else {
      if (_.isEmpty(cellValue) && _.isEmpty(plainText)) {
        this.props.cancel();
      }
      else {
        this.props.onSuccess(raw)
      }
    }

    this.clearSaveInterval();
  }

  normalizeHeight = () => {
    const row = this.props.cell.getRow();
    row.normalizeHeight()
  }

  componentDidMount() {
    this.props.onRendered(() => {
      this.normalizeHeight();
      let realEditor = this.wrapper.querySelector("div[contenteditable]");
      this.handleKeyEvent(realEditor);
      realEditor.focus();
      this.constantlySave();
      this.setEndOfContentEditable(realEditor)
    })
  }

  handleKeyEvent = (realEditor) => {
    realEditor.addEventListener("keydown", (e) => {
      switch (e.keyCode) {
        case 13: //ENTER
          if (!e.shiftKey) {
            this.save();
            this.clearSaveInterval();
          }
          break;

        case 27: //ESC
          this.props.cancel();
          removeAllBufferOnProject();
          this.clearSaveInterval();
          break;

        default:
          break;
      }
    });
  }

  componentDidUpdate() {
    this.normalizeHeight();
  }

  setEndOfContentEditable(contentEditableElement) {
    let text = this.state.text.getCurrentContent();
    let plainText = text.getPlainText().trim();
    if (_.isEmpty(plainText)) {
      return;
    }

    // user setTimeout to fix bug on Firefox and Edge, not found the root caution yet
    setTimeout(() => {
      if (!isIE11) {
        let range = document.createRange();
        range.selectNodeContents(contentEditableElement);
        range.collapse(false);
        let selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }, 0);
  }

  constantlySave = () => {
    const saveInterval = setInterval(() => {
      this.checkAndUpdateRow();
    }, config.CONSTANTLY_SAVE_INTERVAL);

    this.saveInterval = saveInterval;
  }

  clearSaveInterval = () => {
    if (this.saveInterval) {
      clearInterval(this.saveInterval);
    }
  }

  checkAndUpdateRow = () => {
    const editContext = convertToRaw(this.state.text.getCurrentContent());
    if (editContext.blocks.length === 1 && _.isEmpty(editContext.blocks[0].text)) {
      removeAllBufferOnProject();
      return;
    }

    const raw = JSON.stringify(editContext);
    if (raw !== this.oldRawText) {
      const { cell } = this.props;
      const row = cell.getRow();
      const field = cell.getField();
      this.saveBuffer(row, field, raw);
    }
  }

  saveBuffer(row, field, raw) {
    const rowData = row.getData();
    const { id, project } = rowData;
    saveEditBuffer(project, id, field, raw, rowData)
  }

  render() {
    const MentionUser = this.mentionUser.MentionSuggestions;
    const decorators = [{
      strategy: hashtagStrategy,
      component: HashtagSpan,
    },];
    return (
      <div ref={element => this.wrapper = element} onBlur={this.save}>
        <Editor
          editorState={this.state.text}
          plugins={this.plugins}
          onChange={this.onChange}
          decorators={decorators}
          ref={(element) => { this.editor = element; }}
        />
        <MentionUser
          onSearchChange={this.onSearchUserChange}
          suggestions={this.state.userSuggestions}
          onAddMention={this.onAddMention}
        />
      </div>
    );
  }
}

const HASHTAG_REGEX = /#[\w.]+/g;

export function hashtagStrategy(contentBlock, callback, contentState) {
  findWithRegex(HASHTAG_REGEX, contentBlock, callback);
}

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

export const HashtagSpan = (props) => {
  return (
    <span className="hash-tag" data-offset-key={props.offsetKey}>
      {props.children}
    </span>
  );
};
