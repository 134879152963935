import { call, put, takeLatest } from "redux-saga/effects";
import { PROJECT_ACTIONS } from "../actions";
import projectApi from '../../api/projectApi';
import { toast } from 'react-toastify';
import strings from '../../localization';

function* requestGetProjectSetting({ payload }) {
    const projectId = payload;
    try {
        const data = yield call(getRequestProjectSetting.bind(this, projectId))
        const { settings } = data;
        yield put({ type: PROJECT_ACTIONS.GET_PROJECT_SETTING_SUCCESS, payload: settings })
    } catch (error) {
        yield put({ type: PROJECT_ACTIONS.GET_PROJECT_SETTING_FAIL })
    }
}

function getRequestProjectSetting(projectId) {
    return projectApi.getProjectSetting(projectId).then(response => {
        return response
    })
}

function* requestSetProjectSetting({ payload }) {
    const { projectId, setting, ignoreToast } = payload;
    try {
        yield call(setRequestProjectSetting.bind(this, projectId, setting))
        yield put({ type: PROJECT_ACTIONS.SET_PROJECT_SETTING_SUCCESS, payload:setting })
        if (!ignoreToast) {
          toast.success(strings.update_success);
        }
    } catch (error) {
        yield put({ type: PROJECT_ACTIONS.SET_PROJECT_SETTING_FAIL })
        toast.error(strings.update_fail);
    }
}

function setRequestProjectSetting(projectId, setting) {
    return projectApi.setProjectSetting({ projectId, settings: setting }).then(response => {
        return response
    })
}

function* getProjectList(action) {
    const {payload} = action;
    try {
        const data = yield call(getTaskList.bind(this, payload))
        yield put({ type: PROJECT_ACTIONS.PROJECT_GET_LIST_SUCCESS, payload: data })
    } catch (error) {

    }
}

function getTaskList(payload) {
    return projectApi.list(payload).then(response => {
        return response
    })
}

export function* watchProjectAsync() {
    yield takeLatest(PROJECT_ACTIONS.PROJECT_GET_LIST, getProjectList);
    yield takeLatest(PROJECT_ACTIONS.GET_PROJECT_SETTING, requestGetProjectSetting);
    yield takeLatest(PROJECT_ACTIONS.SET_PROJECT_SETTING, requestSetProjectSetting);
}
