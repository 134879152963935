import moment from "moment";
import FileSaver from "file-saver";

export const saveFileFromResponse = (response) => {
    const disposition = response.headers['content-disposition'];
    const fileName = disposition ? disposition.split(";")[1].trim().split("=")[1] : null;
    const fileExtIndex = fileName.lastIndexOf('.');

    let downLoadName = `${moment.now().valueOf()}`;
    if (fileExtIndex >= 0) {
        let extension = fileName.slice(fileExtIndex + 1);
        downLoadName = `${downLoadName}.${extension}`;

        // save file csv
        if(extension === "csv"){
          let BOM = "\uFEFF";
          let csvData = BOM + response.data;
          let blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
          return FileSaver.saveAs(blob, downLoadName);
        }
        // end save file csv
    }
    return FileSaver.saveAs(new Blob([response.data]), downLoadName);
}
