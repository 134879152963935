import { call, put, takeLatest } from "redux-saga/effects";
import { LANG_ACTIONS } from '../actions'
import langApi from '../../api/langApi'
import strings from '../../localization'
import AuthHelper from '../../helpers/AuthHelper'

function* requestGetLang() {
    try {
        const data = yield call(getRequestLang.bind(this))
        const languageSetting = data.find(item => item.key === "Language");
        if (languageSetting) {
            strings.setLanguage(languageSetting.value)
            yield put({ type: LANG_ACTIONS.GET_LANG_SUCCESS, payload: languageSetting })
        }
    } catch (error) {
        yield put({ type: LANG_ACTIONS.GET_LANG_ERROR })
    }
}

function* requestSetLang(dataLang) {
    try {

        if (AuthHelper.getRole() === "guest") {
            const lang =  {
                key: dataLang.key,
                value: dataLang.lang,
                id: 0
            }
            yield put({ type: LANG_ACTIONS.SET_LANG_SUCCESS, payload: lang })
        }
        else {
            const data = yield call(setRequestLang.bind(this, dataLang.lang, dataLang.id, dataLang.key));
            const languageSetting = data.data;
            yield put({ type: LANG_ACTIONS.SET_LANG_SUCCESS, payload: languageSetting })
        }
    } catch (error) {
        yield put({ type: LANG_ACTIONS.SET_LANG_ERROR })
    }
}

function* initializeLang() {
  let langVal = yield call(localStorage.getItem.bind(localStorage, "lang"));
  langVal = JSON.parse(langVal);
  if (langVal) {
    try {
      strings.setLanguage(langVal.value);
    } catch(e) {
      yield call(localStorage.removeItem.bind(localStorage, "lang"));
    }
  }
  yield put({type: LANG_ACTIONS.INITIALIZE_LANG_SUCCESS, payload: {langVal}});
}

function* setLangTemp(props) {
  if (!props.payload) {
    return;
  }
  const langObj = {
    key: "Language",
    value: props.payload,
    id: 0
  }
  yield call(localStorage.setItem.bind(localStorage, "lang", JSON.stringify(langObj)));
}


function getRequestLang() {
    return langApi.listLang().then(response => {
        return response
    })
}


function setRequestLang(langVal, id, key) {
    return langApi.updateLang(langVal, id, key).then(response => {
        return response
    })
}

export function* watchLangAsync() {
    yield takeLatest(LANG_ACTIONS.GET_LANG, requestGetLang);
    yield takeLatest(LANG_ACTIONS.SET_LANG, requestSetLang);
    yield takeLatest(LANG_ACTIONS.INITIALIZE_LANG, initializeLang);
    yield takeLatest(LANG_ACTIONS.SET_LANG_TEMP, setLangTemp);
}
