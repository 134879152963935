const actions = {
  PROJECT_GROUP_CREATE: 'PROJECT_GROUP_CREATE',
  PROJECT_GROUP_CREATE_SUCCESS: 'PROJECT_GROUP_CREATE_SUCCESS',
  PROJECT_GROUP_CREATE_FAIL: 'PROJECT_GROUP_CREATE_FAIL',
  PROJECT_GROUP_GET_LIST: 'PROJECT_GROUP_GET_LIST',
  PROJECT_GROUP_GET_LIST_SUCCESS: 'PROJECT_GROUP_GET_LIST_SUCCESS',
  PROJECT_GROUP_GET_LIST_FAIL: 'PROJECT_GROUP_GET_LIST_FAIL',
  PROJECT_GROUP_GET: 'PROJECT_GROUP_GET',
  PROJECT_GROUP_GET_SUCCESS: 'PROJECT_GROUP_GET_SUCCESS',
  PROJECT_GROUP_GET_FAIL: 'PROJECT_GROUP_GET_FAIL',
  PROJECT_GROUP_DELETE: 'PROJECT_GROUP_DELETE',
  PROJECT_GROUP_DELETE_SUCCESS: 'PROJECT_GROUP_DELETE_SUCCESS',
  PROJECT_GROUP_DELETE_FAIL: 'PROJECT_GROUP_DELETE_FAIL',
  PROJECT_GROUP_CLEAR: 'PROJECT_GROUP_CLEAR',
  PROJECT_GROUP_RESET: 'PROJECT_GROUP_CREATE_RESET',
}

export default actions

export const createProjectGroup = (name) => ({ type: actions.PROJECT_GROUP_CREATE, payload: { name } });
export const resetProjectGroupState = () => ({ type: actions.PROJECT_GROUP_RESET });
export const getProjectGroups = () => ({ type: actions.PROJECT_GROUP_GET_LIST });
export const getProjectGroup = (id) => ({ type: actions.PROJECT_GROUP_GET, payload: {id} });
export const deleteProjectGroup = (id) => ({type: actions.PROJECT_GROUP_DELETE, payload: {id}});
export const clearCurrentGroup = () => ({type: actions.PROJECT_GROUP_CLEAR});
