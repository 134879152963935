import client from '../helpers/HttpClient'
import qs from 'query-string'
import constants from '../constants'
import { store } from '../redux'

const endpoint = '/tasksmanager/tasks/'
export default {
    async detail(taskId) {
        const path = `${endpoint}${taskId}/`;
        const response = await client.get(path);
        return response.data;
    },
    async getTaskByOrder({ projectId, taskNum }) {
        const path = `/tasksmanager/projects/${projectId}/tasks/get-by-task-num/?taskNum=${taskNum}`;
        const response = await client.get(path);
        return response.data;
    },
    async list(payload, query) {
        let queryString = "";
        if (query) {
            queryString = "?" + qs.stringify(query)
        }
        const { projectId } = payload;
        const path = `/tasksmanager/projects/${projectId}/tasks/${queryString}`;
        let response = await client.get(path, payload)
        return response.data
    },
    async create(payload) {
        payload.priority = payload.priority || constants.TASK_PRIORITY.NORMAL
        payload.taskStatus = payload.taskStatus || constants.TASK_STATUS.OPEN
        payload.taskNum = payload.taskNum || 0
        let path = `/tasksmanager/tasks/`;

        const state = store.getState();
        const { useTranslation, translationLanguage } = state.qa;
        const { projectId } = state.app;
        if (useTranslation[projectId]) {
            path += `?language_code=${translationLanguage[projectId]}`
        }

        let response = await client.post(path, payload);
        return response.data;
    },
    async delete(id) {
        const path = `/tasksmanager/tasks/${id}/`;
        let response = await client.delete(path);
        return response.data;
    },
    async update(payload) {
        const { id } = payload;
        let path = `/tasksmanager/tasks/${id}/`;

        const state = store.getState();
        const { useTranslation, translationLanguage } = state.qa;
        const { projectId } = state.app;
        if (useTranslation[projectId]) {
            path += `?language_code=${translationLanguage[projectId]}`
        }

        let response = await client.patch(path, payload);
        return response.data;
    },
    async moveTaskTo(task, toOrder) {
        const path = `/tasksmanager/projects/${task.project}/tasks/${task.id}/dragdrop/`;
        let response = await client.post(path, { toOrder });
        return response.data;
    },
    async getLastHistory(task) {
        const path = `/tasksmanager/projects/${task.project}/taskhistories/last-of-task/${task.id}/`;
        let response = await client.get(path);
        return response.data;
    },
    async getAllTaskNum(projectId) {
        const path = `/tasksmanager/projects/${projectId}/tasks/get-all-task-num/`;
        let response = await client.get(path);
        return response.data;
    },
}
