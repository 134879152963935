import client from '../helpers/HttpClient'
import cache from "js-cache"

const endpoint = '/tasksmanager/projects/'
export default {
    async list(payload) {
        let { limit, offset, search, ordering, group } = payload;
        ordering = ordering.map(order => order.desc ? order.field : `-${order.field}`).join(",");
        const path = `${endpoint}?limit=${limit}&offset=${offset}&search=${search}&ordering=${ordering}&group=${group || ""}`;
        let response = await client.get(path)
        return response.data
    },
    async getProjectSetting({ projectId }) {
        const path = `${endpoint}${projectId}/settings/`;
        let data = cache.get(path)
        if (data) {
            return data
        }
        let response = await client.get(path)
        data = response.data
        cache.set(path, data)
        return data
    },
    async setProjectSetting({ projectId, settings }) {
        const path = `${endpoint}${projectId}/settings/`;
        cache.del(path);
        return await client.put(path, { settings });
    },
    async setProjectPinned({ projectId, isPinned }) {
        const path = `${endpoint}${projectId}/pinned/`;
        return await client.put(path, { isPinned });
    },
    async attachments(payload) {
        let { limit, offset, projectId } = payload;
        const path = `${endpoint}${projectId}/attachments/?limit=${limit}&offset=${offset}`;
        let response = await client.get(path)
        return response.data
    },
    async detail(projectId) {
        const path = `${endpoint}${projectId}/`;
        let data = cache.get(path)
        if (data) {
            return data
        }
        let response = await client.get(path)
        data = response.data
        cache.set(path, data)
        return data
    },
    async create(payload) {
        const path = endpoint;
        let response = await client.post(path, payload)
        return response.data;
    },
    async update({ id, data }) {
        const path = `${endpoint}${id}/`;
        let response = await client.patch(path, data)
        cache.del(path)
        return response.data;
    },
    async delete(projectId) {
        const path = `${endpoint}${projectId}/`;
        let response = await client.delete(path)
        return response.data;
    },
    async histories(projectId, filter) {
        let path = `${endpoint}${projectId}/taskhistories/`;
        let { limit, offset, search, ordering, desc, taskId } = filter;
        ordering = desc ? ordering : `-${ordering}`;
        path = `${path}?limit=${limit}&offset=${offset}&search=${search}&ordering=${ordering}&task=${taskId || ""}`;
        let response = await client.get(path)
        return response.data
    },
    async users(projectId) {
        let path = `${endpoint}${projectId}/users/`;
        let data = cache.get(path)
        if (data) {
            return data
        }
        let response = await client.get(path)
        data = response.data
        cache.set(path, data)
        return data
    },
    async addUsers(projectId, userIds) {
        let path = `${endpoint}${projectId}/users/`;
        const body = {
            listUser: userIds
        }
        let response = await client.post(path, body)
        cache.del(path)
        return response.data
    },
    async addUser(projectId, userId) {
        return await this.addUsers(projectId, [userId])
    },
    async deleteUser(projectId, userIds) {
        let path = `${endpoint}${projectId}/users/${userIds}/`;
        return await client.delete(path)
    },
    async changeRole(projectId, userIds, roleId) {
        let path = `${endpoint}${projectId}/users/${userIds}/changerole/`;
        return await client.patch(path, { role: roleId })
    },
    async inviteUser(projectId, listEmail) {
        let path = `${endpoint}${projectId}/invitations/`;
        cache.del(path)
        return await client.post(path, { emails: listEmail })
    },
    async getInvitations(projectId) {
        let path = `${endpoint}${projectId}/invitations/`;
        let data = cache.get(path)
        if (data) {
            return data
        }
        data = await client.get(path)
        cache.set(path, data)
        return data
    },
    async cancelInvitation(projectId, invitationId) {
        let path = `${endpoint}${projectId}/invitations/${invitationId}`;
        return await client.delete(path)
    },
    async getNotification(projectId) {
        let path = `/tasksmanager/notification/${projectId}/`;
        return await client.get(path);
    },
    async updateNotification(projectId, option) {
        let path = `/tasksmanager/notification/${projectId}/`;
        return await client.put(path, option);
    },
    async leave(projectId) {
        let path = `/tasksmanager/projects/${projectId}/leave/`;
        return await client.delete(path);
    },
    async getTaskReads(projectId) {
        let path = `/tasksmanager/projects/${projectId}/reads/?limit=1000`;
        return await client.get(path);
    },
    async setTaskRead(projectId, taskId, read = true) {
        let path = `/tasksmanager/projects/${projectId}/reads/${taskId}/`;
        return await client.put(path, { read });
    },
    async resendInvitation(projectId, invitationId) {
        let path = `${endpoint}${projectId}/invitations/${invitationId}/resend/`;
        return await client.post(path, { invitationId });
    },
    async translate(projectId, languageCode) {
        let path = `/tasksmanager/projects/${projectId}/translation/?language_code=${languageCode}`;
        return await client.get(path);
    },
    async updateAutoTranslation(updateData, projectId) {
        const path = `/tasksmanager/projects/${projectId}/set-auto-translates/`;
        let response = await client.patch(path, updateData);
        return response.data
    },
    async getLanguages(projectId, getResponseData) {
        let path = `/tasksmanager/projects/${projectId}/language-codes/`;
        const response = await client.get(path);
        return getResponseData ? response.data : response;
    },
}
