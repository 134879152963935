import {
    BILLING_ACTIONS
} from "../actions";

const initState = {
  invoiceData: [],
  loading: false,
};

export default function billingReducer(state = initState, action) {
    let { type, payload } = action;
    switch (type) {
        case BILLING_ACTIONS.GET_INVOICE:
            return {
                ...state,
                loading: true,
            }
        case BILLING_ACTIONS.GET_INVOICE_SUCCESS:
            return {
                ...state,
                invoiceData: payload,
                loading: false,
            }
        case BILLING_ACTIONS.GET_INVOICE_FAIL:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
}
