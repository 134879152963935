import { call, put, takeLeading } from "redux-saga/effects";
import { PROJECT_GROUP_ACTIONS } from "../actions";
import projectGroupApi from "../../api/projectGroupApi";

function* createProjectGroup({payload}) {
  try {
    yield call(requestCreateProjectGroup.bind(this, payload));
    yield put({type: PROJECT_GROUP_ACTIONS.PROJECT_GROUP_CREATE_SUCCESS});
  } catch(e) {
    console.log(e);
    yield put({type: PROJECT_GROUP_ACTIONS.PROJECT_GROUP_CREATE_FAIL});
  }
}

function requestCreateProjectGroup(payload) {
  return projectGroupApi.create(payload);
}

function* getProjectGroups() {
  try {
    const result = yield call(requestGetProjectGroupList.bind(this));
    yield put({type: PROJECT_GROUP_ACTIONS.PROJECT_GROUP_GET_LIST_SUCCESS, payload: result});
  } catch(e) {
    console.log(e);
    yield put({type: PROJECT_GROUP_ACTIONS.PROJECT_GROUP_GET_LIST_FAIL});
  }
}

function requestGetProjectGroupList() {
  return projectGroupApi.list();
}

function* getProjectGroup({payload}) {
  const {id} = payload;
  try {
    if (id === null) {
      yield put({type: PROJECT_GROUP_ACTIONS.PROJECT_GROUP_GET_SUCCESS, payload: {name: null}});
      return;
    }
    const result = yield call(requestGetProjectGroup.bind(this, {id}));
    yield put({type: PROJECT_GROUP_ACTIONS.PROJECT_GROUP_GET_SUCCESS, payload: result});
  } catch(e) {
    console.log(e);
    yield put({type: PROJECT_GROUP_ACTIONS.PROJECT_GROUP_GET_FAIL});
  }
}

function requestGetProjectGroup(payload) {
  return projectGroupApi.get(payload);
}

function* deleteProjectGroup({payload}) {
  const {id} = payload;
  try {
    yield call(requestDeleteProjectGroupList.bind(this, id));
    yield put({type: PROJECT_GROUP_ACTIONS.PROJECT_GROUP_DELETE_SUCCESS});
  } catch(e) {
    console.log(e);
    yield put({type: PROJECT_GROUP_ACTIONS.PROJECT_GROUP_DELETE_FAIL});
  }
}

function requestDeleteProjectGroupList(payload) {
  return projectGroupApi.delete(payload);
}

export function* watchProjectGroupAsync() {
    yield takeLeading(PROJECT_GROUP_ACTIONS.PROJECT_GROUP_CREATE, createProjectGroup);
    yield takeLeading(PROJECT_GROUP_ACTIONS.PROJECT_GROUP_GET_LIST, getProjectGroups);
    yield takeLeading(PROJECT_GROUP_ACTIONS.PROJECT_GROUP_GET, getProjectGroup);
    yield takeLeading(PROJECT_GROUP_ACTIONS.PROJECT_GROUP_DELETE, deleteProjectGroup);
}
