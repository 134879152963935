export default {
    "#": "#",
    home: "家",
    attachments: "附件",
    history: "历史沿革",
    setting: "设定值",
    user_setting: "用户设定",
    company_QA: "公司A的质量检查表",
    tag_not_available: "找不到参考标签",
    invite_user_success: "用户邀请已完成",
    invite_email_fail_message: "邀请失败",
    email_exist_error: "此电子邮件地址已被注册",
    change_password_success: "密码修改成功",
    change_password_fail: "密码更改失败",
    current_password_incorrect: "密码不匹配",
    update_profile_success: "个人资料更新成功",
    update_profile_error: "个人资料更新失败",
    current_password: "当前密码",
    new_issue: "新问题",
    update_issue: "更新问题",
    all_changes: "所有变化",
    notifications: "通知事项",
    inviter: "受邀者",
    confirm_invitation: "确认邀请",
    invitation_date: "邀请日期",
    reject: "拒绝",
    confirm_invite_success: "邀请成功",
    status: "现况",
    priority: "优先权",
    issue: "问题",
    issues: "问题",
    assignee: "委托人",
    answer: "回答",
    upload: "上载",
    comment: "评论",
    title: "标题",
    time: "时间",
    add_file: "新增档案",
    all_files: "所有档案",
    question: "问题",
    last_update: "最后更新",
    files: "档案",
    reply: "回应",
    search_bar: "搜索栏",
    undo: "撤消",
    save: "保存到",
    share: "分享到",
    language: "语言能力",
    add_new: "新创作",
    select_project: "选择项目",
    project_code: "项目代码",
    project_name: "项目名称",
    author: "创作者",
    created_date: "建立日期",
    updated_date: "更新日期",
    text_signup: "立即加入SmartQA，简化您的质量检查表联系人",
    text_signin: "进入登录页面！",
    join: "参与度",
    previous: "之前",
    next: "下一个",
    search: "搜寻",
    project_search_placeholder: "按项目名称或代码搜索",
    history_search_placeholder: "按历史记录内容搜索",
    size: "尺码",
    auth_invalid: "身份验证未正确运行。",
    project_selection: "项目选择",
    logout: "登出",
    login: "登入",
    ok: "是的",
    username: "用户名",
    password: "密码",
    confirm_password: "验证密码",
    required_login: "请输入您的用户名和密码。",
    wrong_login_info: "用户名或密码错误。",
    create: "建立",
    create_project: "建立专案",
    cancel: "取消",
    project_name_placeholder: "请输入项目名称",
    create_project_sucess: "我创建了一个项目",
    create_project_fail: "项目创建失败",
    empty_data: "无资料",
    signup: "报名",
    signin: "登入",
    register_now: "立即注册",
    delete: "删掉",
    update: "更新",
    upload_success: "文件上传成功！",
    upload_fail: "文件上传失败！",
    update_success: "更新成功！",
    update_fail: "更新失败！",
    learn_more: "了解更多",
    take_the_tour: "了解更多",
    delete_issue_success: "我删除了问题",
    search_qa: "搜索质量检查",
    hide_closed: "隐藏的",
    confirm_delete_text: "您确定要删除此项目吗？",
    confirm_resend_text: "您会再次发送邀请吗？",
    confirm_delete_folder: "您确定要删除此项目吗？",
    project_hercules: "大力神项目",
    navigation: "导览",
    account: "开户",
    reset: "重设",
    share_project_success: "共享项目",
    share_project: "分享一个项目",
    copy_project: "复制",
    date_time: "日期和时间",
    task: "任务",
    action: "动作片",
    create_task: "创建任务",
    update_task: "更新任务",
    delete_task: "删除任务",
    create_sub_task: "创建子任务",
    update_sub_task: "更新子任务",
    delete_sub_task: "删除子任务",
    create_attachment: "将附件添加到任务",
    update_attachment: "更新任务中的附件[_file_name_]",
    delete_attachment: "从任务中删除附件",
    delete_file_success: "文件已成功删除",
    delete_file_fail: "文件删除失败",
    project_history: "项目历史",
    confirmation: "确认书",
    thumbnail: "缩图",
    file_name: "档案名称",
    date: "日期",
    user: "用户名",
    users: "用户名",
    download: "资料下载",
    remove: "删掉",
    load_more: "已读",
    and: "和",
    order: "索取",
    after: "之后",
    before: "之前",
    add_user: "新增使用者",
    level: "等级",
    options: "选件",
    manager: "管理员",
    email: "电邮地址",
    can_not_add_user: "无法添加用户",
    high: "高",
    normal: "中号",
    low: "低位",
    open: "开门",
    in_progress: "进行中",
    close: "关门",
    invite: "邀请",
    invite_user: "邀请用户",
    list_invalid: "无效的电子邮件地址",
    confirm_invite_email: "您想向这些电子邮件地址发送邀请吗？",
    send: "发送",
    list_email: "邮件清单",
    back: "回去",
    confirm: "确认书",
    confirm_upgrade: "确认升级",
    input_list_email: "请输入您的电子邮件地址",
    register: "报名",
    first_name: "名",
    romanized_first_name: "罗马名",
    last_name: "姓氏",
    romanized_last_name: "罗马姓",
    field_required: "这是必填项",
    password_min_length: "密码必须是6个字符或更多",
    password_dont_match: "密码不匹配",
    register_success_title: "您已成功注册！",
    register_success_content: "您可以登录并加入smartQA！",
    request_register_success_title: "您的请求成功！",
    request_register_success_content: "注册链接已发送。请检查您的电子邮件以完成注册！",
    register_link_expired: "注册链接已过期",
    company: "公司介绍",
    profile: "个人资料",
    show_hide: "显示/隐藏",
    change_password: "修改密码",
    updated_by: "更新器",
    yes: "是的",
    no: "没有啦",
    override: "覆写",
    warning: "警告",
    conflict_warning_message: "该单元格的内容已更改",
    select: "选择",
    reference_tag: "参考标签",
    sub_title_download: "资料下载",
    sub_title_delete: "删掉",
    error: "失误",
    disconnect_content: "检查连接！",
    pending_invitations: "待处理的邀请",
    closed: "关门",
    complete: "已完成",
    delete_project_warning: "删除该项目将从数据库中删除所有信息。无法撤消",
    delete_project_guild: "键入“ DELETE”执行删除",
    close_project_warning: "已关闭的项目设置为只读模式。用户将无法在项目中添加或编辑任何内容。",
    close_project_guild: "如果要关闭它，请键入“ CLOSE”",
    leave_project: "退出此项目",
    close_project: "完成这个项目",
    open_project: "打开这个项目",
    delete_project: "删除这个专案",
    delete_project_title: "删除专案",
    close_project_title: "完成项目",
    open_project_title: "公开项目",
    leave: "离开",
    view: "见",
    export: "写出来",
    leave_project_title: "离开专案",
    leave_project_warning_user: "你想完成这个项目吗？",
    leave_project_warning_admin: "您是该项目的维护者，您真的要离开吗？",
    leave_project_warning_admin_assign: "如果您离开，请为该项目指派一名经理！",
    delete_project_error_message: "该项目无法删除",
    close_project_success_message: "完成项目",
    close_project_error_message: "关闭专案时发生错误",
    close_project_confirm_error_message: "键入“ CLOSE”关闭项目",
    open_project_warning: "您想重新打开这个项目吗？",
    open_project_guild: "要打开它，请输入“ OPEN”",
    open_project_success_message: "成功打开项目",
    open_project_error_message: "发生错误",
    open_project_confirm_error_message: "要打开它，请输入“ OPEN”",
    general_files: "一般档案",
    description: "内容描述",
    add_folder: "新增资料夹",
    add_folder_success: "新增资料夹",
    delete_success: "已删除",
    delete_fail: "删除失败",
    new_folder: "新资料夹",
    forgot_password: "如果您忘记密码，请点击这里",
    invalid_email: "无效的电子邮件地址",
    request_reset_password_successful: "请检查您的电子邮件以重置密码",
    enter_email: "请输入您的电子邮件地址",
    project_settings: "项目设定",
    customize_columns: "自定义列",
    reset_password: "重设密码",
    reset_password_success: "重设密码",
    reset_password_fail: "密码重置失败",
    reset_password_success_notice: "您的新密码已成功更改！",
    reset_password_expired: "密码重置链接已过期",
    billing: "付款信息",
    invoices: "月结单",
    current_plan: "最新计划",
    basic_plan: "基本计划",
    change_plan: "变更计划",
    next_billing_date: "下一个帐单日期",
    next_plan: "下一个计划",
    billing_information: "帐单信息",
    name: "名称",
    company_name: "公司名称",
    company_address: "公司地址",
    city_country: "城市，国家",
    zip: "邮递区号",
    personal_address: "住址",
    payment_methods: "付款方式",
    credit_card_or_bank_tranfer: "信用卡或银行转帐",
    change_my_plan: "变更计划",
    upgrade: "升级版",
    account_deletion: "删除帐号",
    account_deletion_warning: "这将删除您的帐户和相关数据。无法撤消。",
    delete_my_account: "删除帐号",
    confirm_delete_account: "您确定要删除您的帐户吗？",
    create_success: "创建成功",
    create_fail: "创建失败",
    add_card: "新增卡",
    credit_card: "信用卡卡",
    bank_transfer: "银行转帐",
    select_payment_method: "选择付款方式",
    list_credit_card: "信用卡清单",
    upgrade_account_message: "您必须升级您的帐户才能使用此功能！",
    confirm_payment: "付款确认",
    select_method_upgrade: "您需要先选择一种付款方式，然后才能升级帐户！",
    pay_with_card: "使用以下信用卡进行交易：",
    update_payment_method: "更新付款方式",
    pay: "付钱",
    add_new_credit_card: "新增信用卡",
    free: "免费的",
    basic: "基本的",
    pro: "专业的",
    pro_translation: "专业版+翻译",
    limit_invite_email_error: "电子邮件数量超出限制。您需要升级帐户才能邀请更多人。",
    select_type_payment: "选择付款方式",
    from: "变更前",
    plan: "计划",
    monthly: "月亮",
    yearly: "年",
    per_month: "每月一次",
    per_year: "年度金额",
    selected: "已选",
    jpy: "¥",
    usd: "$",
    you_save: "您保存",
    downgrade: "降级",
    number_of_object: "物件数量",
    price: "价钱",
    max_attachment_storage: "附件文件大小限制",
    customizations_themes: "客制化设计",
    max_collaborators: "可以注册的人数",
    download_only: "仅下载",
    unlimited: "无限的",
    '0MB': "0MB",
    '250MB': "250MB",
    '5GB': "5 GB",
    '380yen': "380日圆",
    '780yen': "780日圆",
    change: "改变",
    'free_des': "与任何人自由协作",
    '3_team_members': "总共3个人",
    '3_projects': "共3个项目",
    'file_download_only': "仅文件下载",
    'basic_des': "让我们开始吧",
    '5_team_members': "每个项目5名成员",
    '30_team_members': "每个项目30名成员",
    '10_projects': "总共10个项目",
    '250_mb_store': "250 MB的文件上传空间",
    'buy_basic': "购买基础",
    'pro_des': "专业用户的专业功能",
    'unlimited_team_members': "每个项目无限的成员",
    'unlimited_projects': "每个项目无限的团队成员",
    '10_gb_storage': "10GB的文件上传空间",
    'export_qa': "将质量检查项目导出到CSV",
    'buy_pro': "专业采购",
    'buy_pro_plus': "购买专业版+翻译",
    'back_to_free': "返回免费",
    'back_to_basic': "回到基础",
    'get_started': "让我们开始吧",
    'pro_tran_des': "将自动翻译添加到您的项目中",
    'all_pro_features': "所有Pro功能",
    'auto_translation_list_language': "自动翻译（英语，日语，中文，韩语）",
    'add_member_for_your_auto_translation_project': "自动翻译项目的所有成员都可以使用翻译功能，即使他们不是Pro + Translate层",
    replies: "回覆",
    view_history: "查看历史",
    actions: "动作片",
    type_here_to_add_reply: "在此处输入以回复",
    type_here_to_add_issue: "在此处输入以添加问题",
    all_status: "所有状态",
    display_issue: "显示的问题",
    current_tier_membership_time: "当前会员资格时间",
    total_projects: "所有专案",
    signup_date: "报名日期",
    of_project: "项目数量",
    previous_plan: "以前的计划",
    previous_plan_start_date: "上一个计划的开始日期",
    previous_plan_total_months: "上一个计划的总月份",
    current_plan_start_date: "最新计划开始日期",
    current_plan_total_months: "目前计划的总月数",
    billing_type: "开票方式",
    activity_level: "活动水平",
    min_months: "最低月份",
    max_months: "最长月份",
    min_project: "最小的项目。 。 。",
    max_project: "最大的项目。 。 。",
    start_date: " -开始日期-",
    end_date: " -结束日期-",
    type: "类型。 。 。",
    year: "年",
    month: "月亮",
    change_current_plan_text_modal: "此帐户的更改计划",
    showing: "显示中",
    to: "〜",
    of: "/",
    entities: "项",
    select_file_upload: "选择要上传的文件",
    drag_and_drop: "将档案放在这里",
    add_a_title: "添加标题",
    add_a_comment: "添加评论",
    projects: "该项目",
    project: "该项目",
    add_project_placeholder: "在此处输入以添加项目",
    search_project: "搜索项目",
    super_admin: "特别经理",
    login_smartQA: "登录到smartQA。",
    sign_up_smartQA: "注册smartQA。",
    sign_up_des_1: "立即加入SmartQA，简化您的质量检查表联系人",
    sign_up_des_2: "点击这里了解详情",
    sign_up_des_3: "游览",
    export_this_project: "汇出这个专案",
    browse_files: "浏览文件",
    status_filter: "状态过滤器",
    translation: "笔译",
    address: "住址",
    card_information: "卡信息",
    country_or_region: "国家或地区",
    tags: "标签",
    custom1: "自订1",
    custom2: "自订2",
    custom3: "自订3",
    copy: "文字已复制到剪贴板",
    resend_success: "邀请再次发送",
    resend_fail: "没有发送邀请",
    date_joined: "报名日期",
    pin: "固定这个专案",
    unpin: "解锁该项目",
    department: "部门",
    overview: "总览",
    plans: "计划",
    add_payment_method: "添加付款方式",
    card_number: "卡号",
    expiry: "到期日",
    name_on_card: "持卡人",
    contact_us: "联络我们",
    bank_transfer_placeholder: "有关银行转帐的查询，请直接通过电子邮件与我们联系",
    current_plan_detail: "目前的计划是",
    downgrade_plan_detail_1: "降级给你",
    downgrade_plan_detail_2: "规划开始",
    select_card: "选择卡",
    department_division: "部门",
    collaborators: "合作者",
    time_format: "时间格式",
    update_settings: "更新设定",
    save_settings_success: "设置更新成功",
    save_settings_error: "更新设置失败",
    project_limit_message: "您已达到计划中的最大项目数。请升级您的帐户或结束您的参与。",
    project_limit_reached: "您已达到计划中的最大项目数。请升级您的帐户或结束您的参与。",
    confirm_payment_description: "您可以在此处添加信息，例如何时付款。",
    paid: "付钱",
    pages: "页面",
    invitation_reject: "邀请被拒绝",
    change_language: "变更语言",
    auto_translate: "自動翻訳",
    enable_translations: "翻訳を有効にする",
    english: "英語",
    japanese: "日本語",
    korean: "韓国語",
    chinese: "中国人",
    you_have: "あなたが持っている",
    auto_translation_setting_title: "自動翻訳プロジェクトのクレジット。",
    enable_auto_translation: "自動翻訳を有効にする?",
    auto_translation_setting_subject: "これを元に戻すことはできません。翻訳クレジットを取り戻すには、プロジェクトを閉じるか削除する必要があります。",
    auto_translation_setting_subject_warning: "翻訳クレジットを取り戻すには、プロジェクトを閉じるか削除する必要があります。",
    import: "インポート",
    export_csv: "CSVをエクスポート",
    export_csv_file: "CSV +ファイルをエクスポート",
    original: "原版的",
    auto_translation: "自动翻译",
    enable: "启用",
    on: "上",
    off: "关",
    import_data_into_this_project: "将数据导入该项目",
    owner: "所有者",
    choose_file_to_upload: "选择要上传的文件",
    choose_another_file: "选择另一个文件",
    issue_column: "发行专栏",
    answer_column: "答案栏",
    import_file_success: "导入文件成功",
    import_file_failure: "导入文件失败",
    averageIssue: "每月平均发行",
    averageIssueTrans: "每月平均A-T",
    averageCharacters: "每月平均字符",
    averageCharactersTrans: "每月平均字符A-T",
    group: "组",
    groups: "组",
    project_group: "项目组",
    new_project_group: "新项目组",
    project_group_name: "组名称",
    no_group: "没有组",
    change_group: "变更组",
    unsorted: "（未排序）",
    delete_project_group: "删除项目组",
    delete_project_group_confirm: "删除该组会将其所有项目移至“未排序”组。 您要继续吗？",
    group_404: "该组不存在",
    limited_space_message: "您已达到该层的文件存储限制，请删除文件或升级您的帐户。",
}
