import {
    HISTORY_ACTIONS
} from "../actions";

const initState = {
    loading: false,
    data: {},
    user: [],
};

export default function historyReducer(state = initState, action) {
    let { type, payload } = action;
    switch (type) {
        case HISTORY_ACTIONS.HISTORY_GET_LIST:
            return {
                ...state,
                loading: true,
            }
        case HISTORY_ACTIONS.HISTORY_GET_LIST_SUCCESS:
            let newData = { ...payload.data }
            return {
                ...state,
                data: newData,
                loading: false,
                users: payload.users
            }
        case HISTORY_ACTIONS.HISTORY_GET_LIST_FAIL:
            return {
                ...state,
                loading: false,
            }
        case HISTORY_ACTIONS.HISTORY_CLEAN_DATA:
            return {
                ...state,
                data: {},
            }
        default:
            return state;
    }
}
