export default {
    PROJECT_USER_GET_LIST: 'PROJECT_USER_GET_LIST',
    PROJECT_USER_GET_LIST_SUCCESS: 'PROJECT_USER_GET_LIST_SUCCESS',
    PROJECT_USER_GET_LIST_FAIL: 'PROJECT_USER_GET_LIST_FAIL',

    PROJECT_USER_ADD_USER: 'PROJECT_USER_ADD_USER',
    PROJECT_USER_ADD_USER_SUCCESS: 'PROJECT_USER_ADD_USER_SUCCESS',
    PROJECT_USER_ADD_USER_FAIL: 'PROJECT_USER_ADD_USER_FAIL',

    PROJECT_USER_DELETE_USER_MODAL_OPEN: 'PROJECT_USER_DELETE_USER_MODAL_OPEN',
    PROJECT_USER_DELETE_USER_MODAL_CLOSE: 'PROJECT_USER_DELETE_USER_MODAL_CLOSE',

    PROJECT_USER_DELETE_USER: 'PROJECT_USER_DELETE_USER',
    PROJECT_USER_DELETE_USER_SUCCESS: 'PROJECT_USER_DELETE_USER_SUCCESS',
    PROJECT_USER_DELETE_USER_FAIL: 'PROJECT_USER_DELETE_USER_FAIL',

    PROJECT_USER_ROLE_MODAL_OPEN: 'PROJECT_USER_ROLE_MODAL_OPEN',
    PROJECT_USER_ROLE_MODAL_CLOSE: 'PROJECT_USER_ROLE_MODAL_CLOSE',

    PROJECT_USER_CHANGE_ROLE: 'PROJECT_USER_CHANGE_ROLE',
    PROJECT_USER_CHANGE_ROLE_SUCCESS: 'PROJECT_USER_CHANGE_ROLE_SUCCESS',
    PROJECT_USER_CHANGE_ROLE_FAIL: 'PROJECT_USER_CHANGE_ROLE_FAIL',

    PROJECT_USER_INVITE_USER_MODAL_TOGGLE: 'PROJECT_USER_INVITE_USER_MODAL_TOGGLE',

    PROJECT_USER_GET_AUTO_TRANSLATION: 'PROJECT_USER_GET_AUTO_TRANSLATION',
    PROJECT_USER_GET_AUTO_TRANSLATION_SUCCESS: 'PROJECT_USER_GET_AUTO_TRANSLATION_SUCCESS',
    PROJECT_USER_GET_AUTO_TRANSLATION_FAIL: 'PROJECT_USER_GET_AUTO_TRANSLATION_FAIL',

    PROJECT_USER_UPDATE_AUTO_TRANSLATION: 'PROJECT_USER_UPDATE_AUTO_TRANSLATION',
    PROJECT_USER_UPDATE_AUTO_TRANSLATION_SUCCESS: 'PROJECT_USER_UPDATE_AUTO_TRANSLATION_SUCCESS',
    PROJECT_USER_UPDATE_AUTO_TRANSLATION_FAIL: 'PROJECT_USER_UPDATE_AUTO_TRANSLATION_FAIL',

    PROJECT_USER_UPDATE_AUTO_TRANSLATION_LANGUAGE: 'PROJECT_USER_UPDATE_AUTO_TRANSLATION_LANGUAGE',
    PROJECT_USER_UPDATE_AUTO_TRANSLATION_LANGUAGE_SUCCESS: 'PROJECT_USER_UPDATE_AUTO_TRANSLATION_LANGUAGE_SUCCESS',
    PROJECT_USER_UPDATE_AUTO_TRANSLATION_LANGUAGE_FAIL: 'PROJECT_USER_UPDATE_AUTO_TRANSLATION_LANGUAGE_FAIL',

    PROJECT_USER_REFRESH_REDUCER: 'PROJECT_USER_REFRESH_REDUCER',
}
