const GET_SETTING = 'GET_SETTING';
const GET_SETTING_SUCCESS = 'GET_SETTING_SUCCESS';
const GET_SETTING_FAIL = 'GET_SETTING_FAIL';

const SET_SETTING = 'SET_SETTING';
const SET_SETTING_SUCCESS = 'SET_SETTING_SUCCESS';
const SET_SETTING_FAIL = 'SET_SETTING_FAIL';

const SET_SETTING_KEY = 'SET_SETTING_KEY';
const SET_SETTING_KEY_SUCCESS = 'SET_SETTING_KEY_SUCCESS';
const SET_SETTING_KEY_FAIL = 'SET_SETTING_KEY_FAIL';

const SETTING_DISMISS = 'SETTING_DISMISS';

export default {
  GET_SETTING,
  GET_SETTING_SUCCESS,
  GET_SETTING_FAIL,

  SET_SETTING,
  SET_SETTING_SUCCESS,
  SET_SETTING_FAIL,

  SET_SETTING_KEY,
  SET_SETTING_KEY_SUCCESS,
  SET_SETTING_KEY_FAIL,

  SETTING_DISMISS
}

export const getSetting = () => ({type: GET_SETTING});
export const setSetting = (key, value) => ({type: SET_SETTING, dataSetting: value, setting: key});
export const setSettingKey = (key, value) => ({type: SET_SETTING_KEY, key, value});
export const settingDismiss = () => ({type: SETTING_DISMISS})
