import client from '../helpers/HttpClient'

const endpoint = '/tasksmanager/project-groups/'
export default {
    async list(payload) {
        let response = await client.get(endpoint)
        return response.data
    },
    async create(payload) {
        const path = endpoint;
        let response = await client.post(path, payload)
        return response.data;
    },
    async update({ id, data }) {
        const path = `${endpoint}${id}/`;
        let response = await client.patch(path, data)
        return response.data;
    },
    async delete(id) {
        const path = `${endpoint}${id}/`;
        let response = await client.delete(path)
        return response.data;
    },
    async get({ id }) {
      const path = `${endpoint}${id}/`;
      let response = await client.get(path)
      return response.data
  },
}
