import update from 'immutability-helper';
import {
    PROJECT_ACTIONS
} from "../actions";

const initState = {
    userCount: 0,
    userList: [],
    data: {},
    selectedProjectId: "",
    isShowInviteModal: false,
    loading: false,
    setting: null,
    isClosed: false,
};

export default function projectReducer(state = initState, action) {
    let { type, payload } = action;
    switch (type) {
        case PROJECT_ACTIONS.GET_PROJECT_SETTING:
            return {
                ...state,
                loading: true,
            }
        case PROJECT_ACTIONS.GET_PROJECT_SETTING_SUCCESS:
            return {
                ...state,
                setting: payload,
                loading: false,
            }
        case PROJECT_ACTIONS.GET_PROJECT_SETTING_FAIL:
            return {
                ...state,
                loading: false,
            }
        case PROJECT_ACTIONS.SET_PROJECT_SETTING:
            return {
                ...state,
                loading: true,
            }
        case PROJECT_ACTIONS.SET_PROJECT_SETTING_SUCCESS:
            return {
                ...state,
                setting: payload,
                loading: false,
            }
        case PROJECT_ACTIONS.SET_PROJECT_SETTING_FAIL:
            return {
                ...state,
                loading: false,
            }
        case PROJECT_ACTIONS.PROJECT_GET_LIST:
            return {
                ...state,
                loading: true,
            }
        case PROJECT_ACTIONS.PROJECT_GET_LIST_SUCCESS:
            let newData = { ...payload }
            return {
                ...state,
                data: newData,
                loading: false,
            }
        case PROJECT_ACTIONS.PROJECT_GET_LIST_FAIL:
            return {
                ...state,
                loading: false,
            }
        case PROJECT_ACTIONS.PROJECT_UPDATE_ROW:
            let tempArr = state.data.results.map(item => {
                if (item.id === payload.id) {
                    return payload;
                }
                return item;
            })
            return {
                ...state,
                data: { ...state.data, results: tempArr }
            }
        case PROJECT_ACTIONS.PROJECT_OPEN_INVITE_MODAL: {
            const { projectId, userCount } = payload;
            return {
                ...state,
                userCount,
                selectedProjectId: projectId,
                isShowInviteModal: true
            }
        }
        case PROJECT_ACTIONS.PROJECT_UPDATE_INFO: {
            const { projectId, userCount, userList, isClosed } = payload;
            return {
                ...state,
                userCount,
                userList,
                selectedProjectId: projectId,
                isClosed
            }
        }
        case PROJECT_ACTIONS.PROJECT_CLOSE_INVITE_MODAL:
            return {
                ...state,
                selectedProjectId: "",
                isShowInviteModal: false
            }
        case PROJECT_ACTIONS.PROJECT_DELETE_SUCCESS: {
            const newData = state.data.results.filter(p => p.id !== payload.projectId)
            const { count } = state.data;
            const newState = update(state, {
                data: {
                    results: { $set: newData },
                    count: { $set: count - 1 }
                }
            })
            return newState
        }
        default:
            return state;
    }
}
