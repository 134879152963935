import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode';
import cons from '../constants'
import config from '../config'

var _token = undefined;

const getToken = () => {
    if (_token)
        return _token;
    let storedToken = Cookies.get(cons.CK_TOKEN);
    _token = storedToken;
    return storedToken;
};

const getTokenParse = () => getToken() && jwtDecode(getToken());

const saveToken = token => {
    _token = token;
    var expireTime = new Date(new Date().getTime() + config.TOKEN_LIFE_TIME);
    Cookies.set(cons.CK_TOKEN, token, { expires: expireTime });
    return token;
};

const destroyToken = () => {
    _token = undefined;
    Cookies.remove(cons.CK_TOKEN);
};

const getRole = () => {
  const token = getToken()
  if (token) {
    const userInfo = jwtDecode(token)
    if(userInfo.isSuperuser) {
      return 'admin'
    }
    return 'user';
  }
  return 'guest';
}

const getUserInfo = () => {
  if(!_token){
    return;
  }

  const user = jwtDecode(_token)
  return user;
}

const getUserId = () => {
  const userInfo = getUserInfo()
  if( userInfo) {
    return userInfo.user_id
  }
}

export default { getToken, saveToken, destroyToken, getRole, getTokenParse, getUserInfo, getUserId };
