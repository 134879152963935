import { LANG_ACTIONS } from '../actions';
import constants from '../../constants';
const initState = {
    lang: {
        key: "Language",
        value: constants.LANGUAGE_CODE.ENGLISH,
        id: 0
    },
    loading: false,
    temp_lang: null
}

export default function langReducer(state = initState, action) {
    const { type, payload } = action
    switch (type) {
        case LANG_ACTIONS.GET_LANG:
            return Object.assign({}, state, {
                loading: true,
            });
        case LANG_ACTIONS.GET_LANG_SUCCESS:
            // strings.setLanguage(payload.value)
            return Object.assign({}, state, {
                lang: payload,
                loading: false
            });
        case LANG_ACTIONS.GET_LANG_ERROR:
            return Object.assign({}, state, {
                loading: false,
            });
        case LANG_ACTIONS.SET_LANG:
            return Object.assign({}, state, {
                loading: true,
            });
        case LANG_ACTIONS.SET_LANG_WHITH_OUT_SAVE_SERVER:
            let lang = payload
            return Object.assign({}, state, {
                lang
            });
        case LANG_ACTIONS.SET_LANG_SUCCESS:
            return Object.assign({}, state, {
                lang: payload,
                loading: false,
            });
        case LANG_ACTIONS.SET_LANG_ERROR:
            return Object.assign({}, state, {
                loading: false,
            });
        case LANG_ACTIONS.SET_LANG_TEMP:
            return {
                ...state,
                temp_lang: payload,
            }
        case LANG_ACTIONS.INITIALIZE_LANG_SUCCESS:
          return {
            ...state,
            temp_lang: payload,
            lang: payload
          }
        default:
            return state;
    }
}
