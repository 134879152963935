import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import strings from "../../localization";

const deleteCss = {
  color: '#8495a6',
  fontFamily: "GT Walsheim Pro - Medium",
  fontSize: '12px',
  fontWeight: '500',
  letterSpacing: '0.3px',
  textTransform: 'uppercase',
  cursor: 'pointer',
  textDecoration: 'underline',
  textDecorationColor: 'rgba(132, 149, 166, 0.5)'
}

export class ButtonIconDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false
    };
  }

  toggle = () => {
    let { cell } = this.props;
    const data = cell.getRow().getData();
    if (data.id) {
      this.setState({
        popoverOpen: !this.state.popoverOpen
      });
    }
  }


  render() {
    let { cell } = this.props;
    const data = cell.getRow().getData();
    return (
      <React.Fragment>
        <Modal size="md" centered={true} isOpen={this.state.popoverOpen} toggle={this.toggle} className="modal-danger">
          <ModalHeader toggle={this.toggle}>{strings.confirmation}</ModalHeader>
          <ModalBody>
            {strings.confirm_delete_text}
          </ModalBody>
          <ModalFooter>
            <Button className="mr-2" onClick={() => { this.toggle(); this.props.handleClick(cell) }} color="danger">{strings.delete}</Button>
            <Button color="secondary" onClick={this.toggle}>{strings.cancel}</Button>
          </ModalFooter>
        </Modal>
        {data && data.id &&
          <button className="btn btn-cell" onClick={this.toggle}>
            <label style={deleteCss}>{strings.delete}</label>
          </button>
        }
      </React.Fragment>
    );
  }
}
