import React, { useEffect, useRef } from 'react';
import { render } from 'react-dom';

import './TagsInput.scss'


const TagsFormat = props => {
    const wrapper = useRef()
    const { tags = [], cell } = props;

    const normalizeHeight = () => {
        const row = cell.getRow();
        row.normalizeHeight();
    }

    useEffect(() => {
        normalizeHeight()
    }, [])

    return (
        <div ref={wrapper} className="tags-input">
            <ul id="tags">
                {tags.map((tag, index) => (
                    <li key={Math.random()} className="tag">
                        <span className='tag-title'>{tag}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default function (cell, formatterParams, onRendered) {
    const container = document.createElement('div');
    container.style.height = '100%';
    const data = cell.getData();
    render(
        <TagsFormat
            cell={cell}
            onRendered={onRendered}
            formatterParams={formatterParams}
            tags={data.tagList}
        />,
        container
    );
    return container;
}
