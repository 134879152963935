import React from "react";
import strings from "../../localization";
import classNames from 'classnames';
import { store } from "../../redux";
import { reactEditor, reactFormatter } from "../../components/Tabulator/ultils";
import DraftEditor from "../../components/Tabulator/DraftEditor";
import DraftFormater from "../../components/Tabulator/DraftFormater";
import TabelHelper from '../../helpers/TabelHelper';
import AuthHelper from "../../helpers/AuthHelper";
import { ButtonIconFile } from "../../components/Tabulator/ButtonIconFile";
import { ButtonIconReply } from "../../components/Tabulator/ButtonIconReply";
import { ButtonIconDelete } from "../../components/Tabulator/ButtonIconDelete";
import { LastUpdateFormatter } from "../../components/Tabulator/LastUpdateFormatter";
import SelectEditor from "../../components/Tabulator/SelectEditor";
import SelectFormater from "../../components/Tabulator/SelectFormater";
import TagsInput from "../../components/Tabulator/TagsInput";
import TagsFormatter from "../../components/Tabulator/TagsFormatter";
import constant from "../../constants"
import _ from 'lodash'
import constants from "../../constants";
import qaActions from "../../redux/actions/qa.actions"
import {highlightRowClass, highlightRow} from './ultils'

export const priorityOptions = () => [
    {
        label: strings.normal,
        value: null
    },
    {
        label: strings.high,
        value: constant.TASK_PRIORITY.HIGH
    },
    {
        label: strings.normal,
        value: constant.TASK_PRIORITY.NORMAL
    },
    {
        label: strings.low,
        value: constant.TASK_PRIORITY.LOW
    }
];

export const statusOptions = () => [
    {
        label: strings.open,
        value: null
    },
    {
        label: strings.open,
        value: constant.TASK_STATUS.OPEN
    },
    {
        label: strings.in_progress,
        value: constant.TASK_STATUS.IN_PROGRESS
    },
    {
        label: strings.complete,
        value: constant.TASK_STATUS.COMPLETE
    },
    {
        label: strings.closed,
        value: constant.TASK_STATUS.CLOSE
    },
]

export const tableOptions = {
    // movableRows: true,
    invalidOptionWarnings: false,
    dataTree: true,
    dataTreeBranchElement: false,
    dataTreeStartExpanded: false,
    dataTreeElementColumn: "subtasks",
    dataTreeChildField: "subtasks",
    resizableRows: true,
    addRowPos: "bottom",
    virtualDomBuffer: 8000,
    placeholder: "No Data Set",
    height: "calc(100vh - 200px)",
    autoResize: false,
    keybindings: {
        "navUp": false,
        "navDown": false,
    },
    rowClick: handleRowClick,
    rowFormatter: rowFormatter,
    dataEdited: function(data){
        updateResultCounts(data);
    },
    dataFiltered: function(filters, rows){
        updateResultCounts(rows);
    },
    timeFormat: "12h"
}

function updateResultCounts(data) {
    if(data[0].getTreeParent && data[0].getTreeParent()) {
        return;
    }

    const count = data.length - 1
    store.dispatch({ type: qaActions.QA_UPDATE_TASK_COUNT, payload: {count} })
}

function rowFormatter(row) {
    formatRowColor(row);
    checkAndMarkUnReadTask(row)
    setTimeout(() => {
        row.normalizeHeight()
    }, 0);
}


function checkAndMarkUnReadTask(row) {
    if (!isReadRow(row) && row.getData().id) {
        highlightRow(row)
    }
}

function isReadRow(row) {
    const rowData = row.getData();
    if (_.isNil(rowData.id)) {
        return true;
    }

    const reads = store.getState().qa.taskReads
    let isRead = 0 <= reads.findIndex(item => item.task === rowData.id && item.read)
    const currentUserId = AuthHelper.getUserInfo().user_id;
    const isLastEffectUser = currentUserId === _.get(rowData, "updatedBy.id", null)
        || (currentUserId === _.get(rowData, "createdBy.id", null) && rowData.updatedBy === null)
    isRead = isRead || isLastEffectUser
    return isRead
}

function formatRowColor(row) {
    const data = row.getData();
    const element = row.getElement();
    const prioClassName = getPriorityClassName(data.priority);
    if (!_.isNil(prioClassName) && element && element.classList) {
        element.classList.remove("prio-high", "prio-normal", "prio-low");
        if (data.taskStatus !== constant.TASK_STATUS.CLOSE) {
            element.classList.add(prioClassName);
        }
    }
}

function getPriorityClassName(priority) {
    switch (priority) {
        case constant.TASK_PRIORITY.HIGH:
            return "prio-high";
        case constant.TASK_PRIORITY.NORMAL:
            return "prio-normal";
        case constant.TASK_PRIORITY.LOW:
            return "prio-low";
        default:
            return;
    }
}

function getAssigneeData(userList) {
    return userList.map(item => {
        const label = `${item.firstName ? item.firstName : ''}
    ${item.lastName ? item.lastName : ''}`
        return {
            label,
            value: item.id,
            company: item.company
        }
    });
}

function handleRowClick(e, row) {
    selectRow(row)
    removeHighlight(row)
}

function removeHighlight(row) {
    if (row && !isReadRow(row)) {
        const rowData = row.getData();
        row.getElement().classList.remove(highlightRowClass)
        store.dispatch({ type: qaActions.QA_SET_TASK_READ, payload: { projectId: rowData.project, taskId: rowData.id } })
    }
}

function selectRow(row) {
    try {
        const table = row.getTable()
        table.deselectRow();
        row.select();
    } catch (error) { }
}

export function editableColumns({ onClickFile, deleteRow, searchByTag, tableSetting, userList, getTableData, projectId, isClosed, addNewRow }) {
    tableSetting = _.isArray(tableSetting) ? constants.DEFAULT_TABLE_SETTING : JSON.parse(tableSetting);
    const issue = 'issue';
    const answer = 'answer';
    const lastupdate = 'lastupdate';
    const assignee = 'assignee';
    const cursor_default = 'cursor-default';
    const tags = 'tags';
    const custom1 = 'custom1';
    const custom2 = 'custom2';
    const custom3 = 'custom3';
    const getWidth = (field, defaultValue) => {
      const item = tableSetting.find(item => item.key === field);
      if (item) {
          return item.size;
      }
      return defaultValue;
    };

    const columnObject = {
      '#': {
              title: "#",
              formatter: function (cell) {
                  let cellData = cell.getData();
                  const isParenRow = TabelHelper.isParentRow(cell);
                  if (cellData.id && cellData.id !== -1) {
                      if (isParenRow) {
                          return `<div class="hcl-index-number">${cellData.taskNum}</div>`;
                      }
                      else {
                          return `<div class="hcl-index-number"> ${cellData.parentTaskNum}.${cellData.taskNum} </div>`
                      }
                  }
                  return isParenRow ? `<div class="hcl-plus-icon">&#x2b;</div>` : null;
              },
              cellClick: function (e, cell) {
                  let cellData = cell.getData();
                  if (!cellData.id) {
                    addNewRow();
                  }
              },
              field: "taskNum",
              align: "center",
              cssClass: `${cursor_default} text-center issue-index`,
              width: 44,
              resizable: false,
              headerSort: true,
              sorterParams: {
                  alignEmptyValues: "bottom"
              }
            },
       'status': {
           title: strings.status,
           field: "taskStatus",
           editor: reactEditor(<SelectEditor isClosed={isClosed} />),
           formatter: reactFormatter(<SelectFormater statusOptions={statusOptions()} />),
           resizable: false,
           width: 120,
           validator: ["required"],
           align: "center",
           cssClass: `${cursor_default} cell-gray text-center`,
           editorParams: {
               allowEmpty: true,
               showListOnEmpty: true,
               values: statusOptions()
           },
           editable: function (cell) {
               return TabelHelper.isParentRow(cell);
           },
           sorterParams: {
               alignEmptyValues: "bottom"
           }
       },
       'priority': {
           title: strings.priority,
           field: "priority",
           editor: reactEditor(<SelectEditor isClosed={isClosed} />),
           width: 120,
           validator: ["required"],
           align: "center",
           resizable: false,
           cssClass: `${cursor_default} cell-gray text-center`,
           editorParams: {
               allowEmpty: true,
               showListOnEmpty: true,
               values: priorityOptions()
           },
           sorterParams: {
               alignEmptyValues: "bottom"
           },
           formatter: function (cell) {
               const row = cell.getRow();
               const data = row.getData();
               const value = cell.getValue();
               const priorityTextClass = value === constants.TASK_PRIORITY.HIGH ? "hight-priority-text" : null;
               if (data && data.id && TabelHelper.isParentRow(cell)) {
                   return `<div class=${priorityTextClass}> ${priorityOptions().find(item => item.value === value).label}</div>`;
               }
               return "";
           },
           editable: function (cell) {
               if (checkEditable(cell)) {
                   return TabelHelper.isParentRow(cell);
               }
           },
       },
       'issue': {
           title: strings.issue,
           field: "issue",
           editor: reactEditor(<DraftEditor isClosed={isClosed} userList={userList} getTaskList={getTableData} type="issue" />),
           formatter: reactFormatter(<DraftFormater type="issue" timeFormat={tableOptions.timeFormat}/>),
           validator: ["required", "string"],
           editable: false,
           cssClass: classNames(escape, cursor_default, "cell-gray", "disabled-child", "has-copy-icon"),
           width: getWidth(issue, 350),
           align: "left",
           cellClick: function (e, cell) {
               handleCellClick(e, cell, false, isClosed, 'updatedIssueBy');
           },
           sorterParams: { alignEmptyValues: "bottom" },
           sorter: draftSorter,
       },
       'answer': {
           title: strings.answer,
           field: "answer",
           editable: false,
           editor: reactEditor(<DraftEditor isClosed={isClosed} userList={userList} getTaskList={getTableData} type="answer" />),
           formatter: reactFormatter(<DraftFormater type="answer" />),
           validator: ["required", "string"],
           cssClass: "escape has-copy-icon",
           width: getWidth(answer, 350),
           align: "left",
           sorterParams: { alignEmptyValues: "bottom" },
           sorter: draftSorter,
           cellClick: function (e, cell) {
               const isParentRow = TabelHelper.isParentRow(cell);
               const row = cell.getRow();
               const data = row.getData();
               const config = row._row.modules && row._row.modules.dataTree;
               const isExpand = config && config.open;
               const { answerBackup } = data;
               const hasWrapData = answerBackup && answerBackup[0] && answerBackup[1];
               if (isParentRow && !isExpand && hasWrapData) {
                 const { answer, updatedAnswerBy, updatedAnswerAt } = answerBackup[0];
                 data.answer = answer;
                 data.updatedAnswerBy = updatedAnswerBy;
                 data.updatedAnswerAt = updatedAnswerAt;
                 row.treeToggle();
               } else {
                 handleCellClick(e, cell, false, isClosed, 'updatedAnswerBy');
               }
           },
       },
       'assignee': {
           title: strings.assignee,
           field: "assignee",
           editor: reactEditor(<SelectEditor isClosed={isClosed} />),
           width: getWidth(assignee, 140),
           validator: ["required"],
           align: "center",
           cssClass: "escape text-center",
           editorParams: {
               allowEmpty: true,
               showListOnEmpty: true,
               values: getAssigneeData(userList)
           },
           sorterParams: {
               alignEmptyValues: "bottom"
           },
           formatter: function (cell) {

               const value = cell.getValue() ? cell.getValue() : '';
               const user = getAssigneeData(userList).find(item => item.value === value);
               const label = value && user ? user.label : value ? 'Anonymous' : '';
               const cellData = value && getAssigneeData(userList).find(item => item.value === value);
               if (cellData) {
                   return `<div class="assignee">
                         <div class="assignee__name">${cellData.label}</div>
                       </div>`;
               }
               return label;
           }
       },
       'lastUpdate': {
           title: strings.last_update,
           cssClass: classNames("escape", "header-text-center"),
           field: "lastUpdate",
           formatter: reactFormatter(<LastUpdateFormatter timeFormat={tableOptions.timeFormat}/>),
           align: "center",
           width: getWidth(lastupdate, 180),
           sorterParams: { alignEmptyValues: "bottom" },
           cellClick: function (e, cell) {
               const row = cell.getRow();
               const data = row.getData();
               const payload = { ...data, project: projectId }
               const isParentRow = TabelHelper.isParentRow(cell);
               if (!_.isNil(data.id) && isParentRow) {
                   store.dispatch({ type: qaActions.QA_SHOW_HISTORY, payload })
               }
           }
       },
       'files': {
           title: strings.files,
           formatter: reactFormatter(<ButtonIconFile isClosed={isClosed} onClickFile={onClickFile} />),
           width: 100,
           cssClass: classNames(cursor_default, "text-center", "cell-gray"),
           align: "center",
           headerSort: false,
           field: "countAttachment",
           resizable: false
       },
       'reply': {
           title: strings.reply,
           formatter: reactFormatter(<ButtonIconReply />),
           width: 120,
           align: "center",
           cssClass: classNames(cursor_default, "text-center", "cell-gray"),
           headerSort: false,
           field: "subtasks",
           resizable: false
       },
       'delete': {
           title: strings.actions,
           formatter: reactFormatter(<ButtonIconDelete handleClick={deleteRow} />),
           width: 80,
           align: "center",
           cssClass: classNames(cursor_default, "text-center"),
           headerSort: false,
           field: "delete",
           resizable: false
        },
        'tags': {
            title: strings.tags,
            field: "tags",
            width: getWidth(tags, 180),
            editable: false,
            editor: TagsInput,
            formatter: TagsFormatter,
            cellClick: function (e, cell) {
                handleCellClick(e, cell, false, isClosed, 'taskList');
            },
        },
       'custom1': {
           title: strings.custom1,
           field: "custom1",
           editor: reactEditor(<DraftEditor isClosed={isClosed} userList={userList} getTaskList={getTableData} type="answer" />),
           formatter: reactFormatter(<DraftFormater type="custom1" />),
           validator: ["required", "string"],
           editable: false,
           cssClass: classNames(escape, "cell-gray", "disabled-child", "has-copy-icon"),
           width: getWidth(custom1, 220),
           align: "left",
           cellClick: function (e, cell) {
               const row = cell.getRow();
               const data = row.getData();
               const { id } = data;
               if (id) {
                handleCellClick(e, cell, false, isClosed, 'updatedCustome1By');
               }
           },
           sorterParams: { alignEmptyValues: "bottom" },
           sorter: draftSorter,
       },
       'custom2': {
           title: strings.custom2,
           field: "custom2",
           editor: reactEditor(<DraftEditor isClosed={isClosed} userList={userList} getTaskList={getTableData} type="answer" />),
           formatter: reactFormatter(<DraftFormater type="custom2" />),
           validator: ["required", "string"],
           editable: false,
           cssClass: classNames(escape, "cell-gray", "disabled-child", "has-copy-icon"),
           width: getWidth(custom2, 220),
           align: "left",
           cellClick: function (e, cell) {
             const row = cell.getRow();
             const data = row.getData();
             const { id } = data;
             if (id) {
              handleCellClick(e, cell, false, isClosed, 'updatedCustome2By');
             }
           },
           sorterParams: { alignEmptyValues: "bottom" },
           sorter: draftSorter,
       },
       'custom3': {
           title: strings.custom3,
           field: "custom3",
           editor: reactEditor(<DraftEditor isClosed={isClosed} userList={userList} getTaskList={getTableData} type="answer" />),
           formatter: reactFormatter(<DraftFormater type="custom3" />),
           validator: ["required", "string"],
           editable: false,
           cssClass: classNames(escape, "cell-gray", "disabled-child", "has-copy-icon"),
           width: getWidth(custom3, 220),
           align: "left",
           cellClick: function (e, cell) {
             const row = cell.getRow();
             const data = row.getData();
             const { id } = data;
             if (id) {
              handleCellClick(e, cell, false, isClosed, 'updatedCustome3By');
             }
           },
           sorterParams: { alignEmptyValues: "bottom" },
           sorter: draftSorter,
       }
    }

    const tableColumnSetting = [];
    tableSetting.forEach(item => {
      if (!(item.id === 'delete' && isClosed) && item.isVisible) {
        tableColumnSetting.push(columnObject[item.id]);
      }
    });

    return tableColumnSetting;

    function handleCellClick(e, cell, required, isClosed, type) {
        const eventTargetId = e && e.target && e.target.id;
        const ignoreIconButton = !(eventTargetId && ["copy-button-wrapper", "copy-button-icon"].indexOf(eventTargetId) !== -1);
        const data = cell.getData();
        let checkEditPermission = false;
        const cellUser =  data[type];
        if (cellUser) {
          const currentUserId = AuthHelper.getUserInfo().user_id;
          checkEditPermission = currentUserId === cellUser.id;
        } else {
          checkEditPermission = true;
        }
        let innerHTML = e.target && e.target.innerHTML;
        if (innerHTML && innerHTML.startsWith("#")) {
            let hashTag = innerHTML.slice(1, innerHTML.length);
            while ('.,!/()\'"?><;:'.includes(hashTag.slice(hashTag.length - 1, hashTag.length))) {
                hashTag = hashTag.slice(0, hashTag.length - 1)
            }

            const taskRefRegex = /^\d+(\.\d+)?$/
            if (taskRefRegex.test(hashTag)) {
                openTagIssue(innerHTML, cell);
                e.preventDefault();
            } else {
                searchByTag(hashTag);
            }
        } else if (!required || data.id) {
            let canEdit = checkEditable(cell);
            if (canEdit && !isClosed && checkEditPermission && ignoreIconButton) {
               updateAndEditCell(cell, canEdit);
            }
        }
    }

    function checkEditable(cell) {
        const data = cell.getData();
        let canEdit = true;
        if (TabelHelper.isParentRow(cell)) {
            if (data.taskStatus === constants.TASK_STATUS.CLOSE) {
                canEdit = false;
            }
        }
        else {
            const parent = cell && cell.getRow().getTreeParent();
            const parentData = parent && parent.getData();
            if (parentData&& parentData.taskStatus === constants.TASK_STATUS.CLOSE) {
                canEdit = false;
            }
        }
        return canEdit;
    }

    function openTagIssue(innerHTML, cell) {
        const tasks = innerHTML.match(/\d+/g);
        let [parentTaskNum, childTaskNum] = tasks;
        const data = cell.getRow().getData();
        if (data.project) {
            const payload = {
                projectId: data.project,
                parentTaskNum: parentTaskNum,
                childTaskNum: childTaskNum,
            }
            store.dispatch({ type: qaActions.QA_SHOW_TASK, payload })
        }

    }
}

function updateAndEditCell(cell, canEdit) {
    try {
        let currentEditCell = cell.getTable().modules.edit.getCurrentCell();
        currentEditCell.cancelEdit();
    } catch (error) { }
    try {
        cell && cell.edit(canEdit);
    } catch (error) { }
}

function draftSorter(a, b, aRow, bRow, column, dir, params) {
    try {
        let aObj = JSON.parse(a);
        a = aObj.blocks[0].text;
    } catch (error) { }
    try {
        let bObj = JSON.parse(b);
        b = bObj.blocks[0].text;
    } catch (error) { }

    var alignEmptyValues = params.alignEmptyValues;
    var emptyAlign = 0;
    var locale;

    //handle empty values
    if (!a) {
        emptyAlign = !b ? 0 : -1;
    } else if (!b) {
        emptyAlign = 1;
    } else {
        //compare valid values
        switch (typeof (params.locale)) {
            case "boolean":
                if (params.locale) {
                    locale = this.table.modules.localize.getLocale();
                }
                break;
            case "string":
                locale = params.locale;
                break;
            default:
                break;
        }

        return String(a).localeCompare(String(b), locale);
    }

    //fix empty values in position
    if ((alignEmptyValues === "top" && dir === "desc") || (alignEmptyValues === "bottom" && dir === "asc")) {
        emptyAlign *= -1;
    }

    return emptyAlign;
}
