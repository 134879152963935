export default {
    //Get file sidebar
    GET_SIDEBAR_REQUEST: 'GET_SIDEBAR_REQUEST',
    GET_SIDEBAR_SUCCESS: 'GET_SIDEBAR_SUCCESS',
    GET_SIDEBAR_FAIL: 'GET_SIDEBAR_FAIL',
    LOADMORE_SIDEBAR_REQUEST: 'LOADMORE_SIDEBAR_REQUEST',
    LOADMORE_SIDEBAR_SUCCESS: 'LOADMORE_SIDEBAR_SUCCESS',
    LOADMORE_SIDEBAR_FAIL: 'LOADMORE_SIDEBAR_FAIL',
    OPEN_SIDEBAR: 'OPEN_SIDEBAR',
    TOGGLE_SIDE_BAR: 'TOGGLE_SIDE_BAR',
    //Delete file sidebar
    DELETE_SIDEBAR_REQUEST: 'DELETE_SIDEBAR_REQUEST',
    DELETE_SIDEBAR_SUCCESS: 'DELETE_SIDEBAR_SUCCESS',
    DELETE_SIDEBAR_FAIL: 'DELETE_SIDEBAR_FAIL',

    //Upload file sidebar
    UPLOAD_SIDEBAR_REQUEST: 'UPLOAD_SIDEBAR_REQUEST',
    UPLOAD_SIDEBAR_SUCCESS: 'UPLOAD_SIDEBAR_SUCCESS',
    UPLOAD_SIDEBAR_FAIL: 'UPLOAD_SIDEBAR_FAIL',
    PUSH_SIDEBAR_REQUEST: 'PUSH_SIDEBAR_REQUEST',
    PUSH_SIDEBAR_REQUEST_SUCCESS: 'PUSH_SIDEBAR_REQUEST_SUCCESS',
    PUSH_SIDEBAR_REQUEST_FAIL: 'PUSH_SIDEBAR_REQUEST_FAIL',
    UPDATE_CELL: 'UPDATE_CELL'
}