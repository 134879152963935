import jwtDecode from 'jwt-decode';

import { LOGIN_ACTIONS } from '../actions';
import authHelper from '../../helpers/AuthHelper'
const token = authHelper.getToken()
let initState = {
    haveError: false,
    token,
    isAuthenticated: !!token,
    loading: false,
    isShowExpireAlert: false,
    userInfo: {},
}
export default function authReducer(state = initState, action) {
    let { type, payload } = action;
    switch (type) {
        case LOGIN_ACTIONS.LOGIN:
        case LOGIN_ACTIONS.LOGIN_AFTER_REGISTER:
            return Object.assign({}, state, {
                isAuthenticated: false,
                haveError: false,
                loading: true
            });
        case LOGIN_ACTIONS.LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isAuthenticated: true,
                data: payload,
                haveError: false,
                loading: false,
                token: payload.token,
                userInfo: jwtDecode(payload.token)
            });

        case LOGIN_ACTIONS.REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                token: payload.token,
                userInfo: jwtDecode(payload.token)
            };
        case LOGIN_ACTIONS.LOGIN_FAIL:
            return Object.assign({}, state, {
                isAuthenticated: false,
                haveError: true,
                loading: false
            });
        case LOGIN_ACTIONS.LOGOUT:
            return Object.assign({}, state, {
                isAuthenticated: false
            });
        case LOGIN_ACTIONS.LOGOUT_EXPIRE:
            return Object.assign({}, state, {
                isAuthenticated: false,
                isShowExpireAlert: true,
            });
        case LOGIN_ACTIONS.TURN_OFF_EXPIRE_ALERT:
            return Object.assign({}, state, {
                isShowExpireAlert: false,
            });
        default:
            return state;
    }
}
