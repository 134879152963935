import { call, put, takeLatest } from "redux-saga/effects";
import { HISTORY_ACTIONS } from "../actions";
import projectApi from '../../api/projectApi';

function* getHistoryList(action) {
    const { payload } = action;
    const { projectId, filter } = payload
    try {
        const data = yield call(requestGetList.bind(this, projectId, filter))
        const users = yield call(requestGetUsers.bind(this, projectId))
        yield put({ type: HISTORY_ACTIONS.HISTORY_GET_LIST_SUCCESS, payload: { data, users } })
    } catch (error) {
        yield put({ type: HISTORY_ACTIONS.HISTORY_GET_LIST_FAIL, payload: error })
    }
}

function requestGetList(projectId, filter) {
    return projectApi.histories(projectId, filter).then(response => {
        return response
    })
}

async function requestGetUsers(projectId, filter) {
    return await projectApi.users(projectId)
}

export function* watchHistoryAsync() {
    yield takeLatest(HISTORY_ACTIONS.HISTORY_GET_LIST, getHistoryList);
}
