import { call, put, takeLatest } from "redux-saga/effects";
import { BILLING_ACTIONS } from "../actions";
import paymentApi from '../../api/paymentApi';

function* getInvoiceList() {
    try {
        const data = yield call(_getInvoiceList.bind(this))
        yield put({ type: BILLING_ACTIONS.GET_INVOICE_SUCCESS, payload: data })
    } catch (error) {
        yield put({ type: BILLING_ACTIONS.GET_INVOICE_FAIL })
    }
}

function _getInvoiceList() {
  return paymentApi.getListInvoice().then(res => {
    return res.data
  })
}

export function* watchBillingAsync() {
    yield takeLatest(BILLING_ACTIONS.GET_INVOICE, getInvoiceList);
}
