import client from '../helpers/HttpClient'
import { urlQueryFromObject } from '../helpers/UtilsHelper';
const endpoint = '/admin/users/'

export default {
  async list(payload) {
    const query = urlQueryFromObject(payload)
    let path = `${endpoint}${query}`;
    let response = await client.get(path)
    return response.data;
  },

  async updateCurrentPlan({ adminId, tierType, interval = "year" }) {
    const path = `${endpoint}${adminId}/upgrade-tier/`;
    let response = await client.post(path, { tierType, interval });
    return response.data;
  },
}
