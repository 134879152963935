const actionTypes = {
    GET_ADMIN_LIST: "GET_ADMIN_LIST",
    GET_ADMIN_LIST_SUCCESS: 'GET_ADMIN_LIST_SUCCESS',
    GET_ADMIN_LIST_FAIL: 'GET_ADMIN_LIST_FAIL',

    UPDATE_CURRENT_PLAN: "UPDATE_CURRENT_PLAN",
    UPDATE_CURRENT_PLAN_SUCCESS: 'UPDATE_CURRENT_PLAN_SUCCESS',
    UPDATE_CURRENT_PLAN_FAIL: 'UPDATE_CURRENT_PLAN_FAIL',

}

export default actionTypes

export const getList = (filters) => ({ type: actionTypes.GET_ADMIN_LIST, payload: { filters } })
export const updateCurrentPlan = (adminId, tierType, toggleModalCurrentPlan) => ({ type: actionTypes.UPDATE_CURRENT_PLAN, payload: { adminId, tierType, toggleModalCurrentPlan } })

