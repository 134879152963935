import {
    APP_ACTIONS
} from "../actions";

const initState = {
    loading: false,
    projectId: null,
    projectName: null,
    checkDisconnect: false,
    history: null,
    header: null,
};

export default function appReducer(state = initState, action) {
    const { type, payload } = action;
    switch (type) {
        case APP_ACTIONS.UPDATE_HEADER:
            const { header } = payload
            return {
                ...state,
                header,
            }
        case APP_ACTIONS.APP_CHANGE_CURRENT_PROJECT:
            const { projectId } = payload
            return {
                ...state,
                projectId,
            }
        case APP_ACTIONS.APP_CLEAN_CURRENT_PROJECT:
            return {
                ...state,
                projectId: null,
            }
        case APP_ACTIONS.APP_CHANGE_CURRENT_PROJECT_NAME:
            const { projectName } = payload
            return {
                ...state,
                projectName,
            }
        case APP_ACTIONS.APP_CLEAN_CURENT_PROJECT_NAME:
            return {
                ...state,
                projectName: null,
            }
        case APP_ACTIONS.DISCONNECT_ON:
            return {
                ...state,
                checkDisconnect: true,
            }
        case APP_ACTIONS.DISCONNECT_OFF:
            return {
                ...state,
                checkDisconnect: false,
            }
        case APP_ACTIONS.START_LOADING:
            return {
                ...state,
                loading: true,
            }
        case APP_ACTIONS.END_LOADING:
            return {
                ...state,
                loading: false,
            }
        case APP_ACTIONS.UPDATE_HISTORY:
            const { history } = payload;
            return {
                ...state,
                history
            }
        default:
            return state;
    }
}
