const QA_SHOW_HISTORY = 'QA_SHOW_HISTORY';
const QA_TOGGLE_HISTORY = 'QA_TOGGLE_HISTORY';
const QA_TOGGLE_TASK = 'QA_TOGGLE_TASK';
const QA_SHOW_TASK = 'QA_SHOW_TASK';
const QA_GET_HISTORY_SUCCESS = 'QA_GET_HISTORY_SUCCESS';
const QA_GET_HISTORY_FALSE = 'QA_GET_HISTORY_FALSE';
const QA_SHOW_CONFLICT = 'QA_SHOW_CONFLICT';
const QA_HIDE_CONFLICT = 'QA_HIDE_CONFLICT';
const QA_RETRY_BUFFER = 'QA_RETRY_BUFFER';
const QA_RETRY_BUFFER_LOAD = 'QA_RETRY_BUFFER_LOAD';
const QA_RETRY_BUFFER_CLOSE = 'QA_RETRY_BUFFER_CLOSE';
const QA_RETRY_BUFFER_CLEAR_AND_CLOSE = 'QA_RETRY_BUFFER_CLEAR_AND_CLOSE';
const QA_RETRY_BUFFER_COMMIT_AND_CLOSE = 'QA_RETRY_BUFFER_COMMIT_AND_CLOSE';
const QA_SAVE_TABLE = 'QA_SAVE_TABLE';
const QA_UPDATE_TASK_COUNT = 'QA_UPDATE_TASK_COUNT';
const QA_UPDATE_TASK_READS = 'QA_UPDATE_TASK_READS';
const QA_SET_TASK_READ = 'QA_SET_TASK_READ';
const QA_SET_USE_TRANSLATION = 'QA_SET_USE_TRANSLATION';
const QA_SET_TRANSLATION_LANGUAGE = 'QA_SET_TRANSLATION_LANGUAGE';
const QA_INITIALIZE_TRANSLATION = 'QA_INITIALIZE_TRANSLATION';
const QA_INITIALIZE_TRANSLATION_DONE = 'QA_INITIALIZE_TRANSLATION_DONE';
const QA_GET_TRANSLATION_INFO = 'QA_GET_TRANSLATION_INFO';
const QA_GET_TRANSLATION_INFO_SUCCESS = 'QA_GET_TRANSLATION_INFO_SUCCESS';
const QA_CHECK_EMPTY_DATA_TABLE = 'QA_CHECK_EMPTY_DATA_TABLE';

export default {
    QA_RESET_STATE: 'QA_RESET_STATE',
    QA_GET_LIST: 'QA_GET_LIST',
    QA_GET_LIST_SUCCESS: 'QA_GET_LIST_SUCCESS',
    QA_GET_LIST_FAIL: 'QA_GET_LIST_FAIL',
    QA_EXPAND_ROW: 'QA_EXPAND_ROW',
    QA_ADD_ROW: 'QA_ADD_ROW',
    QA_SHOW_HISTORY,
    QA_TOGGLE_HISTORY,
    QA_TOGGLE_TASK,
    QA_SHOW_TASK,
    QA_GET_HISTORY_SUCCESS,
    QA_GET_HISTORY_FALSE,
    QA_SHOW_CONFLICT,
    QA_HIDE_CONFLICT,
    QA_RETRY_BUFFER,
    QA_RETRY_BUFFER_LOAD,
    QA_RETRY_BUFFER_CLOSE,
    QA_RETRY_BUFFER_CLEAR_AND_CLOSE,
    QA_RETRY_BUFFER_COMMIT_AND_CLOSE,
    QA_SAVE_TABLE,
    QA_UPDATE_TASK_COUNT,
    QA_UPDATE_TASK_READS,
    QA_SET_TASK_READ,
    QA_SET_USE_TRANSLATION,
    QA_SET_TRANSLATION_LANGUAGE,
    QA_INITIALIZE_TRANSLATION,
    QA_INITIALIZE_TRANSLATION_DONE,
    QA_GET_TRANSLATION_INFO,
    QA_GET_TRANSLATION_INFO_SUCCESS,
    QA_CHECK_EMPTY_DATA_TABLE,
}

export const showHistory = () => ({ type: QA_SHOW_HISTORY })
export const toggleHistory = () => ({ type: QA_TOGGLE_HISTORY })
export const toggleTask = () => ({ type: QA_TOGGLE_TASK })
export const showConflict = (resolve) => ({ type: QA_SHOW_CONFLICT, resolve })
export const hideConflict = () => ({ type: QA_HIDE_CONFLICT })
export const retryBuffer = (projectId) => ({ type: QA_RETRY_BUFFER, payload: { projectId } })
export const closeRetryBuffer = () => ({ type: QA_RETRY_BUFFER_CLOSE })
export const clearAndCloseRetryBuffer = () => ({ type: QA_RETRY_BUFFER_CLEAR_AND_CLOSE })
export const commitAndCloseRetryBuffer = () => ({ type: QA_RETRY_BUFFER_COMMIT_AND_CLOSE })
export const updateTaskReads = (taskReads) => ({ type: QA_UPDATE_TASK_READS, payload: { taskReads } })
export const setUseTranslation = (projectId, isUse) => ({ type: QA_SET_USE_TRANSLATION, payload: { projectId, isUse } })
export const setTranslationLanguage = (projectId, languageCode) => ({ type: QA_SET_TRANSLATION_LANGUAGE, payload: { projectId, languageCode } })
export const initializeTranslation = (projectId) => ({type: QA_INITIALIZE_TRANSLATION, payload: {projectId}})
export const getTranslationInfo = (projectId) => ({type: QA_GET_TRANSLATION_INFO, payload: {projectId}})
export const updateEmptyData = (status) => ({ type: QA_CHECK_EMPTY_DATA_TABLE, payload: status })
