
const END_POINT = `${window.location.hostname}:${process.env.REACT_APP_SERVER_PORT}`
const HTTP_END_POINT = process.env.REACT_APP_SERVER
const API_END_POINT = `${HTTP_END_POINT}/api`
const WS_END_POINT = `ws://${END_POINT}`
const STATIC_WEB_URL = "/staticweb/index.html"
const TOKEN_LIFE_TIME = 1000 * 60 * 30; // 30 minutes
const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 25; // 25 minutes
const CONSTANTLY_SAVE_INTERVAL = 1000 // ms/s
const CONSTANTLY_UPDATE_INTERVAL = 60000 // ms/s
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY
const FILE_URL_LIFE_TIME = 1800 * 1000 // 1 minute

let firebaseConfig = {}

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    firebaseConfig = require("./firebaseConfig").firebaseConfig
} else if (process.env.REACT_APP_STAGING) {
    firebaseConfig = require("./firebaseConfigStaging").firebaseConfig
} else {
    firebaseConfig = require("./firebaseConfigProduction").firebaseConfig
}

export default {
    END_POINT,
    HTTP_END_POINT,
    API_END_POINT,
    WS_END_POINT,
    STATIC_WEB_URL,
    TOKEN_LIFE_TIME,
    REFRESH_TOKEN_INTERVAL,
    CONSTANTLY_SAVE_INTERVAL,
    CONSTANTLY_UPDATE_INTERVAL,
    STRIPE_KEY,
    firebaseConfig,
    FILE_URL_LIFE_TIME,
}
