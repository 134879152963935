import {
    ADMIN_DASHBOARD_ACTIONS
} from "../actions";

const initState = {
    loading: false,
    data: {}
};

export default function adminDashboardReducer(state = initState, action) {
    let { type, payload } = action;
    switch (type) {

        // Get Provider List
        case ADMIN_DASHBOARD_ACTIONS.GET_ADMIN_LIST:
            return {
                ...state,
                loading: true,
            }
        case ADMIN_DASHBOARD_ACTIONS.GET_ADMIN_LIST_SUCCESS:
            return {
                ...state,
                data: payload,
                loading: false,
            }
        case ADMIN_DASHBOARD_ACTIONS.GET_ADMIN_LIST_FAIL:
            return {
                ...state,
                loading: false,
            }

        // Update
        // case ADMIN_DASHBOARD_ACTIONS.UPDATE_PROVIDER:
        //     return {
        //         ...state,
        //         loading: true,
        //     }
        // case ADMIN_DASHBOARD_ACTIONS.UPDATE_PROVIDER_SUCCESS:
        //     if (state.data.result) {
        //         const listProvider = [...state.data.result];
        //         let providerUpdate = listProvider.map(item => item.id === payload.id ? payload : item);
        //         return {
        //             data: { ...state.data, result: providerUpdate },
        //             loading: false,
        //         }
        //     }
        //     else {
        //         return {
        //             ...state,
        //             data: payload,
        //             loading: false,
        //         }
        //     }
        // case ADMIN_DASHBOARD_ACTIONS.UPDATE_PROVIDER_FAIL:
        //     return {
        //         ...state,
        //         loading: false,
        //     }

        // Update Status
        case ADMIN_DASHBOARD_ACTIONS.UPDATE_CURRENT_PLAN:
            return {
                ...state,
                loading: true,
            }
        case ADMIN_DASHBOARD_ACTIONS.UPDATE_CURRENT_PLAN_SUCCESS:
            if (state.data.results) {
                const adminUserUpdate = state.data.results.map(item => {
                    if (item.id === payload.adminId) {
                        item.tierType = payload.response.tierType
                    }
                    return item
                });
                return {
                    data: { ...state.data, result: adminUserUpdate },
                    loading: false,
                }
            }
            else {
                return {
                    ...state,
                    data: payload,
                    loading: false,
                }
            }
        case ADMIN_DASHBOARD_ACTIONS.UPDATE_CURRENT_PLAN_FAIL:
            return {
                ...state,
                loading: false,
            }

        default:
            return state;
    }
}
