export default {
    "#": "#",
    home: "홈",
    attachments: "첨부 파일",
    history: "역사",
    setting: "설정",
    user_setting: "사용자 설정",
    company_QA: "회사 A 's QA 시트",
    tag_not_available: "참조 태그를 찾을 수 없습니다",
    invite_user_success: "사용자 초대를 완료했습니다",
    invite_email_fail_message: "초대에 실패했습니다",
    email_exist_error: "이 이메일 주소는 이미 등록되어 있습니다",
    change_password_success: "암호 변경에 성공했습니다",
    change_password_fail: "암호 변경에 실패했습니다",
    current_password_incorrect: "비밀번호가 일치하지 않습니다",
    update_profile_success: "프로파일이 업데이트되었습니다",
    update_profile_error: "프로파일 업데이트에 실패했습니다",
    current_password: "현재 비밀번호",
    new_issue: "새로운 질문",
    update_issue: "질문 업데이트",
    all_changes: "모든 변경",
    notifications: "통지",
    inviter: "초대",
    confirm_invitation: "초대 확인",
    invitation_date: "초대 날짜",
    reject: "거부",
    confirm_invite_success: "초대에 성공했습니다",
    status: "상태",
    priority: "우선 순위",
    issue: "질문",
    issues: "질문",
    assignee: "의뢰자",
    answer: "답변",
    upload: "업로드",
    comment: "댓글",
    title: "제목",
    time: "시간",
    add_file: "파일을 추가하려면",
    all_files: "모든 파일",
    question: "질문",
    last_update: "마지막",
    files: "파일",
    reply: "응답",
    search_bar: "검색 창",
    undo: "취소",
    save: "저장",
    share: "공유하기",
    language: "언어",
    add_new: "새로 만들기",
    select_project: "프로젝트를 선택",
    project_code: "프로젝트 코드",
    project_name: "프로젝트 이름",
    author: "작성자",
    created_date: "작성일",
    updated_date: "업데이트 날짜",
    text_signup: "SmartQA에 지금 참여하여 QA 시트의 연락을 효율화합시다",
    text_signin: "로그인 페이지로 이동합니다!",
    join: "참여",
    previous: "전",
    next: "다음",
    search: "검색",
    project_search_placeholder: "프로젝트 이름 또는 코드로 검색",
    history_search_placeholder: "기록 내용으로 검색",
    size: "크기",
    auth_invalid: "인증이 제대로 실행되지 않았습니다.",
    project_selection: "프로젝트 선택",
    logout: "로그 아웃",
    login: "로그인",
    ok: "예",
    username: "사용자 이름",
    password: "비밀번호",
    confirm_password: "비밀번호를 인증하기",
    required_login: "사용자 이름과 암호를 입력하십시오.",
    wrong_login_info: "사용자 이름 또는 암호가 잘못되었습니다.",
    create: "작성",
    create_project: "프로젝트 만들기",
    cancel: "취소",
    project_name_placeholder: "프로젝트 이름을 입력하십시오",
    create_project_sucess: "프로젝트를 만들었습니다",
    create_project_fail: "프로젝트 작성에 실패했습니다",
    empty_data: "데이터가 없습니다",
    signup: "가입",
    signin: "로그인",
    register_now: "지금 등록",
    delete: "삭제하기",
    update: "업데이트",
    upload_success: "파일 업로드 성공!",
    upload_fail: "파일 업로드가 실패!",
    update_success: "업데이트에 성공했습니다!",
    update_fail: "업데이트에 실패했습니다!",
    learn_more: "자세히 알아보기",
    take_the_tour: "자세히 알아보기",
    delete_issue_success: "질문을 삭제했습니다",
    search_qa: "QA를 검색",
    hide_closed: "숨기기",
    confirm_delete_text: "이 아이템을 삭제 하시겠습니까?",
    confirm_resend_text: "또한 초대장을 보냅니다?",
    confirm_delete_folder: "이 아이템을 삭제 하시겠습니까?",
    project_hercules: "Hercules 프로젝트",
    navigation: "네비게이션",
    account: "계정",
    reset: "재설정",
    share_project_success: "프로젝트를 공유했습니다",
    share_project: "프로젝트 공유하기",
    copy_project: "복사",
    date_time: "일시",
    task: "작업",
    action: "액션",
    create_task: "작업 만들기",
    update_task: "업데이트 작업",
    delete_task: "작업을 삭제",
    create_sub_task: "하위 작업",
    update_sub_task: "하위 업데이트",
    delete_sub_task: "하위 제거",
    create_attachment: "작업에 첨부 파일을 추가하려면",
    update_attachment: "작업의 첨부 파일 [_file_name_를 업데이트합니다",
    delete_attachment: "작업에서 첨부 파일을 삭제",
    delete_file_success: "파일 삭제에 성공했습니다",
    delete_file_fail: "파일의 삭제에 실패했습니다",
    project_history: "프로젝트 내역",
    confirmation: "확인",
    thumbnail: "썸네일",
    file_name: "파일 이름",
    date: "날짜",
    user: "사용자",
    users: "사용자",
    download: "다운로드",
    remove: "삭제",
    load_more: "로드",
    and: "그리고",
    order: "의뢰",
    after: "후",
    before: "전",
    add_user: "사용자 추가",
    level: "레벨",
    options: "옵션",
    manager: "관리자",
    email: "이메일 주소",
    can_not_add_user: "사용자를 추가 할 수 없습니다",
    high: "고",
    normal: "중",
    low: "낮은",
    open: "열",
    in_progress: "진행 중",
    close: "닫기",
    invite: "초대",
    invite_user: "사용자 초대",
    list_invalid: "이메일 주소가 잘못되었습니다.",
    confirm_invite_email: "이 이메일 주소로 초대장을 보내려면?",
    send: "전송",
    list_email: "메일 링리스트",
    back: "돌아 가기",
    confirm: "확인",
    confirm_upgrade: "업그레이드 확인",
    input_list_email: "이메일 주소를 입력하십시오",
    register: "등록",
    first_name: "이름",
    romanized_first_name: "영문 이름",
    last_name: "성",
    romanized_last_name: "로마의 성",
    field_required: "필수 입력 항목입니다",
    password_min_length: "비밀번호는 6 자 이상으로 설정하십시오",
    password_dont_match: "비밀번호가 일치하지 않습니다",
    register_success_title: "등록에 성공했습니다!",
    register_success_content: "로그인 smartQA에 참여할 수 있습니다!",
    request_register_success_title: "요청에 성공했습니다!",
    request_register_success_content: "등록 링크를 보냈습니다. 이메일을 확인하여 등록을 완료하십시오!",
    register_link_expired: "등록 링크의 유효 기간이 만료되었습니다",
    company: "회사",
    profile: "프로필",
    show_hide: "표시 / 숨기기",
    change_password: "암호 변경",
    updated_by: "업데이트들",
    yes: "예",
    no: "아니오",
    override: "덮어",
    warning: "경고",
    conflict_warning_message: "이 셀의 내용은 변경되었습니다",
    select: "선택",
    reference_tag: "참조 태그",
    sub_title_download: "다운로드",
    sub_title_delete: "삭제하기",
    error: "오류",
    disconnect_content: "연결을 확인하십시오!",
    pending_invitations: "보류중인 초대",
    closed: "닫기",
    complete: "완료",
    delete_project_warning: "이 프로젝트를 삭제하면 데이터베이스에서 모든 정보가 삭제됩니다. 취소 할 수 없습니다",
    delete_project_guild: "삭제를 실행하려면 \"DELETE\"를 입력하십시오",
    close_project_warning: "닫힌 프로젝트는 읽기 전용 모드로 설정됩니다. 사용자는 프로젝트에 뭔가를 추가하거나 편집 할 수 없습니다.",
    close_project_guild: "닫아도 경우에는 \"CLOSE\"를 입력합니다",
    leave_project: "이 프로젝트에서 퇴출",
    close_project: "이 프로젝트를 완료",
    open_project: "이 프로젝트 열기",
    delete_project: "이 프로젝트를 삭제",
    delete_project_title: "프로젝트 삭제",
    close_project_title: "프로젝트 종료",
    open_project_title: "프로젝트 열기",
    leave: "퇴출",
    view: "보기",
    export: "내보내기",
    leave_project_title: "프로젝트에서 퇴출",
    leave_project_warning_user: "이 프로젝트를 종료 하시겠습니까?",
    leave_project_warning_admin: "당신이 프로젝트의 관리자이지만, 정말 퇴출 하시겠습니까?",
    leave_project_warning_admin_assign: "퇴출 할 경우이 프로젝트 매니저를 지정하십시오!",
    delete_project_error_message: "이 프로젝트를 삭제할 수 없습니다",
    close_project_success_message: "프로젝트를 종료 まし했다",
    close_project_error_message: "프로젝트를 종료 할 때 오류가 발생했습니다",
    close_project_confirm_error_message: "프로젝트를 닫으려면 \"CLOSE\"를 입력하십시오",
    open_project_warning: "이 프로젝트를 다시 시작 하시겠습니까?",
    open_project_guild: "열 경우 \"OPEN\"을 입력하십시오",
    open_project_success_message: "프로젝트를 성공적으로 열었습니다",
    open_project_error_message: "오류가 발생했습니다",
    open_project_confirm_error_message: "열 경우 \"OPEN\"을 입력하십시오",
    general_files: "일반 파일",
    description: "설명",
    add_folder: "폴더를 추가",
    add_folder_success: "폴더를 추가했습니다",
    delete_success: "삭제했습니다",
    delete_fail: "삭제에 실패했습니다",
    new_folder: "새 폴더",
    forgot_password: "비밀번호를 잊어 버렸습니까?",
    invalid_email: "잘못된 이메일 주소",
    request_reset_password_successful: "비밀번호를 재설정하려면 이메일을 확인하십시오",
    enter_email: "이메일 주소를 입력하십시오",
    project_settings: "프로젝트 설정",
    customize_columns: "열을 사용자 정의 할",
    reset_password: "암호 재설정",
    reset_password_success: "비밀번호를 재설정했습니다",
    reset_password_fail: "암호 재설정에 실패했습니다",
    reset_password_success_notice: "새 암호를 성공적으로 변경되었습니다!",
    reset_password_expired: "암호 재설정 링크가 만료 있습니다",
    billing: "결제 정보",
    invoices: "청구서",
    current_plan: "최신 계획",
    basic_plan: "Basic Plan",
    change_plan: "계획의 변경",
    next_billing_date: "다음 청구일",
    next_plan: "다음 계획",
    billing_information: "청구 정보",
    name: "이름",
    company_name: "회사 명",
    company_address: "회사 주소",
    city_country: "도시 국가",
    zip: "우편 번호",
    personal_address: "주소",
    payment_methods: "지불 방법",
    credit_card_or_bank_tranfer: "신용 카드 또는 은행 송금",
    change_my_plan: "계획의 변경",
    upgrade: "업그레이드",
    account_deletion: "계정 삭제",
    account_deletion_warning: "이렇게하면 계정과 관련 데이터가 삭제됩니다. 취소 할 수 없습니다.",
    delete_my_account: "계정 삭제",
    confirm_delete_account: "계정을 삭제 하시겠습니까?",
    create_success: "작성에 성공했습니다",
    create_fail: "작성에 실패했습니다",
    add_card: "카드를 추가",
    credit_card: "신용 카드",
    bank_transfer: "은행 송금",
    select_payment_method: "결제 방법의 선택",
    list_credit_card: "신용 카드 목록",
    upgrade_account_message: "이 기능을 사용하려면 계정을 업그레이드해야합니다!",
    confirm_payment: "결제 확인",
    select_method_upgrade: "계정을 업그레이드하기 전에 결제 방법을 선택해야합니다!",
    pay_with_card: "다음의 신용 카드 거래를 수행합니다.",
    update_payment_method: "결제 방법 업데이트",
    pay: "지불",
    add_new_credit_card: "새로운 신용 카드를 추가",
    free: "무료",
    basic: "기본",
    pro: "프로",
    pro_translation: "프로 + 번역",
    limit_invite_email_error: "전자 메일의 수를 초과했습니다. 더 많은 사람을 초대하려면 계정을 업그레이드해야합니다.",
    select_type_payment: "결제 방법의 선택",
    from: "변경 전",
    plan: "계획",
    monthly: "월",
    yearly: "년",
    per_month: "월",
    per_year: "연간",
    selected: "선택한",
    jpy: "¥",
    usd: "$",
    you_save: "당신은 저장",
    downgrade: "다운 그레이드",
    number_of_object: "개체 수",
    price: "가격",
    max_attachment_storage: "첨부 파일 용량 제한",
    customizations_themes: "사용자 정의 디자인",
    max_collaborators: "등록 가능 인원",
    download_only: "다운로드 만",
    unlimited: "무제한",
    '0MB': "0MB",
    '250MB': "250MB",
    '5GB': "5GB",
    '380yen': "380 엔",
    '780yen': "780 엔",
    change: "변경",
    'free_des': "누구와도 자유롭게 협업",
    '3_team_members': "총 3 명",
    '3_projects': "총 3 개의 프로젝트",
    'file_download_only': "파일 다운로드 만",
    'basic_des': "시작하자",
    '5_team_members': "프로젝트 당 총 5 명의 멤버",
    '30_team_members': "프로젝트 당 총 30 명의 멤버",
    '10_projects': "총 10 프로젝트",
    '250_mb_store': "파일 업로드 용으로 250 MB 저장",
    'buy_basic': "기본 구매",
    'pro_des': "프로 사용자를위한 프로 기능",
    'unlimited_team_members': "프로젝트마다 무제한 멤버",
    'unlimited_projects': "각 프로젝트의 무제한 팀 구성원",
    '10_gb_storage': "파일 업로드 용 10GB 저장",
    'export_qa': "QA 프로젝트를 CSV로 내보내기",
    'buy_pro': "프로 구매",
    'buy_pro_plus': "프로 구매 + 번역",
    'back_to_free': "무료로 돌아 가기",
    'back_to_basic': "기본으로 돌아 가기",
    'get_started': "시작하자",
    'pro_tran_des': "프로젝트에 자동 번역 추가",
    'all_pro_features': "모든 Pro 기능",
    'auto_translation_list_language': "자동 번역 (영어, 일본어, 중국어, 한국어)",
    'add_member_for_your_auto_translation_project': "자동 번역 프로젝트의 모든 회원은 Pro + Translate 등급이 아닌 경우에도 번역 기능을 사용할 수 있습니다",
    replies: "회신",
    view_history: "기록보기",
    actions: "액션",
    type_here_to_add_reply: "여기에 입력하여 회신합니다",
    type_here_to_add_issue: "여기에 입력 한 과제를 추가합니다",
    all_status: "모든 상태",
    display_issue: "표시된 과제",
    current_tier_membership_time: "현재 티아 회원 시간",
    total_projects: "모든 프로젝트",
    signup_date: "등록일",
    of_project: "프로젝트 수",
    previous_plan: "이전 계획",
    previous_plan_start_date: "이전 계획 개시일",
    previous_plan_total_months: "이전 계획의 총 월수",
    current_plan_start_date: "최신 계획 개시일",
    current_plan_total_months: "최신 계획의 총 월수",
    billing_type: "청구 방법",
    activity_level: "활동 수준",
    min_months: "최소 월",
    max_months: "최대 월",
    min_project: "최소 프로젝트. . .",
    max_project: "최대 프로젝트. . .",
    start_date: " - 시작일 -",
    end_date: " - 종료일 -",
    type: "타입. . .",
    year: "년",
    month: "월",
    change_current_plan_text_modal: "이 계정의 계획을 변경",
    showing: "보기",
    to: "~",
    of: "/",
    entities: "항목",
    select_file_upload: "업로드 할 파일을 선택",
    drag_and_drop: "여기에 파일을 드롭",
    add_a_title: "제목을 추가",
    add_a_comment: "코멘트를 추가",
    projects: "프로젝트",
    project: "프로젝트",
    add_project_placeholder: "여기에 입력하고 프로젝트를 추가합니다",
    search_project: "프로젝트를 검색",
    super_admin: "특별 관리",
    login_smartQA: "smartQA에 로그인합니다.",
    sign_up_smartQA: "smartQA에 등록합니다.",
    sign_up_des_1: "SmartQA에 지금 참여하여 QA 시트의 연락을 효율화시켜보세요",
    sign_up_des_2: "자세히보기",
    sign_up_des_3: "투어",
    export_this_project: "이 프로젝트를 내보내기",
    browse_files: "검색 파일",
    status_filter: "상태 필터",
    translation: "번역",
    address: "주소",
    card_information: "카드 정보",
    country_or_region: "국가 또는 지역",
    tags: "태그",
    custom1: "정의 1",
    custom2: "사용자 정의 2",
    custom3: "사용자 정의 3",
    copy: "텍스트가 클립 보드에 복사되었습니다",
    resend_success: "초대는 다시 한번 보내졌습니다",
    resend_fail: "초대는 전송되지 않았습니다",
    date_joined: "등록 날짜",
    pin: "이 프로젝트를 고정하기",
    unpin: "이 프로젝트의 고정을 해제",
    department: "부문",
    overview: "요약",
    plans: "계획",
    add_payment_method: "결제 방법을 추가",
    card_number: "카드 번호",
    expiry: "유효 기간",
    name_on_card: "카드 명의인",
    contact_us: "문의",
    bank_transfer_placeholder: "은행 송금에 관한 문의는 직접 메일로 연락주세요",
    current_plan_detail: "현재 계획은",
    downgrade_plan_detail_1: "당신에게 다운 그레이드",
    downgrade_plan_detail_2: "계획이 시작됩니다",
    select_card: "카드를 선택",
    department_division: "부문",
    collaborators: "협력자",
    time_format: "시간 형식",
    update_settings: "설정 업데이트",
    save_settings_success: "설정은 성공적으로 업데이트했습니다",
    save_settings_error: "설정을 업데이트하는 것은 실패",
    project_limit_message: "계획 프로젝트 한도에 도달했습니다. 계정을 업그레이드하거나 참여를 종료하십시오.",
    project_limit_reached: "계획 프로젝트 한도에 도달했습니다. 계정을 업그레이드하거나 참여를 종료하십시오.",
    confirm_payment_description: "여기에서는 지불이 언제 청구되는지 등의 정보를 추가 할 수 있습니다.",
    paid: "유료",
    pages: "페이지",
    invitation_reject: "초대가 거부되었습니다",
    change_language: "언어를 바꾸십시오",
    auto_translate: "자동 번역",
    enable_translations: "번역을 켜다",
    english: "영어",
    japanese: "일본어",
    korean: "한국어",
    chinese: "중국말",
    you_have: "당신은",
    auto_translation_setting_title: "자동 번역 프로젝트 크레딧.",
    enable_auto_translation: "자동 번역을 사용 하시겠습니까?",
    auto_translation_setting_subject: "이 작업은 취소 할 수 없으며 번역 크레딧을 다시 받으려면 프로젝트를 닫거나 삭제해야합니다.",
    auto_translation_setting_subject_warning: "번역 크레딧을 다시 받으려면 프로젝트를 닫거나 삭제해야합니다.",
    import: "수입",
    export_csv: "CSV 내보내기",
    export_csv_file: "CSV + 파일 내보내기",
    original: "기발한",
    auto_translation: "자동 번역",
    enable: "사용",
    on: "의 위에",
    off: "떨어져서",
    import_data_into_this_project: "이 프로젝트로 데이터 가져 오기",
    owner: "소유자",
    choose_file_to_upload: "업로드 할 파일을 선택하십시오",
    choose_another_file: "다른 파일을 선택하십시오",
    issue_column: "이슈 열",
    answer_column: "답변 열",
    import_file_success: "파일 가져 오기 성공",
    import_file_failure: "파일 가져 오기 실패",
    averageIssue: "한달에 평균 발행",
    averageIssueTrans: "월 평균 이슈 A-T",
    averageCharacters: "한달에 평균 문자",
    averageCharactersTrans: "한달에 평균 문자 A-T",
    group: "그룹",
    groups: "그룹",
    project_group: "프로젝트 그룹",
    new_project_group: "새로 프로젝트 그룹",
    project_group_name: "그룹 이름",
    no_group: "그룹 없음",
    change_group: "그룹 변경",
    unsorted: "(정렬되지 않은)",
    delete_project_group: "프로젝트 그룹 삭제",
    delete_project_group_confirm: "이 그룹을 삭제하면 모든 프로젝트가 \"정렬되지 않은\"그룹으로 이동됩니다. 계속 하시겠습니까?",
    group_404: "이 그룹은 존재하지 않습니다",
    limited_space_message: "계층의 파일 저장 용량 한도에 도달했습니다. 파일을 삭제하거나 계정을 업그레이드하십시오.",
}
