import React from "react";
import * as ReactDOM from "react-dom";
import AuthHelper from "../../helpers/AuthHelper";
import { store } from '../../redux';

function reactEditor(JSX) {
    return function customFormatter(cell, onRendered, success, cancel, editorParams) {
        let editor = document.createElement("div");
        editor.classList.add("custom-cell-editing")

        function onSuccess(value) {
            success(value);
        }

        let CompWithMoreProps = React.cloneElement(JSX, { ...JSX.props, cell, onRendered, onSuccess, cancel, editorParams });
        ReactDOM.render(CompWithMoreProps, editor);

        return editor;
    };
}

function reactFormatter(JSX) {
    return function customFormatter(cell, formatterParams, onRendered) {
        let baseElement = document.createElement("div");
        var CompWithMoreProps = React.cloneElement(JSX, { cell, formatterParams, onRendered, baseElement });
        ReactDOM.render(CompWithMoreProps, baseElement);
        return baseElement;
    };
}

function saveEditBuffer(projectId, taskId, fieldName, value, rowData) {
    const userId = AuthHelper.getUserInfo().user_id;
    const timeStamp = Date.now();

    const saveData = {
        userId,
        projectId,
        taskId,
        fieldName,
        value,
        rowData,
        timeStamp,
    }

    const buffers = getEditBuffers();
    let existBuffer = buffers.find(findBufferCallback(saveData));

    if (existBuffer) {
        if (existBuffer.value !== value)
            Object.assign(existBuffer, saveData)
    } else {
        buffers.push(saveData)
    }

    saveBuffers(buffers);
}

function getEditBuffer(userId, projectId, taskId) {
    const buffers = getEditBuffers();
    return buffers.find(findBufferCallback({ userId, projectId, taskId }));
}

function getEditBuffersByUser(userId, projectId) {
    const buffers = getEditBuffers();
    const userBuffers = buffers.filter(b => b.userId === userId && b.projectId === projectId);
    return userBuffers;
}

function removeEditBuffer(projectId, taskId) {
    const userId = AuthHelper.getUserInfo().user_id;
    const buffers = getEditBuffers();
    const bufferIndex = buffers.findIndex(findBufferCallback({ userId, projectId, taskId }));
    if (bufferIndex < 0) {
        return;
    }

    buffers.splice(bufferIndex, 1);
    saveBuffers(buffers);
}

function removeAllBufferOnProject(projectId) {
    const prId = projectId || store.getState().app.projectId;
    const userId = AuthHelper.getUserInfo().user_id;
    const buffers = getEditBuffers();
    const bufferIndex = buffers.findIndex(buffer => buffer.userId === userId && buffer.projectId === prId);
    if (bufferIndex < 0) {
        return;
    }

    buffers.splice(bufferIndex, 1);
    saveBuffers(buffers);
}

function findBufferCallback(buffer) {
    return b => b.userId === buffer.userId
        && b.projectId === buffer.projectId
        && b.taskId === buffer.taskId
}

function saveBuffers(buffers) {
    localStorage.setItem("edit_buffers", JSON.stringify(buffers));
}

function getEditBuffers() {
    const buffersRaw = localStorage.getItem("edit_buffers");
    if (!buffersRaw) {
        return [];
    }

    try {
        const buffers = JSON.parse(buffersRaw);
        return buffers;
    } catch (error) {
        return [];
    }
}

export {
    reactEditor,
    reactFormatter,
    saveEditBuffer,
    getEditBuffer,
    removeEditBuffer,
    getEditBuffersByUser,
    removeAllBufferOnProject
}
