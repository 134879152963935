import Cookies from 'js-cookie';
import cons from '../constants';
import strings from '../localization'

const getLanguage = () => {
    return Cookies.get(cons.LANG);
}

const setLanguage = (value) => {
    return Cookies.set(cons.LANG, value);
}

const initLanguage = () => {
    const lang = getLanguage();
    if (lang) {
        strings.setLanguage(lang);
    }
}

export default {
    getLanguage,
    setLanguage,
    initLanguage
};
