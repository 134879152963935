import update from 'immutability-helper';
import _ from 'lodash'
import { getExpandList, getCloseExpandList } from '../../helpers/UtilsHelper';
import { QA_ACTIONS } from "../actions";

const initState = () => ({
    loading: false,
    data: {},
    isOpenHistory: false,
    historyTaskId: null,
    isOpenTask: false,
    taskDetail: null,
    isOpenConflict: false,
    resolveConflict: function () { return false },
    conflictField: "",
    conflictData: {
        local: {},
        server: {}
    },
    isOpenRetry: false,
    retryData: {},
    table: null,
    taskCount: 0,
    taskReads: [],
    useTranslation: {},
    translationLanguage: {},
    isAutoTranslate: false,
    languageCodes: [],
    isEmptyData: false,
});

export default function qaReducer(state = initState(), action) {
    let { type, payload } = action;
    switch (type) {
        case QA_ACTIONS.QA_RESET_STATE:
            return {
                ...initState(),
                useTranslation: state.useTranslation
            }
        case QA_ACTIONS.QA_GET_LIST:
            return {
                ...state,
                loading: true,
            }
        case QA_ACTIONS.QA_GET_LIST_SUCCESS:
            let newData = { ...payload }
            newData.time = Date.now();
            return {
                ...state,
                data: newData,
                loading: false,
            }
        case QA_ACTIONS.QA_GET_LIST_FAIL:
            return {
                ...state,
                loading: false,
            }
        case QA_ACTIONS.QA_EXPAND_ROW:
            let results = state.data.results;
            if (payload.isExpand) {
                if (results && results.length) {
                    results = getExpandList(results, payload.id);
                }
            } else {
                results = getCloseExpandList(results, payload.id);
            }

            return {
                ...state,
                data: { ...state.data, results },
                loading: false,
            }
        case QA_ACTIONS.QA_ADD_ROW:
            state.data.results.push(payload.data);
            return {
                ...state,
                data: { ...state.data },
                loading: false,
            }
        case QA_ACTIONS.QA_TOGGLE_HISTORY:
            return {
                ...state,
                isOpenHistory: !state.isOpenHistory,
                historyTaskId: state.isOpenHistory ? null : state.historyTaskId
            }
        case QA_ACTIONS.QA_SHOW_HISTORY:
            return {
                ...state,
                isOpenHistory: true,
                historyTaskId: payload.id
            }
        case QA_ACTIONS.QA_TOGGLE_TASK:
            return {
                ...state,
                isOpenTask: !state.isOpenTask,
                taskDetail: state.isOpenTask ? null : state.taskDetail
            }
        case QA_ACTIONS.QA_SHOW_TASK:
            return {
                ...state,
                isOpenTask: true,
                taskDetail: payload
            }
        case QA_ACTIONS.QA_SHOW_CONFLICT:
            return {
                ...state,
                isOpenConflict: true,
                resolveConflict: payload.resolve,
                conflictField: payload.fieldName,
                conflictData: payload.data,
            }
        case QA_ACTIONS.QA_HIDE_CONFLICT:
            return {
                ...state,
                isOpenConflict: false,
            }
        case QA_ACTIONS.QA_RETRY_BUFFER_LOAD:
            return {
                ...state,
                isOpenRetry: true,
                retryData: payload.buffer
            }
        case QA_ACTIONS.QA_RETRY_BUFFER_CLOSE:
            return {
                ...state,
                isOpenRetry: false,
                retryData: {}
            }
        case QA_ACTIONS.QA_SAVE_TABLE:
            return {
                ...state,
                table: payload.table
            }
        case QA_ACTIONS.QA_UPDATE_TASK_COUNT:
            return {
                ...state,
                taskCount: payload.count
            }
        case QA_ACTIONS.QA_UPDATE_TASK_READS:
            return {
                ...state,
                taskReads: payload.taskReads
            }
        case QA_ACTIONS.QA_SET_TASK_READ_SUCCESS:
            return update(state, {
                taskReads: { $push: [payload.data] },
            });
        case QA_ACTIONS.QA_SET_USE_TRANSLATION:
            const translations = JSON.parse(JSON.stringify(state.useTranslation));
            translations[payload.projectId] = payload.isUse;
            return {
              ...state,
              useTranslation: translations
            }
        case QA_ACTIONS.QA_SET_TRANSLATION_LANGUAGE:
            const translationLanguage = _.cloneDeep(state.translationLanguage);
            translationLanguage[payload.projectId] = payload.languageCode;
            return {
                ...state,
                translationLanguage
            }
        case QA_ACTIONS.QA_INITIALIZE_TRANSLATION_DONE:
            {
                const translations = _.cloneDeep(state.useTranslation);
                translations[payload.projectId] = payload.isUse;
                const translationLanguage = _.cloneDeep(state.translationLanguage);
                translationLanguage[payload.projectId] = payload.translationLanguage;
                return {
                    ...state,
                    useTranslation: translations,
                    translationLanguage
                }
            }
        case QA_ACTIONS.QA_GET_TRANSLATION_INFO_SUCCESS:
        {
            const {isAutoTranslate, languageCodes} = payload;
            return {
                ...state,
                isAutoTranslate,
                languageCodes
            }
          }
          case QA_ACTIONS.QA_CHECK_EMPTY_DATA_TABLE: {
            return {
              ...state,
              isEmptyData: payload.status,
            };
          }
          default:
            return state;
    }
}
