export default {
    "#": "#",
    home: "Home",
    attachments: "Attachments",
    history: "History",
    setting: "Settings",
    user_setting: "User settings",
    company_QA: "Company A's QA Sheet",
    tag_not_available: "Cannot find reference tag",
    invite_user_success: "Successfully Invited User",
    invite_email_fail_message: "Invite is unsuccessful",
    email_exist_error: "The email is already registered",
    change_password_success: "Change password success",
    change_password_fail: "Change password fail",
    current_password_incorrect: "Incorrect password",
    update_profile_success: "Profile updated",
    update_profile_error: "Update profile error",
    current_password: "Current password",
    new_issue: "New issues",
    update_issue: "Update issues",
    all_changes: "All changes",
    notifications: "Notifications",
    inviter: "Inviter",
    confirm_invitation: "Confirm invitation",
    confirm_invitation_user: "Confirm invitation User",
    invitation_date: "Invitation date",
    reject: "Reject",
    confirm_invite_success: "Invitation confirmed",
    status: "Status",
    priority: "Priority",
    issue: "Issue",
    issues: "Issues",
    assignee: "Assignee",
    answer: "Answer",
    upload: "Upload",
    comment: "Comment",
    title: "Title",
    time: "Time",
    add_file: "Add files",
    all_files: "All files",
    question: "Question",
    last_update: "Last Update",
    files: "Files",
    reply: "Reply",
    search_bar: "Search Bar",
    undo: "Undo",
    save: "Save",
    share: "Share",
    language: "Language",
    add_new: "Add New",
    select_project: "Select project",
    project_code: "Project code",
    project_name: "Project name",
    author: "Author",
    created_date: "Created date",
    updated_date: "Updated date",
    text_signup: "Join smartQA today and streamline your Question and Answer sheet communication",
    text_signin: "Go to the login page!",
    join: "Join",
    previous: "Previous",
    next: "Next",
    search: "Search",
    project_search_placeholder: "Search with project name or code",
    history_search_placeholder: "Search with history content",
    size: "Size",
    auth_invalid: "Your authentication information is invalid or has expired",
    project_selection: "Project selection",
    logout: "Logout",
    login: "Login",
    ok: "Ok",
    username: "Username",
    fullname: "Fullname",
    password: "Password",
    confirm_password: "Confirm password",
    required_login: "Please input your username and password!",
    wrong_login_info: "Username or password is incorrect!",
    create: "Create",
    create_project: "Create project",
    cancel: "Cancel",
    project_name_placeholder: "Please input project name",
    create_project_sucess: "Project has been created!",
    create_project_fail: "Project creation has failed",
    empty_data: "Empty data",
    signup: "Sign up",
    signin: "Sign in",
    register_now: "Register Now",
    delete: "Delete",
    update: "Update",
    upload_success: "File upload successful.",
    upload_fail: "File upload has failed.",
    update_success: "Update success.",
    update_fail: "Update fail.",
    learn_more: "Learn more",
    take_the_tour: "take the tour",
    delete_issue_success: "Issue has been deleted.",
    search_qa: "Search QA",
    hide_closed: "Hide Closed",
    confirm_delete_text: "Are you sure to delete this item?",
    confirm_resend_text: "Do you want to send again the invitation?",
    confirm_delete_folder: "Are you sure to delete this folder?",
    project_hercules: "Project Hercules",
    navigation: 'Navigation',
    account: 'Account',
    reset: 'Reset',
    share_project_success: "Project has been copied",
    share_project: "Share project",
    copy_project: "Copy",
    date_time: "Date time",
    task: "Task",
    action: "Actions",
    create_task: "Create task",
    update_task: "Update task",
    delete_task: "Delete task",
    create_sub_task: "Create subtask",
    update_sub_task: "Update subtask",
    delete_sub_task: "Delete subtask",
    create_attachment: "Add attachment into task",
    update_attachment: "Update attachment [_file_name_] in task",
    delete_attachment: "Delete attachment from task",
    delete_file_success: "Delete file success",
    delete_file_fail: "File delete has failed",
    project_history: "Project history",
    confirmation: "Confirmation",
    thumbnail: "Thumbnail",
    file_name: "File name",
    date: "Date",
    user: "User",
    users: "Users",
    download: "Download",
    remove: "Remove",
    load_more: "Load more",
    and: "and",
    order: "Order",
    after: "After",
    before: "Before",
    add_user: "Add user",
    accounts: "Accounts",
    level: "Level",
    options: "Options",
    manager: "Manager",
    email: "Email",
    can_not_add_user: "Cannot add user",
    high: "High",
    normal: "Normal",
    low: "Low",
    open: "Open",
    in_progress: "In progress",
    close: "Close",
    invite: "Invite",
    invite_user: "Invite user",
    list_invalid: "Email address is invalid",
    confirm_invite_email: "Do you want to send an invitation to these email addresses:",
    send: "Send",
    list_email: "List email",
    back: "Back",
    confirm: "Confirm",
    confirm_upgrade: "Confirm upgrade",
    input_list_email: "Please input list email",
    register: "Register",
    first_name: "First name",
    romanized_first_name: "Romanized first name",
    last_name: "Last name",
    romanized_last_name: "Romanized last name",
    field_required: "This field is required",
    password_min_length: "Password must be more than 6 characters",
    password_dont_match: "These passwords don't match",
    register_success_title: "Registration successful!",
    register_success_content: "You can login to join smartQA!",
    request_register_success_title: "Request successful.",
    request_register_success_content: "Please check your email to continue your registration",
    register_link_expired: "Your registration link has been expired",
    company: "Company",
    profile: "Profile",
    show_hide: "Show/Hide",
    change_password: "Change password",
    updated_by: "Updated by",
    yes: "Yes",
    no: "No",
    override: "Override",
    warning: "Warning",
    conflict_warning_message: "The contents of this cell have changed",
    select: "Select",
    reference_tag: "Reference tag",
    sub_title_download: "Download",
    sub_title_delete: "Delete",
    error: "Error",
    disconnect_content: "Something went wrong. Please check your connection!",
    pending_invitations: "Pending invitations",
    closed: "Closed",
    complete: "Completed",
    delete_project_warning: "Deleting this project will remove all of your information from the database, this CANNOT be undone.",
    delete_project_guild: "To confirm this, type \"DELETE\"",
    close_project_warning: "Closed projects will be set to read only mode. Users can no longer add or edit anything in the project.",
    close_project_guild: "To confirm this, type \"CLOSE\"",
    leave_project: "Leave this project",
    close_project: "Close this project",
    open_project: "Open this project",
    delete_project: "Delete this project",
    delete_project_title: "Delete project",
    close_project_title: "Close project",
    open_project_title: "Open project",
    leave: "Leave",
    view: "View",
    export: "Export",
    leave_project_title: "Leave project",
    leave_project_warning_user: "Are you sure you want to leave this project?",
    leave_project_warning_admin: "You are a manager of this project, do you still want to leave?",
    leave_project_warning_admin_assign: "Please assign a manager to this project if you wish to leave!",
    delete_project_error_message: "Cannot delete this project",
    close_project_success_message: "Project has been closed.",
    close_project_error_message: "Close project error",
    close_project_confirm_error_message: "Please confirm with \"CLOSE\" text",
    open_project_warning: "Do you want to re-open this project?",
    open_project_guild: "To confirm this, type \"OPEN\"",
    open_project_success_message: "Open project successful",
    open_project_error_message: "Open project error",
    open_project_confirm_error_message: "Please confirm with \"OPEN\" text",
    general_files: "General Files",
    description: "Description",
    add_folder: "Add folder",
    add_folder_success: "Folder has been added.",
    delete_success: "Delete successful",
    delete_fail: "Delete failed",
    new_folder: "New Folder",
    forgot_password: "Forgot password",
    invalid_email: "Invalid email address",
    request_reset_password_successful: "Reset successful! Please check your email to reset password.",
    enter_email: "Please enter your email address",
    project_settings: "Project settings",
    customize_columns: "Customize Columns",
    reset_password: "Reset password",
    reset_password_success: "Reset password successful",
    reset_password_fail: "Reset password fail",
    reset_password_success_notice: "The new password has been changed successfully.",
    reset_password_expired: "Reset password link has expired",
    billing: "Billing",
    invoices: "Invoices",
    current_plan: "Current Plan",
    plan_view_plans: "Plan View Plans",
    basic_plan: "Basic Plan",
    change_plan: "Change Plan",
    next_billing_date: "Next billing date",
    next_plan: "Next plan",
    billing_information: "Billing information",
    name: "Name",
    company_name: "Company Name",
    company_address: "Company Address",
    city_country: "City, Country",
    zip: "Zip",
    personal_address: "Personal Address",
    payment_methods: "Payment Method",
    credit_card_or_bank_tranfer: "Credit Card or Bank Tranfer",
    change_my_plan: "Change my Plan",
    upgrade: "Upgrade",
    account_deletion: "Account Deletion",
    account_deletion_warning: "This will delete your account and all associated data. This cannot be undone.",
    delete_my_account: "Delete my Account",
    confirm_delete_account: "Are you sure to delete your account?",
    create_success: "Create success",
    create_fail: "Create fail",
    add_card: "Add card",
    credit_card: "Credit Card",
    bank_transfer: "Bank Transfer",
    select_payment_method: "Select payment method",
    list_credit_card: "List of credit cards",
    upgrade_account_message: "You must upgrade your account to use this feature!",
    confirm_payment: "Confirm payment",
    select_method_upgrade: "You must select payment method before upgrading your account.",
    pay_with_card: "You will complete the transaction with the credit card below:",
    update_payment_method: "Update payment method",
    pay: "Pay",
    add_new_credit_card: "Add new credit card",
    free: "Free",
    basic: "Basic",
    pro: "Pro",
    pro_translation: "Pro + Translation",
    limit_invite_email_error: "The number of invites exceeds the allowed limit, you need to upgrade your account to invite more people.",
    select_type_payment: "Select type of payment",
    from: "from",
    plan: "plan",
    monthly: "Monthly",
    yearly: "Yearly",
    per_month: "per month",
    per_year: "per year",
    selected: "Selected",
    jpy: "¥",
    usd: "$",
    you_save: "You save",
    downgrade: "Downgrade",
    number_of_object: "Number of object",
    price: "Price",
    max_attachment_storage: "Max attachment storage",
    customizations_themes: "Customizations themes",
    max_collaborators: "Max collaborators",
    download_only: "Download only",
    unlimited: "Unlimited",
    '0MB': "0MB",
    '250MB': "250MB",
    '5GB': "5GB",
    '380yen': '380yen',
    '780yen': '780yen',
    change: "Change",
    'free_des': 'Free to collaborate with anyone',
    '3_team_members': "3 members total",
    '3_projects': "3 projects total",
    'file_download_only': "File download only",
    'basic_des': "Good to get started",
    '5_team_members': "5 members total for each project",
    '30_team_members': "30 members total for each project",
    '10_projects': "10 projects total",
    '250_mb_store': "250mb storage for file uploads",
    'buy_basic': "Buy Basic",
    'pro_des': 'Pro features for pro users',
    'unlimited_team_members': "Unlimited members for each project",
    'unlimited_projects': "Unlimited total projects",
    '10_gb_storage': "10gb storage for file uploads",
    'export_qa': "Export QA projects to CSV",
    'buy_pro': "Buy Pro",
    'buy_pro_plus': "Buy Pro + Translation",
    'get_started': 'Get started',
    'back_to_free': "Back to Free",
    'back_to_basic': "Back to Basic",
    'pro_tran_des': "Add auto-translation to your projects",
    'all_pro_features': "All Pro features",
    'auto_translation_list_language': "Auto-translation (English, Japanese, Chinese, Korean)",
    'add_member_for_your_auto_translation_project': "All members of your Auto-Translate projects can use the translation feature even if they are not Pro + Translate tier",
    replies: 'replies',
    view_history: "View history",
    actions: "Actions",
    type_here_to_add_reply: "Type here to add a reply",
    type_here_to_add_issue: "Type here to add an issue",
    all_status: "All status",
    display_issue: "Displayed issues",
    current_tier_membership_time: "current tier membership time",
    total_projects: "total projects",
    signup_date: "signup date",
    of_project: "# of projects",
    previous_plan: "previous plan",
    previous_plan_start_date: "previous plan start date",
    previous_plan_total_months: "previous plan total months",
    current_plan_start_date: "current plan start date",
    current_plan_total_months: "current plan total months",
    billing_type: "billing type",
    activity_level: "activity level",
    min_months: "Min Months...",
    max_months: "Max Months...",
    min_project: "Min Project...",
    max_project: "Max Project...",
    start_date: "-Start date-",
    end_date: "-End date-",
    type: "Type...",
    year: "Year",
    month: "Month",
    change_current_plan_text_modal: "Change the plan for this account",
    showing: "Showing",
    to: "to",
    of: "of",
    entities: "entities",
    select_file_upload: "Select files to upload",
    drag_and_drop: "or drag and drop files here",
    add_a_title: "Add a title",
    add_a_comment: "Add a comment",
    projects: "Projects",
    project: "Project",
    add_project_placeholder: "Type here to add a project",
    search_project: "Search project",
    super_admin: "Super admin",
    login_smartQA: "Log in to smartQA.",
    sign_up_smartQA: "Sign up for smartQA.",
    sign_up_des_1: "Join smartQA today and streamline your Question and Answer sheet communication",
    sign_up_des_2: "To learn more, ",
    sign_up_des_3: "take the tour",
    export_this_project: "Export this project",
    browse_files: "Browse files",
    status_filter: "Status filter",
    translation: "Translation",
    address: "Address",
    card_information: "Card information",
    country_or_region: "Country or region",
    tags: "Tags",
    custom1: "Custom 1",
    custom2: "Custom 2",
    custom3: "Custom 3",
    copy: "Text has been copied to clipboard",
    resend_success: "Invitation has been sent again successfully",
    resend_fail: "Invitation could not be sent",
    date_joined: "Sign up Date",
    pin: "Pin this project",
    unpin: "Unpin this project",
    department: "Department",
    overview: "Overview",
    plans: "Plans",
    add_payment_method: "Add payment method",
    card_number: "Card Number",
    expiry: "Expiry",
    name_on_card: "Name on card",
    contact_us: "Contact us",
    bank_transfer_placeholder: "For bank transfer inquiries, please e-mail us directly at ",
    current_plan_detail: "Currently you are still on the ",
    downgrade_plan_detail_1: "Your downgrade to the ",
    downgrade_plan_detail_2: " plan will start ",
    select_card: "Select card",
    department_division: "Department/Division",
    collaborators: "Collaborators",
    time_format: "Time format",
    update_settings: "Update settings",
    save_settings_success: "Settings saved successfully",
    save_settings_error: "Error saving settings",
    project_limit_message: "You have reached the maximum Project limit for your plan. Please upgrade your account or close to join.",
    project_limit_reached: "You have reached the maximum Project limit for your plan. Please upgrade your account or close to join.",
    confirm_payment_description: "Here you can add some information about when the payment will be billed etc.",
    paid: "Paid",
    pages: "pages",
    invitation_reject: "Invitation Rejected",
    change_language: "Change language",
    auto_translate: "Auto Translate",
    enable_translations: "Enable Translations",
    english: "English",
    japanese: "Japanese",
    korean: "Korean",
    chinese: "Chinese",
    you_have: "You have",
    auto_translation_setting_title: "Auto Translation Project credits.",
    enable_auto_translation: "Enable Auto Translation?",
    auto_translation_setting_subject: "This cannot be undone and the project must be closed/deleted in order to regain translation credit.",
    auto_translation_setting_subject_warning: "The project must be closed/deleted in order to regain translation credit.",
    import: "Import",
    export_csv: "Export CSV",
    export_csv_file: "Export CSV + Files",
    original: "Original",
    auto_translation: "Auto Translation",
    enable: "Enable",
    on: "On",
    off: "Off",
    import_data_into_this_project: "Import data into this project",
    owner: "Owner",
    choose_file_to_upload: "Choose file to upload",
    choose_another_file: "Choose another file",
    issue_column: "Issue Column",
    answer_column: "Answer Column",
    import_file_success: "Import file success",
    import_file_failure: "Import file failure",
    tos_text_before: "I agree to the",
    tos_text_middle: "and",
    tos_text_after: "",
    tos: "Terms & Conditions",
    privacy_policy: "Privacy Policy",
    averageIssue: "Average Issue Per Month",
    averageIssueTrans: "Average Issue A-T Per Month",
    averageCharacters: "Average Character Per Month",
    averageCharactersTrans: "Average Character A-T Per Month",
    group: "Group",
    groups: "Groups",
    project_group: "Project group",
    new_project_group: "New project group",
    project_group_name: "Group name",
    no_group: "No group",
    change_group: "Change group",
    unsorted: "(Unsorted)",
    delete_project_group: "Delete project group",
    delete_project_group_confirm: "Deleting this group will move all its projects into the \"unsorted\" group. Do you want to proceed?",
    group_404: "This group does not exist",
    limited_space_message: "You have reached the file storage limit for your tier, please delete files or upgrade your account.",
    storage: "Storage",
    storage_used: "Storage Used"
}
