import update from 'immutability-helper';
import {
    PROJECT_USER_ACTIONS
} from "../actions";

const initState = () => ({
    loading: false,
    data: [],
    isAutoTranslate: false,
    languageCodes: [],
    isOpenDeleteModal: false,
    deletingItemId: null,
    isOpenRoleModal: false,
    changingRoleItem: null,
    isOpenInviteUserModal: false,
});

export default function projectUserReducer(state = initState(), action) {
    let { type, payload } = action;
    switch (type) {
        case PROJECT_USER_ACTIONS.PROJECT_USER_GET_LIST:
        case PROJECT_USER_ACTIONS.PROJECT_USER_ADD_USER:
        case PROJECT_USER_ACTIONS.PROJECT_USER_DELETE_USER:
        case PROJECT_USER_ACTIONS.PROJECT_USER_CHANGE_ROLE:
        case PROJECT_USER_ACTIONS.PROJECT_USER_GET_AUTO_TRANSLATION:
        case PROJECT_USER_ACTIONS.PROJECT_USER_UPDATE_AUTO_TRANSLATION:
        case PROJECT_USER_ACTIONS.PROJECT_USER_UPDATE_AUTO_TRANSLATION_LANGUAGE:

            return {
                ...state,
                loading: true,
            }
        case PROJECT_USER_ACTIONS.PROJECT_USER_GET_LIST_FAIL:
        case PROJECT_USER_ACTIONS.PROJECT_USER_ADD_USER_FAIL:
        case PROJECT_USER_ACTIONS.PROJECT_USER_DELETE_USER_FAIL:
        case PROJECT_USER_ACTIONS.PROJECT_USER_CHANGE_ROLE_FAIL:
        case PROJECT_USER_ACTIONS.PROJECT_USER_GET_AUTO_TRANSLATION_FAIL:
        case PROJECT_USER_ACTIONS.PROJECT_USER_UPDATE_AUTO_TRANSLATION_FAIL:
        case PROJECT_USER_ACTIONS.PROJECT_USER_UPDATE_AUTO_TRANSLATION_LANGUAGE_FAIL:
            return {
                ...state,
                loading: false,
            }
        case PROJECT_USER_ACTIONS.PROJECT_USER_GET_LIST_SUCCESS:
            let { data } = payload
            return {
                ...state,
                data,
                loading: false,
            }
        case PROJECT_USER_ACTIONS.PROJECT_USER_ADD_USER_SUCCESS:
            return update(state, {
                data: { $push: [payload.user] },
                loading: { $set: false },
            });
        case PROJECT_USER_ACTIONS.PROJECT_USER_GET_AUTO_TRANSLATION_SUCCESS:
            const { isAutoTranslate, languageCodes } = payload.data
            const language = languageCodes.map(item => item.value)
            return {
                ...state,
                isAutoTranslate,
                languageCodes: language,
                loading: false,
            }
        case PROJECT_USER_ACTIONS.PROJECT_USER_UPDATE_AUTO_TRANSLATION_LANGUAGE_SUCCESS:
            return {
                ...state,
                languageCodes: payload.data.languageCodes,
                loading: false,
            }
        case PROJECT_USER_ACTIONS.PROJECT_USER_UPDATE_AUTO_TRANSLATION_SUCCESS:
            return {
                ...state,
                isAutoTranslate: payload.data.isAutoTranslate,
                loading: false,
            }

        case PROJECT_USER_ACTIONS.PROJECT_USER_DELETE_USER_MODAL_OPEN:
            return {
                ...state,
                isOpenDeleteModal: true,
                deletingItemId: payload.id
            }
        case PROJECT_USER_ACTIONS.PROJECT_USER_DELETE_USER_MODAL_CLOSE:
            return {
                ...state,
                isOpenDeleteModal: false,
                deletingItemId: null
            }
        case PROJECT_USER_ACTIONS.PROJECT_USER_DELETE_USER_SUCCESS:
            const deletedId = payload.id;
            const deletedIndex = state.data.findIndex(item => item.id === deletedId);
            return update(state, {
                data: { $splice: [[deletedIndex, 1]] },
                loading: { $set: false },
            });

        case PROJECT_USER_ACTIONS.PROJECT_USER_ROLE_MODAL_OPEN:
            let changingRoleItem = state.data.find(item => item.id === payload.id)
            return {
                ...state,
                isOpenRoleModal: true,
                changingRoleItem: changingRoleItem && { ...changingRoleItem }
            }
        case PROJECT_USER_ACTIONS.PROJECT_USER_ROLE_MODAL_CLOSE:
            return {
                ...state,
                isOpenRoleModal: false,
                changingRoleItem: null
            }
        case PROJECT_USER_ACTIONS.PROJECT_USER_CHANGE_ROLE_SUCCESS:
            const changedRoleUserId = payload.userId;
            const newRoleId = payload.roleId;
            const changedRoleUseIndex = state.data.findIndex(item => item.id === changedRoleUserId);
            let newState = update(state, {
                data: { [changedRoleUseIndex]: { role: { $set: { id: newRoleId } } } },
                loading: { $set: false },
            });
            return newState;
        case PROJECT_USER_ACTIONS.PROJECT_USER_INVITE_USER_MODAL_TOGGLE:
            return {
                ...state,
                isOpenInviteUserModal: !state.isOpenInviteUserModal
            }
        case PROJECT_USER_ACTIONS.PROJECT_USER_REFRESH_REDUCER:
            return {
                state: initState()
            }
        default:
            return state;
    }
}
