import client from '../helpers/HttpClient'
const cardEndpoint = '/cards/'
const invoicesEndpoint = '/invoices/'
const paymentEndpoint = '/customer-stripe'
const tierEndpoint = '/tiers'
export default {
  async addCard(data) {
    const path = `${cardEndpoint}add`;
    let response = await client.post(path, data);
    return response.data;
  },
  async deleteCard(cardId) {
    const path = `${cardEndpoint}delete/${cardId}`;
    let response = await client.delete(path);
    return response.data;
  },
  async selectCard(data) {
    const path = `${cardEndpoint}set-default`;
    let response = await client.post(path, data);
    return response.data;
  },
  async getListCredit(data) {
    const path = `${cardEndpoint}list`;
    let response = await client.get(path);
    return response.data;
  },
  async getListInvoice(data) {
    const path = `${invoicesEndpoint}list/me?limit=100`;
    let response = await client.get(path);
    return response.data;
  },
  async updateCustomer(data) {
    const path = paymentEndpoint;
    let response = await client.post(path, data);
    return response.data;
  },
  async getCustomer(data) {
    const path = `${paymentEndpoint}/overview`;
    let response = await client.get(path);
    return response.data;
  },
  async getListTier(data) {
    const path = `${tierEndpoint}/`;
    let response = await client.get(path);
    return response.data;
  },
  async addUserInvitations(data) {
    let response = await client.post(`/payment/invitation`, { emails: data })
    return response.data;
  },
  async getUserInvitations(data) {
    let response = await client.get(`/payment/invitation`);
    return response.data;
  },
  async acceptInvitations(id) {
    let response = await client.get(`/payment/invitation/${id}/accept`);
    return response.data;
  },
  async rejectInvitations(id) {
    let response = await client.delete(`/payment/invitation/${id}/reject`);
    return response.data;
  },
  async getUserGroup() {
    let response = await client.get(`/payment/user-group-payment`);
    return response.data;
  },
}
