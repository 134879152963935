import { combineReducers } from 'redux';
import qaReducer from "./qaReducer";
import langReducer from './langReducer';
import authReducer from './authReducer';
import projectReducer from './projectReducer';
import projectGroupReducer from './projectGroupReducer';
import settingReducer from './settingReducer';
import sidebarReducer from './sidebarReducer'
import historyReducer from './historyReducer'
import appReducer from './appReducer'
import projectUserReducer from './projectUserReducer'
import adminDashboardReducer from './adminDashboardReducer'
import billingReducer from './billingReducer';

export default combineReducers({
  qa: qaReducer,
  lang: langReducer,
  auth: authReducer,
  project: projectReducer,
  projectGroup: projectGroupReducer,
  setting: settingReducer,
  sidebar: sidebarReducer,
  history: historyReducer,
  app: appReducer,
  projectUser: projectUserReducer,
  adminDashboard: adminDashboardReducer,
  billing: billingReducer
});
