export default {
    GET_LANG: 'GET_LANG',
    GET_LANG_SUCCESS: 'GET_LANG_SUCCESS',
    GET_LANG_ERROR: 'GET_LANG_ERROR',
    SET_LANG_TEMP: 'SET_LANG_TEMP',

    SET_LANG: 'SET_LANG',
    SET_LANG_WHITH_OUT_SAVE_SERVER: 'SET_LANG_WHITH_OUT_SAVE_SERVER',
    SET_LANG_SUCCESS: 'SET_LANG_SUCCESS',
    SET_LANG_ERROR: 'SET_LANG_ERROR',

    INITIALIZE_LANG: 'INITIALIZE_LANG',
    INITIALIZE_LANG_SUCCESS: 'INITIALIZE_LANG_SUCCESS'
}
