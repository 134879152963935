import { call, put, takeLatest, select, takeLeading } from "redux-saga/effects";
import _ from 'lodash';
import { QA_ACTIONS } from "../actions"
import taskApi from '../../api/taskApi'
import projectApi from '../../api/projectApi'
import AuthHelper from "../../helpers/AuthHelper";
import { getEditBuffersByUser, removeEditBuffer } from "../../components/Tabulator/ultils";
import { getMentionUser } from "../../pages/QA/ultils";
import { getTranslationActive, setTranslationActive, getTranslationLanguage, setTranslationLanguage } from "../../helpers/TranslationHelper";


function* getQAList(options) {
    const { payload } = options;
    try {
        const data = yield call(getTaskList.bind(this, payload))
        yield put({ type: QA_ACTIONS.QA_GET_LIST_SUCCESS, payload: { results: data } })
    } catch (error) {
        yield put({ type: QA_ACTIONS.QA_GET_LIST_FAIL })
    }
}

function getTaskList(payload) {
    return taskApi.list(payload).then(response => {
        return response
    })
}

function* checkRetry(options) {
    const projectId = options.payload.projectId;
    const user = AuthHelper.getUserInfo();
    const userId = user.user_id;
    const buffers = getEditBuffersByUser(userId, projectId);
    if (buffers.length) {
        const buffer = buffers[0];
        yield put({ type: QA_ACTIONS.QA_RETRY_BUFFER_LOAD, payload: { buffer } })
    }
}

function* clearAndCloseBuffer(options) {
    const data = yield select(state => state.qa.retryData)
    const { projectId, taskId } = data;
    removeEditBuffer(projectId, taskId);
    yield put({ type: QA_ACTIONS.QA_RETRY_BUFFER_CLOSE })
}

function* commitAndCloseBuffer(options) {
    const data = yield select(state => state.qa.retryData);
    const { taskId, rowData, projectId } = data;
    const table = yield select(state => state.qa.table);
    if (taskId) {
      try {
        data.callback = responseData => {
          let targetRow = _.get(table && table.searchRows("id", "=", Number(taskId)), "[0]", null);
          if (rowData.parentTaskNum) {
            const parentRow = _.get(table && table.searchRows("taskNum", "=", Number(rowData.parentTaskNum)), "[0]", null);
            const listChildRow = parentRow.getTreeChildren();
            targetRow = listChildRow.find(row => row.getData().taskNum === Number(rowData.taskNum));
          }
          targetRow.update({
            [data.fieldName]: responseData[data.fieldName]
          });

        }
        yield call(updateTask, data);
      } catch (e) {}
    } else {
        data.rowData[data.fieldName] = data.value;
        data.callback = () => window.location.reload();
        yield call(addTask, data);
    }

    removeEditBuffer(projectId, taskId);
    yield put({ type: QA_ACTIONS.QA_RETRY_BUFFER_CLOSE })
}

function updateTask(data) {
    const { taskId, fieldName, value, callback } = data;
    const updateData = {
        id: taskId,
        [fieldName]: value
    };

    if (getMentionUser(value).length > 0) {
      updateData.mention_users = getMentionUser(value).join();
    }

    return taskApi.update(updateData).then(res => {
      if (callback) {
        callback(res);
      }
    });
}

function addTask(data) {
    const { rowData } = data
    return taskApi.create(rowData).then(() => {
      if (data.callback) {
        data.callback();
      }
    });
}

function* setTaskAsRead({payload}) {
  const {projectId, taskId} = payload;
  try {
    const data = yield call(_setTaskAsRead.bind(this, projectId, taskId));
    yield put({ type: QA_ACTIONS.QA_SET_TASK_READ_SUCCESS, payload: { data } });
  } catch (error) { /* ignore */ }
}

async function _setTaskAsRead(projectId, taskId) {
  const response = await projectApi.setTaskRead(projectId, taskId);
  return response.data;
}

function* initializeTranslation({payload}) {
  const {projectId} = payload;
  const isTranslationActive = yield call(getTranslationActive.bind(this, projectId));
  const translationLanguage = yield call(getTranslationLanguage.bind(this, projectId));
  yield put({type: QA_ACTIONS.QA_INITIALIZE_TRANSLATION_DONE, payload: {projectId, isUse: isTranslationActive, translationLanguage}});
}

function *saveTranslation({payload}) {
  const {projectId, isUse} = payload;
  yield call(setTranslationActive.bind(this, projectId, isUse));
}

function *saveTranslationLanguage({payload}) {
  const {projectId, languageCode} = payload;
  yield call(setTranslationLanguage.bind(this, projectId, languageCode));
}

function *getTranslationInfo({payload}) {
  const {projectId} = payload;
  const data = yield call(_getTranslationInfo.bind(this, projectId));
  yield put({type: QA_ACTIONS.QA_GET_TRANSLATION_INFO_SUCCESS, payload: data})
}

async function _getTranslationInfo(projectId) {
  const response = await projectApi.getLanguages(projectId);
  return response.data;
}

export function* watchQAAsync() {
    yield takeLatest(QA_ACTIONS.QA_GET_LIST, getQAList);
    yield takeLatest(QA_ACTIONS.QA_RETRY_BUFFER, checkRetry);
    yield takeLatest(QA_ACTIONS.QA_RETRY_BUFFER_CLEAR_AND_CLOSE, clearAndCloseBuffer);
    yield takeLatest(QA_ACTIONS.QA_RETRY_BUFFER_COMMIT_AND_CLOSE, commitAndCloseBuffer);
    yield takeLatest(QA_ACTIONS.QA_SET_TASK_READ, setTaskAsRead);
    yield takeLeading(QA_ACTIONS.QA_INITIALIZE_TRANSLATION, initializeTranslation);
    yield takeLeading(QA_ACTIONS.QA_SET_USE_TRANSLATION, saveTranslation);
    yield takeLeading(QA_ACTIONS.QA_SET_TRANSLATION_LANGUAGE, saveTranslationLanguage);
    yield takeLeading(QA_ACTIONS.QA_GET_TRANSLATION_INFO, getTranslationInfo);
}
