import React, { useState, useEffect, useRef } from 'react';
import { render } from 'react-dom';

import './TagsInput.scss'

const KeyCodes = {
    semicolon: 186,
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.semicolon, KeyCodes.enter];

const TagsInput = props => {
    const wrapper = useRef()
    const input = useRef()
    const [tags, setTags] = useState(props.tags || []);
    const removeTags = indexToRemove => {
        setTags([...tags.filter((_, index) => index !== indexToRemove)]);
    };
    const addTags = event => {
        let tag = event.target.value.replace(/[,.;']/g, '')
        if (tag !== "") {
            setTags([...tags, tag]);
            event.target.value = "";
            normalizeHeight()
        }
    };

    const checkBlur = e => {
        window.removeEventListener("click", checkBlur)
        props.success([...tags])
    }

    useEffect(() => {
        normalizeHeight()
        wrapper.current.onclick = e => {
            e.preventDefault();
        }
        window.addEventListener("click", checkBlur)
        input.current.focus()

        return () => {
            window.removeEventListener("click", checkBlur)
        }
    })

    const normalizeHeight = () => {
        const row = props.cell.getRow();
        row.normalizeHeight();
    }

    const onKeyUp = e => {
        if (delimiters.includes(e.keyCode)) {
            addTags(e)
        }
        else if (e.keyCode === KeyCodes.enter) {
            props.success(tags);
        }
    }
    return (
        <div tabIndex="1" ref={wrapper} className="tags-input">
            <ul id="tags">
                {tags.map((tag, index) => (
                    <li key={Math.random()} className="tag">
                        <span className='tag-title'>{tag}</span>
                        <span className='tag-close-icon'
                            onMouseDown={() => removeTags(index)}
                        >
                            x
                        </span>
                    </li>
                ))}
                <li className="tag-input">
                    <input
                        type="text"
                        onKeyUp={onKeyUp}
                        placeholder="Press enter to add tags"
                        ref={input}
                    />
                </li>
            </ul>
        </div>
    );
};

export default function (
    cell,
    onRendered,
    success,
    cancel,
    editorParams
) {
    const container = document.createElement('div');
    container.style.height = '100%';

    const data = cell.getData();
    render(
        <TagsInput
            cell={cell}
            onRendered={onRendered}
            success={success}
            cancel={cancel}
            editorParams={editorParams}
            tags={data.tagList}
        />,
        container
    );
    return container;
}