const actions = {
    APP_CHANGE_CURRENT_PROJECT: 'APP_CHANGE_CURRENT_PROJECT',
    APP_CLEAN_CURRENT_PROJECT: 'APP_CLEAN_CURRENT_PROJECT',
    APP_CHANGE_CURRENT_PROJECT_NAME: 'APP_CHANGE_CURRENT_PROJECT_NAME',
    APP_CLEAN_CURENT_PROJECT_NAME: 'APP_CLEAN_CURENT_PROJECT_NAME',
    DISCONNECT_ON: 'DISCONNECT_ON',
    DISCONNECT_OFF: 'DISCONNECT_OFF',
    START_LOADING: 'START_LOADING',
    END_LOADING: 'END_LOADING',
    UPDATE_HISTORY: 'UPDATE_HISTORY',
    UPDATE_HEADER: 'UPDATE_HEADER',
}

export default actions;

export const startLoading = () => ({ type: actions.START_LOADING});
export const endLoading = () => ({ type: actions.END_LOADING });