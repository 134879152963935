import strings from "../localization";

const TASK_STATUS = {
    OPEN: 200,
    IN_PROGRESS: 400,
    COMPLETE: 500,
    CLOSE: 600,
}

const TASK_PRIORITY = {
    LOW: 200,
    NORMAL: 400,
    HIGH: 600
}

const USER_PROJECT_ROLE = {
    ADMIN: 1,
    USER: 2,
    OWNER: 3,
}

const LANGUAGE_CODE = {
    ENGLISH: "en",
    JAPANESE: "ja",
    CHINESE: "zh",
    KOREAN: 'ko',
}

const LANGUAGES = () => [
    {
        code: LANGUAGE_CODE.ENGLISH,
        name: strings.english,
        displayText: strings.english,
    },
    {
        code: LANGUAGE_CODE.JAPANESE,
        name: strings.japanese,
        displayText: strings.japanese,
    },
    {
        code: LANGUAGE_CODE.CHINESE,
        name: strings.chinese,
        displayText: strings.chinese,
    },
    {
        code: LANGUAGE_CODE.KOREAN,
        name: strings.korean,
        displayText: strings.korean,
    },
]

const DEFAULT_TABLE_SETTING = [
    { id: "#", key: "#", isVisible: true, size: 20 },
    { id: "status", key: "status", isVisible: true, size: 110 },
    { id: "priority", key: "priority", isVisible: true, size: 110 },
    { id: "issue", key: "issue", isVisible: true, size: 350 },
    { id: "answer", key: "answer", isVisible: true, size: 350 },
    { id: "assignee", key: "assignee", isVisible: true, size: 160 },
    { id: "lastUpdate", key: "last_update", isVisible: true, size: 200 },
    { id: "files", key: "files", isVisible: true, size: 100 },
    { id: "reply", key: "reply", isVisible: true, size: 100 },
    { id: "delete", key: "delete", isVisible: true, size: 100 },
    { id: "tags", key: "tags", isVisible: true, size: 150 },
    { id: "custom1", key: "custom1", isVisible: true, size: 220 },
    { id: "custom2", key: "custom2", isVisible: true, size: 220 },
    { id: "custom3", key: "custom3", isVisible: true, size: 220 },
];

const TIER_TYPE_INFO = () => [
    { code: 1, name: strings.free, color: "secondary" },
    { code: 2, name: strings.basic, color: "primary" },
    { code: 3, name: strings.pro, color: "success" },
    { code: 4, name: strings.pro_translation, color: "warning" },
]
const TIER_TYPE = {
    FREE: 1,
    BASIC: 2,
    PRO: 3,
    PRO_TRANSLATION: 4,
}

const LIMITED = {
    AUTO_TRANSLATION: 10,
    INVITE_MEMBER_FREE_TIER: 3,
    INVITE_MEMBER_BASIC_TIER: 10,
    INVITE_MEMBER_PRO_TIER: 30,
    INVITE_MEMBER_PRO_TRANSLATION_TIER: 30,
}

const LIMITED_SPACE_CODE = "ATTACHMENTS"

export default {
    // Cookies key
    CK_TOKEN: "CK_TOKEN",
    CK_USER: "CK_USER",
    CK_DATA: "CK_DATA",

    //MULTI LANG
    LANG: "MULTI_LANG",

    TASK_STATUS,
    TASK_PRIORITY,

    USER_PROJECT_ROLE,
    LANGUAGE_CODE,
    LANGUAGES,
    DEFAULT_TABLE_SETTING,

    TIER_TYPE_INFO,
    TIER_TYPE,

    LIMITED,
    LIMITED_SPACE_CODE,
}
