const actions = {
    PROJECT_GET_LIST: 'PROJECT_GET_LIST',
    PROJECT_GET_LIST_SUCCESS: 'PROJECT_GET_LIST_SUCCESS',
    PROJECT_GET_LIST_FAIL: 'PROJECT_GET_LIST_FAIL',
    PROJECT_UPDATE_ROW: 'PROJECT_UPDATE_ROW',
    PROJECT_OPEN_INVITE_MODAL: 'PROJECT_OPEN_INVITE_MODAL',
    PROJECT_CLOSE_INVITE_MODAL: 'PROJECT_CLOSE_INVITE_MODAL',
    PROJECT_OPEN_DELETE_MODAL: 'PROJECT_OPEN_DELETE_MODAL',
    PROJECT_DELETE_SUCCESS: 'PROJECT_DELETE_SUCCESS',
    GET_PROJECT_SETTING: 'GET_PROJECT_SETTING',
    GET_PROJECT_SETTING_SUCCESS: 'GET_PROJECT_SETTING_SUCCESS',
    GET_PROJECT_SETTING_FAIL: 'GET_PROJECT_SETTING_FAIL',
    SET_PROJECT_SETTING: 'SET_PROJECT_SETTING',
    SET_PROJECT_SETTING_SUCCESS: 'SET_PROJECT_SETTING_SUCCESS',
    SET_PROJECT_SETTING_FAIL: 'SET_PROJECT_SETTING_FAIL',
    PROJECT_UPDATE_INFO: 'PROJECT_UPDATE_INFO',
}

export default actions

export const openInviteModal = (projectId, userCount) => ({ type: actions.PROJECT_OPEN_INVITE_MODAL, payload: { projectId, userCount } })
export const updateProjectInfo = (projectId, userCount) => ({ type: actions.PROJECT_UPDATE_INFO, payload: { projectId, userCount } })
export const closeInviteModal = () => ({ type: actions.PROJECT_CLOSE_INVITE_MODAL })
export const getList = (pagination) => ({ type: actions.PROJECT_GET_LIST, payload: pagination })
export const updateList = (item) => ({ type: actions.PROJECT_UPDATE_ROW, payload: item })
export const openDeleteModal = () => ({ type: actions.PROJECT_OPEN_DELETE_MODAL })
export const deleteSuccess = (projectId) => ({ type: actions.PROJECT_DELETE_SUCCESS, payload: { projectId } })
