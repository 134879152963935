import { call, put, takeLatest } from "redux-saga/effects";
import { PROJECT_USER_ACTIONS, LOGIN_ACTIONS } from "../actions";
import projectApi from '../../api/projectApi';
import authHelper from "../../helpers/AuthHelper";
import { toast } from 'react-toastify';
import strings from "../../localization";

function* getProjectUserList(action) {
    const { projectId } = action.payload;
    try {
        const data = yield call(getUsers.bind(this, projectId))
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_GET_LIST_SUCCESS, payload: { data } })
    } catch (error) {

    }
}

async function getUsers(projectId) {
    return await projectApi.users(projectId)
}

function* addProjectUser(action) {
    const { projectId, user, onSuccess } = action.payload;
    try {
        yield call(addUser.bind(this, projectId, user.id))
        onSuccess();
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_ADD_USER_SUCCESS, payload: { user } })
    } catch (error) {
        toast.error(strings.can_not_add_user)
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_ADD_USER_FAIL, payload: { error } })
    }
}

async function addUser(projectId, userId) {
    return await projectApi.addUser(projectId, userId);
}

function* deleteProjectUser(action) {
    const { projectId, userId } = action.payload;
    try {
        yield call(deleteUser.bind(this, projectId, userId))
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_DELETE_USER_SUCCESS, payload: { id: userId } })
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_DELETE_USER_MODAL_CLOSE })
    } catch (error) {
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_DELETE_USER_FAIL, payload: { error } })
    }
}

async function deleteUser(projectId, userId) {
    return await projectApi.deleteUser(projectId, userId)
}

function* changeUserRole(action) {
    const { projectId, userId, roleId } = action.payload;
    try {
        yield call(changeRole.bind(this, projectId, userId, roleId))
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_CHANGE_ROLE_SUCCESS, payload: { userId, roleId } })
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_ROLE_MODAL_CLOSE })
        window.location.reload()
    } catch (error) {
        toast.error(error.response.data.message[0])
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_CHANGE_ROLE_FAIL, payload: { error } })
    }
}

async function changeRole(projectId, userId, roleId) {
    return await projectApi.changeRole(projectId, userId, roleId)
}

function* getLanguages(action) {
    const { projectId } = action.payload;
    try {
        const data = yield call(getProject.bind(this, projectId))
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_GET_AUTO_TRANSLATION_SUCCESS, payload: { data } })
    } catch (error) {
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_GET_AUTO_TRANSLATION_FAIL, payload: { error } })
    }
}

async function getProject(projectId) {
    return await projectApi.getLanguages(projectId, true)
}

function* updateAutoTranslation(action) {
    const { isAutoTranslate, projectId } = action.payload;
    const updateData = { isAutoTranslate }
    try {
        const data = yield call(update.bind(this, updateData, projectId))
        data && data.token && authHelper.saveToken(data.token);
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_UPDATE_AUTO_TRANSLATION_SUCCESS, payload: { data } })
        yield put({ type: LOGIN_ACTIONS.REFRESH_TOKEN })
    } catch (error) {
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_UPDATE_AUTO_TRANSLATION_FAIL, payload: { error } })
    }
}

function* updateAutoTranslationLanguage(action) {
    const { languageCodes, projectId } = action.payload;
    const updateData = { languageCodes, isAutoTranslate: true }
    try {
        const data = yield call(update.bind(this, updateData, projectId))
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_UPDATE_AUTO_TRANSLATION_LANGUAGE_SUCCESS, payload: { data } })
    } catch (error) {
        yield put({ type: PROJECT_USER_ACTIONS.PROJECT_USER_UPDATE_AUTO_TRANSLATION_LANGUAGE_FAIL, payload: { error } })
    }
}

async function update(updateData, projectId) {
    return await projectApi.updateAutoTranslation(updateData, projectId)
}

export function* watchProjectUserAsync() {
    yield takeLatest(PROJECT_USER_ACTIONS.PROJECT_USER_GET_LIST, getProjectUserList);
    yield takeLatest(PROJECT_USER_ACTIONS.PROJECT_USER_ADD_USER, addProjectUser);
    yield takeLatest(PROJECT_USER_ACTIONS.PROJECT_USER_DELETE_USER, deleteProjectUser);
    yield takeLatest(PROJECT_USER_ACTIONS.PROJECT_USER_CHANGE_ROLE, changeUserRole);
    yield takeLatest(PROJECT_USER_ACTIONS.PROJECT_USER_GET_AUTO_TRANSLATION, getLanguages);
    yield takeLatest(PROJECT_USER_ACTIONS.PROJECT_USER_UPDATE_AUTO_TRANSLATION, updateAutoTranslation);
    yield takeLatest(PROJECT_USER_ACTIONS.PROJECT_USER_UPDATE_AUTO_TRANSLATION_LANGUAGE, updateAutoTranslationLanguage);
}
