import React from "react";
import _ from "lodash"
import { convertFromRaw, EditorState } from 'draft-js';
import Editor, { createEditorStateWithText } from 'draft-js-plugins-editor';
import createMentionPlugin from 'draft-js-mention-plugin';
import constants from "../../constants";
import { toast } from 'react-toastify';
import { getDateTime } from "../../helpers/UtilsHelper";
import { hashtagStrategy, HashtagSpan } from "./DraftEditor";
import strings from "../../localization";
import copyIcon from '../../assets/img/icon/copy.svg';
import "./ButtonIcon.scss";

export default class DraftFormater extends React.Component {
  constructor(props) {
    super(props);
    this.mentionPlugin = createMentionPlugin({ mentionPrefix: '@' });
    this.plugins = [this.mentionPlugin];

    let raw = createEditorStateWithText("");
    let cellValue = props.cell.getValue() || "";
    if (!_.isEmpty(cellValue)) {
      try {
        raw = EditorState.createWithContent(convertFromRaw(JSON.parse(cellValue)))
      } catch (error) {
        raw = (createEditorStateWithText(cellValue))
      }
    } else {
      raw = (createEditorStateWithText(cellValue))
    }

    this.state = {
      value: raw
    }
  }

  normalizeHeight = () => {
    const row = this.props.cell.getRow();
    row.normalizeHeight();
  }

  componentDidMount() {
    this.normalizeHeight();
    this.markRow();
  }

  markRow = () => {
    const baseElement = this.props.baseElement;
    setTimeout(() => {
      let closestRow = baseElement.closest('.tabulator-row');
      const data = this.props.cell.getRow().getData();
      this.markEmptyRow(data, closestRow);
      this.markClosedRow(data, closestRow);
    }, 0)
  }

  markClosedRow(data, closestRow) {
    if (data.taskStatus === constants.TASK_STATUS.CLOSE) {
      if (closestRow)
        closestRow.classList.add("closed-row");
    } else {
      if (closestRow)
        closestRow.classList.remove("closed-row");
    }
  }

  markEmptyRow(data, closestRow) {
    if (data.id === null) {
      if (closestRow)
        closestRow.classList.add("empty-row");
    }
  }

  componentDidUpdate() {
    this.normalizeHeight();
  }

  getRow = () => {
    return this.props.cell.getRow();
  }

  getData = () => {
    return this.getRow().getData();
  }

  renderUserInfoByType = () => {
    const rowData = this.getData();
    const { type } = this.props;
    let info = {}
    const format = this.props.timeFormat === "12h" ? "h:mm a, MMMM Do YYYY" : "HH:mm, MMMM Do YYYY"
    if (type === "issue") {
      info.auth = rowData && rowData.updatedIssueBy;
      info.timestamp = getDateTime(rowData.updatedIssueAt, format)
    } else {
      info.auth = rowData && rowData.updatedAnswerBy;
      info.timestamp = getDateTime(rowData.updatedAnswerAt, format)
    }

    return this.renderUserInfo(info)
  }

  renderUserInfo = (info) => {
    const { auth, timestamp } = info;
    if (!auth) {
      return;
    }

    const { firstName, lastName, company } = auth;
    const fullName = `${firstName} ${lastName}`;

    return <div className="user-info">
      <span className="user-info__name">{fullName}</span>
      {company && <span className="user-info__company">{company}</span>}
      <span className="user-info__time">{timestamp} </span>
    </div>
  }

  renderPlaceHolder(text) {
    return (
      <div className="QA-placeholder">{text}</div>
    )
  }

  getTimestamp() {
    const rowData = this.getData();
    const timestamp = getDateTime(rowData.updatedIssueAt);
    return timestamp;
  }

  getTextFromCellData(data) {
    let resultData = "";
    if (data) {
      const blockArr = JSON.parse(data).blocks;
      blockArr.forEach((item) => {
        resultData += `${item.text}\r\n`;
      });
    }
    return resultData;
  }

  copyContentToClipboard(event, data) {
    event.preventDefault();
    const text = this.getTextFromCellData(data)
    if (text) {
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      toast.success(strings.copy);
    }
  }

  render() {
    const decorators = [{
      strategy: hashtagStrategy,
      component: HashtagSpan,
    },];
    const rowData = this.getData();
    const { answer, id, issue, parentTask } = rowData;
    const { type } = this.props;
    const holderConditionAnswer = (rowData && id && !parentTask && type ==="answer" && !answer)
          || (rowData && !id && parentTask && type ==="answer" && !answer);
    const holderConditionIssue = (!parentTask && type==="issue" && !issue);
    const showCopyCondition = !!rowData[type];
    const showUserInfo = ["custom1", "custom2", "custom3"].indexOf(type) === -1;

    return <div className="cell-format">
      <Editor
        editorState={this.state.value}
        readOnly={true}
        plugins={this.plugins}
        decorators={decorators}
        onChange={(value) => this.setState({ value })} />
      { holderConditionAnswer && this.renderPlaceHolder(strings.type_here_to_add_reply)}
      { holderConditionIssue && this.renderPlaceHolder(strings.type_here_to_add_issue)}
      {showUserInfo && this.renderUserInfoByType()}
      {
        showCopyCondition &&
        <div id="copy-button-wrapper" className="cell-format__copy-icon" onClick={event => this.copyContentToClipboard(event, rowData[type])}>
          <img id="copy-button-icon" src={copyIcon} alt="copy icon"/>
        </div>
      }
    </div>;
  }
}
