import "./ButtonIcon.scss"

import React from "react";
import TabelHelper from '../../helpers/TabelHelper';
import strings from "../../localization";
export function ButtonIconReply(props) {
  const data = props.cell.getRow().getData();
  const isParentRow = TabelHelper.isParentRow(props.cell);
  const row = props.cell.getRow();
  const dataChildren = props.cell.getData()['subtasks'];
  let countChild = dataChildren && dataChildren.filter(item => item.id).length;
  if (props.guest) {
    countChild = dataChildren && dataChildren.length
  }
  const config = row._row.modules && row._row.modules.dataTree;
  const isExpand = config && config.open;
  props.onRendered(() => {
    let closestRow = props.baseElement.closest('.tabulator-row');
    let closestCell = props.baseElement.closest('.tabulator-cell');
    if (!isParentRow) {
      closestRow.classList.add("child-row");
      closestCell.classList.add("diable-mouse");
    }

    if (closestRow) {
      if (data.id === null) {

        closestRow.classList.add("empty-row");
      } else {
        closestRow.classList.remove("empty-row");
      }
    }
  })

  const _getLatestAnswerChild = (rowData) => {
    const { subtasks } = rowData;
    const countTask = subtasks && subtasks.length;
    if (countTask > 1) {
      const latestChild = subtasks[countTask - 2];
      return {
        answer: latestChild.answer,
        updatedAnswerBy: latestChild.updatedAnswerBy,
        updatedAnswerAt: latestChild.updatedAnswerAt
      };
    }
    return null;
  }

  const _swapData = () => {
    const { answerBackup } = data;
    if (answerBackup) {
      answerBackup[1] = _getLatestAnswerChild(data);
      if (answerBackup && answerBackup[0] && answerBackup[1]) {
        if (isExpand) {
          const { answer, updatedAnswerBy, updatedAnswerAt } = answerBackup[1];
          data.answer = answer;
          data.updatedAnswerBy = updatedAnswerBy;
          data.updatedAnswerAt = updatedAnswerAt;
        } else {
          const { answer, updatedAnswerBy, updatedAnswerAt } = answerBackup[0];
          data.answer = answer;
          data.updatedAnswerBy = updatedAnswerBy;
          data.updatedAnswerAt = updatedAnswerAt;
        }
      }
    }
    row.treeToggle();
  }

  return ((isParentRow) && data && data.id && data.answer ?
    <div className="icon-reply" onClick={(e) => { _swapData() }}>
      { countChild > 0 &&
        <div className="icon-reply__count">
          <div className={`icon-reply__count__mark ${isExpand ? 'icon-reply__count__mark--open' : 'icon-reply__count__mark--close'}`}></div>
          <span className="icon-reply__count__number">{countChild}</span>
          <span>{ countChild > 1 ? strings.replies : strings.reply}</span>
        </div>
      }
      <div className="icon-reply__link">{strings.reply}</div>
    </div> : "");
}
