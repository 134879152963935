import React from "react";
import _ from "lodash"
import classNames  from "classnames/bind"
import constants from "../../constants";

export default class SelectEditor extends React.Component {

  constructor(props) {
    super(props);
    this.dropRef = React.createRef();
  }

  save = (e) => {
    const cellValue = this.getCellValue()
    const tempValue = e.target.attributes.value.value;
    const value = tempValue.length > 4 ? tempValue : +tempValue;
    if(cellValue !== value){
      this.props.onSuccess(value);
    }
    else {
      this.props.cancel();
    }
  }

  componentDidUpdate() {
  }

  getLabel = (value) => {
    const values = this.getValues();
    const item = values.find(item => item.value === value);
    return _.get(item, "label", "");
  }

  getValues = () => {
    const { editorParams } = this.props
    let values = _.get(editorParams, "values", [])
    values = values.filter(i => i && i.value !== null)
    return values;
  }

  getCellValue = () => {
    return this.props.cell.getValue();
  }

  componentDidMount() {
    this.props.onRendered(() => {
      this.dropRef.focus();
    })
  }

  componentWillUnmount() {
    this.props.cancel()
  }

  onBlur = () => {
    this.props.cancel()
  }

  render() {
    const values = this.getValues();
    const cellValue = this.getCellValue();
    const label = this.getLabel(cellValue);
    let priorityTextClass = null;
    switch (cellValue) {
      case constants.TASK_PRIORITY.HIGH:
           priorityTextClass = "hight-priority-text";
        break;
      case constants.TASK_STATUS.COMPLETE:
          priorityTextClass = "completed-status-text";
        break;
      default:
        priorityTextClass = null;
    }
    return <div ref={ref => this.dropRef = ref} tabIndex="-1" onBlur={this.onBlur}>
      <div className={priorityTextClass}>{label}</div>
      {
        !this.props.isClosed &&
        <div className="custom-select-cell" onBlur={this.onBlur}>
          {this.renderItems(values, cellValue)}
        </div>
      }
    </div>
  }

  renderItems(values, cellValue) {
    return values.map(item => {
      const selectedClass = item.value === cellValue ? "selected" : "";
      return <div
        className={classNames("select-item", selectedClass)}
        key={item.value}
        value={item.value}
        onMouseDown={this.save}>{item.label}</div>
    });
  }
}
