import {
    PROJECT_GROUP_ACTIONS
} from "../actions";

const initState = {
    groups: [],
    groupLoading: false,
    groupSuccess: false,
    groupError: false,
    groupLastAction: "idle",
    groupCreateError: false,
    selectedGroup: {}
};

export default function projectReducer(state = initState, action) {
    let { type, payload } = action;
    switch (type) {
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_CREATE:
          return {
            ...state,
            groupLoading: true,
            groupLastAction: "create",
            groupSuccess: false,
            groupError: false
          }
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_CREATE_SUCCESS:
          return {
            ...state,
            groupLoading: false,
            groupSuccess: true,
            groupError: false
          }
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_CREATE_FAIL:
          return {
            ...state,
            groupLoading: false,
            groupSuccess: false,
            groupError: true
          }
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_RESET:
          return {
            ...state,
            groupLoading: false,
            groupSuccess: false,
            groupError: false,
            groupLastAction: "idle"
          }
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_GET_LIST:
          return {
            ...state,
            groupLoading: true,
            groupLastAction: "getList",
            groupSuccess: false,
            groupError: false,
          }
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_GET_LIST_SUCCESS:
          return {
            ...state,
            groupLoading: false,
            groupError: false,
            groupSuccess: true,
            groups: payload
          }
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_GET_LIST_FAIL:
          return {
            ...state,
            groupLoading: false,
            groupError: true,
            groups: []
          }
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_GET:
          return {
            ...state,
            groupLoading: false,
            groupError: false,
            groupLastAction: "get",
            groupSuccess: false
          }
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_GET_SUCCESS:
          return {
            ...state,
            groupLoading: false,
            groupError: false,
            groupSuccess: true,
            selectedGroup: payload
          }
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_GET_FAIL:
          return {
            ...state,
            groupLoading: false,
            groupError: true,
            selectedGroup: {id: null}
          }
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_CLEAR:
          return {
            ...state,
            selectedGroup: {}
          }
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_DELETE:
          return {
            ...state,
            groupLoading: false,
            groupError: false,
            groupLastAction: "delete",
            groupSuccess: false
          }
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_DELETE_SUCCESS:
          return {
            ...state,
            groupLoading: false,
            groupError: false,
            groupSuccess: true
          }
        case PROJECT_GROUP_ACTIONS.PROJECT_GROUP_DELETE_FAIL:
          return {
            ...state,
            groupLoading: false,
            groupError: true,
            groupSuccess: false
          }
        default:
            return state;
    }
}
