import jwtDecode from 'jwt-decode';
import authHelper from '../helpers/AuthHelper'
import constants from '../constants';

export function getTranslationActive(projectId) {
  const userObject = jwtDecode(authHelper.getToken());
  const isPro = userObject.tier && userObject.tier.type ? userObject.tier.type === constants.TIER_TYPE.BASIC : false;
  const translations = JSON.parse(localStorage.getItem("translations")) || {};
  return translations[projectId] !== undefined ? !!translations[projectId] : isPro; //cast string to bool. If undefined it will default to if the user has pro account or not
}

export function setTranslationActive(projectId, translationActive) {
  const translations = JSON.parse(localStorage.getItem("translations")) || {};
  translations[projectId] = translationActive;
  localStorage.setItem("translations", JSON.stringify(translations));
}

export function getTranslationLanguage(projectId) {
  const translations = JSON.parse(localStorage.getItem("translations-lang")) || {};
  return translations[projectId] !== undefined ? translations[projectId] : "";
}

export function setTranslationLanguage(projectId, languageCode) {
  const translations = JSON.parse(localStorage.getItem("translations-lang")) || {};
  translations[projectId] = languageCode;
  localStorage.setItem("translations-lang", JSON.stringify(translations));
}
